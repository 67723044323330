import React, { useState, useEffect, useRef } from 'react';
import { facebook_icon, favourite_icon, instagram_icon, plus_icon, search_icon, twitter_icon, user_icon } from './icons';
import FlatList from 'flatlist-react/lib';
import { useNavigate, useLocation } from 'react-router-dom';
import { db } from '../config';
import { toast } from 'wc-toast';
import { Levels } from 'react-activity';
import "react-activity/dist/library.css";
import { AiOutlineMail, AiOutlinePhone, AiOutlineStar, AiTwotoneStar } from 'react-icons/ai'
import { LiaMapMarkerAltSolid } from 'react-icons/lia'
import { CgWebsite } from 'react-icons/cg'
import { ImAddressBook } from 'react-icons/im'
import ReactDropdown from 'react-dropdown';
import 'react-dropdown/style.css';
import { Img } from 'react-image'
import error from './assets/error.png'
import Rating from 'react-rating';

const map_icon = <LiaMapMarkerAltSolid size={13} color="black" />
const mail_icon = <AiOutlineMail size={15} color="#28b8dc" />
const phone_icon = <AiOutlinePhone size={15} color="#28b8dc" />
const web_icon = <CgWebsite size={15} color="#28b8dc" />
const address_icon = <ImAddressBook size={15} color="#28b8dc" />

const accepted_db = db.ref('accepted_db')
//import './App.css';
//import { db } from './config';

//const newdb = db.ref('new')

const empty = <AiOutlineStar size={10} color='black' />
const fill = <AiTwotoneStar size={10} color='#FDCC0D' />

function Favourite() {

    const navigate = useNavigate();

    const [width, setWidth] = useState(window.innerWidth);
    const [height, setHeight] = useState(window.innerHeight);

    const [fav, setFav] = useState(null)

    const [loading, setLoading] = useState(false)

    const isMobile = width <= 768;

    useEffect(() => {
        if (fav == null) {
            let data = localStorage.getItem('favourite');
            if (data == null) { toast('No favourite is found !'); setFav([]) }
            else {
                data = JSON.parse(data)
                console.log(data)
                data = data.arr
                setFav([...data])
            }
        }
    })

    function handleWindowSizeChange() {
        setWidth(window.innerWidth);
        setHeight(window.innerHeight);
    }

    useEffect(() => {
        window.addEventListener('resize', handleWindowSizeChange);
        return () => {
            window.removeEventListener('resize', handleWindowSizeChange);
        }
    }, []);

    function remove_favourite(id) {
        let new_fav = []
        for (let i = 0; i < fav.length; i++) {
            if (fav[i].id != id) {
                new_fav.push(fav[i])
            }
        }
        setFav([...new_fav])
        localStorage.setItem('favourite', JSON.stringify({ arr: new_fav }))
    }

    const renderStandings = (item) => {

        if (item.Title == undefined) return (<div style={{ padding: 6, borderStyle: 'solid', borderWidth: 0.3, borderColor: '#28b8dc', cursor: 'pointer', display: 'flex', width: isMobile ? '96%' : '98%', flexDirection: 'column', backgroundColor: '#f7fcfe', justifyContent: 'center', marginBottom: 8, alignItems: 'center', height: isMobile ? 320 : 185, alignSelf: 'center', borderRadius: 6 }}>
            <div onClick={() => {
                remove_favourite(item.id)
            }} style={{ cursor: 'pointer', width: '98%', display: 'flex', justifyContent: 'space-between', flexDirection: 'row', alignItems: 'center', marginBottom: 4 }}>
                <text style={{ color: '#0066ff', fontWeight: '400', fontSize: 12 }}>{item.free != undefined ? '' : 'Sponsored'}</text>
                {favourite_icon}
            </div>
            <div onClick={() => navigate('/SingleListing', { state: { item: item } })} style={{ cursor: 'pointer', width: '98%', alignSelf: 'center', alignItems: 'center', display: 'flex', flexDirection: 'row', height: 200 }}>
                <div style={{ display: 'flex', height: isMobile ? 178 : 148, width: isMobile ? '38%' : 140, justifyContent: 'center' }}>
                    <img src={item.uri} alt='image' style={{ width: 140, height: 145, borderRadius: 2, objectFit: 'fill' }} />
                </div>
                <div style={{ display: 'flex', flexDirection: 'column', height: isMobile ? 190 : 150, width: isMobile ? '57%' : width - 220, justifyContent: 'space-around', marginLeft: 10, alignItems: 'flex-start', marginBottom: 6 }}>
                    <text style={{ color: '#28b8dc', fontSize: 16, fontWeight: '700', marginLeft: 7 }}>{item.business_name}</text>

                    <div style={{ display: 'flex', alignItems: 'center', flexDirection: 'row', marginTop: 4 }}>
                        <text style={{ color: 'black', fontWeight: '400', fontSize: 12, marginLeft: 10 }}>{item.website}</text>
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'row', marginTop: 4, alignItems: 'center', marginLeft: 6 }}>
                        {exist_rating(item) && <div style={{ display: 'flex', alignItems: 'center', flexDirection: 'row', backgroundColor: '#e7eff5', padding: 2, borderRadius: 4 }}>
                            <Rating
                                emptySymbol={empty}
                                fullSymbol={fill}
                                fractions={1}
                                initialRating={this_rating(item)}
                                readonly={true}
                                stop={5}
                            />
                            <text style={{ fontSize: 11, fontWeight: '400', marginLeft: 4 }}>{this_rating(item)}</text>
                        </div>}
                        {item.far_from != undefined && <div style={{ display: 'flex', alignItems: 'center', flexDirection: 'row', marginLeft: 10, backgroundColor: '#e7eff5', padding: 4, borderRadius: 4 }}>
                            {map_icon}
                            <text style={{ fontSize: 11, letterSpacing: 1.01 }}>{item.far_from.toFixed(1)} KM</text>
                        </div>}
                    </div>

                    <div style={{ display: 'flex', alignItems: 'center', flexDirection: 'row', marginTop: 4, marginLeft: 6 }}>
                        {address_icon}
                        <text style={{ color: 'black', fontWeight: '400', fontSize: 12, marginLeft: 10 }}>{item.address1} , {item.country}</text>
                    </div>
                </div>
            </div>
        </div>
        );

        else return (<div style={{ padding: 6, borderStyle: 'solid', borderWidth: 0.3, borderColor: '#28b8dc', cursor: 'pointer', display: 'flex', width: isMobile ? '96%' : '98%', flexDirection: 'column', backgroundColor: '#f7fcfe', justifyContent: 'center', marginBottom: 8, alignItems: 'center', maxHeight: isMobile ? 520 : 395, alignSelf: 'center', borderRadius: 6 }}>
            <div onClick={() => {
                remove_favourite(item.id)
            }} style={{ cursor: 'pointer', width: '98%', display: 'flex', justifyContent: 'space-between', flexDirection: 'row', alignItems: 'center', marginBottom: 4 }}>
                <text style={{ color: '#0066ff', fontWeight: '400', fontSize: 12 }}>Wordpress</text>
                {favourite_icon}
            </div>
            <div onClick={() => navigate('/SingleListingW', { state: { item: item } })} style={{ width: '98%', alignSelf: 'center', alignItems: 'center', display: 'flex', flexDirection: 'row', maxHeight: isMobile ? 460 : 370 }}>
                <div style={{ display: 'flex', maxHeight: isMobile ? 460 : 370, width: 140, justifyContent: 'center' }}>
                    <Img
                        style={{ width: 140, height: 145, borderRadius: 2, objectFit: 'contain' }}
                        src={item['Image URL']}
                        loader={<img src={error} alt="img" style={{ width: 140, height: 145, borderRadius: 2, objectFit: 'fill' }} />}
                        unloader={<img src={error} alt="img" style={{ width: 140, height: 145, borderRadius: 2, objectFit: 'fill' }} />}
                    />
                </div>
                <div style={{ display: 'flex', flexDirection: 'column', maxHeight: isMobile ? 460 : 370, justifyContent: 'space-around', marginLeft: 10, alignItems: 'flex-start', marginBottom: 6 }}>
                    <text style={{ color: '#28b8dc', fontSize: 16, fontWeight: '700', marginLeft: 10 }}>{item.Title}</text>

                    <div style={{ display: 'flex', alignItems: 'center', flexDirection: 'row', marginTop: 4, marginLeft: 6 }}>
                        <text style={{ color: 'black', fontWeight: '400', fontSize: 12, marginLeft: 8 }}>{item._company_website}</text>
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'row', marginTop: 4, alignItems: 'center', marginLeft: 8 }}>
                        {exist_rating(item) && <div style={{ display: 'flex', alignItems: 'center', flexDirection: 'row', backgroundColor: '#e7eff5', padding: 2, borderRadius: 4 }}>
                            <Rating
                                emptySymbol={empty}
                                fullSymbol={fill}
                                fractions={1}
                                initialRating={this_rating(item)}
                                readonly={true}
                                stop={5}
                            />
                            <text style={{ fontSize: 11, fontWeight: '400', marginLeft: 4 }}>{this_rating(item)}</text>
                        </div>}
                        {item.far_from != undefined && <div style={{ display: 'flex', alignItems: 'center', flexDirection: 'row', marginLeft: 10, backgroundColor: '#e7eff5', padding: 4, borderRadius: 4 }}>
                            {map_icon}
                            <text style={{ fontSize: 11, letterSpacing: 1.01 }}>{item.far_from.toFixed(1)} KM</text>
                        </div>}
                    </div>

                    <div style={{ display: 'flex', alignItems: 'center', flexDirection: 'row', marginTop: 4, justifyContent: 'left' }}>
                        <text style={{ color: 'black', fontWeight: '500', fontSize: 12, marginLeft: 10, textAlign: 'left' }}>{my_description(item.Content)}</text>
                    </div>

                </div>
            </div>
        </div>
        );
    }

    const renderStandingsGrid = (item) => {

        if (item.Title == undefined) return (<div style={{ marginLeft: '5%', padding: 6, borderStyle: 'solid', borderWidth: 0.3, borderColor: '#28b8dc', cursor: 'pointer', display: 'flex', width: '40%', flexDirection: 'column', backgroundColor: '#f7fcfe', justifyContent: 'center', marginBottom: 8, alignItems: 'center', height: isMobile ? 320 : 185, alignSelf: 'center', borderRadius: 6 }}>
            <div onClick={() => {
                remove_favourite(item.id)
            }} style={{ cursor: 'pointer', width: '98%', display: 'flex', justifyContent: 'space-between', flexDirection: 'row', alignItems: 'center', marginBottom: 4 }}>
                <text style={{ color: '#0066ff', fontWeight: '400', fontSize: 12 }}>{item.free != undefined ? '' : 'Sponsored'}</text>
                {favourite_icon}
            </div>
            <div onClick={() => navigate('/SingleListing', { state: { item: item } })} style={{ width: '98%', alignSelf: 'center', alignItems: 'center', display: 'flex', flexDirection: 'row', height: 200 }}>
                <div style={{ display: 'flex', height: isMobile ? 178 : 148, width: isMobile ? '38%' : 140, justifyContent: 'center' }}>
                    <img src={item.uri} alt='image' style={{ width: 140, height: 145, borderRadius: 2, objectFit: 'fill' }} />
                </div>
                <div style={{ display: 'flex', flexDirection: 'column', height: isMobile ? 190 : 150, width: isMobile ? '57%' : width - 220, justifyContent: 'space-around', marginLeft: 10, alignItems: 'flex-start', marginBottom: 6 }}>
                    <text style={{ color: '#28b8dc', fontSize: 16, fontWeight: '700' }}>{item.business_name}</text>
                    <div style={{ display: 'flex', alignItems: 'center', flexDirection: 'row', marginTop: 4 }}>
                        {mail_icon}
                        <text style={{ color: 'black', fontWeight: '400', fontSize: 12, marginLeft: 10 }}>{item.email}</text>
                    </div>
                    <div style={{ display: 'flex', alignItems: 'center', flexDirection: 'row', marginTop: 4 }}>
                        {web_icon}
                        <text style={{ color: 'black', fontWeight: '400', fontSize: 12, marginLeft: 10 }}>{item.website}</text>
                    </div>
                    <div style={{ display: 'flex', alignItems: 'center', flexDirection: 'row', marginTop: 4 }}>
                        <text style={{ color: '#28b8dc', fontWeight: '400', fontSize: 13 }}>Open . </text>
                        <text style={{ color: 'black', fontWeight: '400', fontSize: 12, marginLeft: 10 }}>{item.start}</text>

                        <text style={{ color: '#28b8dc', fontWeight: '400', fontSize: 13, marginLeft: 15 }}>-  Close . </text>
                        <text style={{ color: 'black', fontWeight: '400', fontSize: 12, marginLeft: 10 }}>{item.end}</text>
                    </div>
                    <div style={{ display: 'flex', alignItems: 'center', flexDirection: 'row', marginTop: 4 }}>
                        {phone_icon}
                        <text style={{ color: 'black', fontWeight: '400', fontSize: 12, marginLeft: 10 }}>{item.phone}</text>
                    </div>
                    <div style={{ display: 'flex', alignItems: 'center', flexDirection: 'row', marginTop: 4 }}>
                        {address_icon}
                        <text style={{ color: 'black', fontWeight: '400', fontSize: 12, marginLeft: 10 }}>{item.address1} , {item.country}</text>
                    </div>
                </div>
            </div>
        </div>
        );

        else return (<div style={{ marginLeft: '5%', padding: 6, borderStyle: 'solid', borderWidth: 0.3, borderColor: '#28b8dc', cursor: 'pointer', display: 'flex', width: '40%', flexDirection: 'column', backgroundColor: '#f7fcfe', justifyContent: 'center', marginBottom: 8, alignItems: 'center', height: isMobile ? 320 : 185, alignSelf: 'center', borderRadius: 6 }}>
            <div onClick={() => {
                remove_favourite(item.id)
            }} style={{ cursor: 'pointer', width: '98%', display: 'flex', justifyContent: 'space-between', flexDirection: 'row', alignItems: 'center', marginBottom: 4 }}>
                <text style={{ color: '#0066ff', fontWeight: '400', fontSize: 12 }}>Wordpress</text>
                {favourite_icon}
            </div>
            <div onClick={() => navigate('/SingleListingW', { state: { item: item } })} style={{ width: '98%', alignSelf: 'center', alignItems: 'center', display: 'flex', flexDirection: 'row', height: 160 }}>
                <div style={{ display: 'flex', height: 148, width: 140, justifyContent: 'center' }}>
                    <Img
                        style={{ width: 140, height: 145, borderRadius: 2, objectFit: 'contain' }}
                        src={item['Image URL']}
                        loader={<img src={error} alt="img" style={{ width: 140, height: 145, borderRadius: 2, objectFit: 'fill' }} />}
                        unloader={<img src={error} alt="img" style={{ width: 140, height: 145, borderRadius: 2, objectFit: 'fill' }} />}
                    />
                </div>
                <div style={{ display: 'flex', flexDirection: 'column', height: 150, justifyContent: 'space-around', marginLeft: 10, alignItems: 'flex-start', marginBottom: 6 }}>
                    <text style={{ color: '#28b8dc', fontSize: 16, fontWeight: '700' }}>{item.Title}</text>
                    <div style={{ display: 'flex', alignItems: 'center', flexDirection: 'row', marginTop: 4 }}>
                        {mail_icon}
                        <text style={{ color: 'black', fontWeight: '400', fontSize: 12, marginLeft: 10 }}>{item._company_email}</text>
                    </div>
                    <div style={{ display: 'flex', alignItems: 'center', flexDirection: 'row', marginTop: 4 }}>
                        {web_icon}
                        <text style={{ color: 'black', fontWeight: '400', fontSize: 12, marginLeft: 10 }}>{item._company_website}</text>
                    </div>
                    <div style={{ display: 'flex', alignItems: 'center', flexDirection: 'row', marginTop: 4 }}>
                        {phone_icon}
                        <text style={{ color: 'black', fontWeight: '400', fontSize: 12, marginLeft: 10 }}>{item._company_phone}</text>
                    </div>
                    <div style={{ display: 'flex', alignItems: 'center', flexDirection: 'row', marginTop: 4 }}>
                        {address_icon}
                        <text style={{ color: 'black', fontWeight: '400', fontSize: 12, marginLeft: 10 }}>{item.geolocation_formatted_address} , {item.geolocation_country_long}</text>
                    </div>
                </div>
            </div>
        </div>
        );
    }

    function exist_rating(item) {
        let all = (item.rating1 == undefined ? 0 : item.rating1) + (item.rating2 == undefined ? 0 : item.rating2) + (item.rating3 == undefined ? 0 : item.rating3) + (item.rating4 == undefined ? 0 : item.rating4) + (item.rating5 == undefined ? 0 : item.rating5)
        if (all > 0) return true;
        else return false
    }

    function this_rating(item) {
        let five = item.rating5 == undefined ? 0 : item.rating5;
        let four = item.rating4 == undefined ? 0 : item.rating4;
        let three = item.rating3 == undefined ? 0 : item.rating3;
        let two = item.rating2 == undefined ? 0 : item.rating2;
        let one = item.rating1 == undefined ? 0 : item.rating1;

        let all = (item.rating1 == undefined ? 0 : item.rating1) + (item.rating2 == undefined ? 0 : item.rating2) + (item.rating3 == undefined ? 0 : item.rating3) + (item.rating4 == undefined ? 0 : item.rating4) + (item.rating5 == undefined ? 0 : item.rating5)

        let avg = 0;
        if (all != 0) avg = ((five * 5 + four * 4 + three * 3 + two * 2 + one * 1) / (all)).toFixed(1)

        return avg;

    }

    function my_description(str) {
        let ret = String(str);
        ret = ret.replaceAll('<span>', '')
        ret = ret.replaceAll('<span', '')
        ret = ret.replaceAll('<img', '')
        ret = ret.replaceAll('class=', '')
        ret = ret.replaceAll('gt3_dl', '')
        ret = ret.replaceAll('</span>', '')
        ret = ret.replaceAll('</div>', '')
        ret = ret.replaceAll('<div>', '')
        ret = ret.replaceAll('<div', '')
        ret = ret.replaceAll('</dt>', '')
        ret = ret.replaceAll('<dt>', '')
        ret = ret.replaceAll('<dt', '')
        ret = ret.replaceAll('<dl>', '')
        ret = ret.replaceAll('<dl', '')
        ret = ret.replaceAll('</dl>', '')
        ret = ret.replaceAll('<dd>', '')
        ret = ret.replaceAll('<dd', '')
        ret = ret.replaceAll('</dd>', '')
        ret = ret.replaceAll('header', '')
        ret = ret.replaceAll('&amp', '')
        ret = ret.replaceAll('""', '')

        ret = ret.replaceAll('>', '')
        ret = ret.replaceAll('<', '')

        ret = ret.replaceAll('_aac', '')
        return ret;
    }

    return (
        <div style={{ display: 'flex', flexDirection: 'column', width: '100%', overflow: 'hidden', padding: 2 }}>

            <wc-toast></wc-toast>

            <div style={{ alignItems: 'center', width: '90%', display: 'flex', flexDirection: 'row', alignSelf: 'center', height: 80, justifyContent: 'space-between' }}>
                <div onClick={() => navigate('/')} style={{ cursor: 'pointer', width: '30%', display: 'flex', justifyContent: 'flex-start' }}>
                    <img src={require('../nwlb.jpg')} style={{ height: 60, width: 60, borderRadius: 30, objectFit: 'contain' }} />
                </div>
                <div style={{ width: '50%', display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-evenly' }}>
                    {isMobile && <ReactDropdown
                        arrowClosed={<text></text>}
                        arrowOpen={<text></text>}
                        options={['Learn', 'Join', 'Contact us', 'Profile', 'Add Listing']}
                        onChange={(e) => {
                            let command = e.value;
                            if (command.includes("Contact")) {
                                navigate('/Contact')
                            }
                            else if (command.includes("Profile")) {
                                navigate('/Login')
                            }
                            else if (command.includes("Add")) {
                                navigate('/AddListing')
                            }
                        }}
                        placeholder="     Menu     "
                    />}
                    {!isMobile && <text style={{ fontSize: 16, color: 'black', fontWeight: '500' }}>Favourite</text>}
                    {!isMobile && <text style={{ fontSize: 16, color: 'black', fontWeight: '500' }}>Learn</text>}
                    {!isMobile && <text onClick={() => navigate('/Join')} style={{ fontSize: 16, color: 'black', fontWeight: '500' }}>Join</text>}
                    {!isMobile && <text onClick={() => navigate('/Contact')} style={{ cursor: 'pointer', fontSize: 16, color: 'black', fontWeight: '500' }}>Contact us</text>}
                    {!isMobile && <div onClick={() => navigate('/Login')} style={{ cursor: 'pointer', display: 'flex', alignContent: 'center', alignItems: 'center', backgroundColor: '#28b8dc', height: 35, width: 35, borderRadius: 4, justifyContent: 'center' }}>
                        {user_icon}
                    </div>}
                    {!isMobile && <div onClick={() => navigate('/AddListing')} style={{ cursor: 'pointer', display: 'flex', alignContent: 'center', flexDirection: 'row', alignItems: 'center', backgroundColor: '#28b8dc', height: 35, width: 130, borderRadius: 4, justifyContent: 'center' }}>
                        {plus_icon}
                        <text style={{ marginLeft: 4, color: 'white', fontSize: 12 }}>Add Listing</text>
                    </div>}
                </div>
            </div>

            <div style={{ height: 130, backgroundColor: '#d4f1f8', display: 'flex', justifyContent: 'center', marginTop: 20 }}>
                <text style={{ fontWeight: '600', fontSize: 45, alignSelf: 'center', color: '#089ec4' }}>Favourite</text>
            </div>

            <div style={{ width: '90%', height: 0.5, backgroundColor: '#28b8dc', alignSelf: 'center', marginTop: 20, marginBottom: 30 }} />

            <div style={{ display: 'flex', overflow: "auto", overflowX: "none", height: 550, marginTop: 1, flexDirection: 'column', width: isMobile ? '98%' : '88%', alignSelf: 'center' }}>
                {fav != null && <FlatList
                    list={fav}
                    renderItem={(id) => renderStandings(id)}
                    renderWhenEmpty={() => <div style={{ height: 1, backgroundColor: '#EEEEEE' }} />}
                />}
            </div>


            <div style={{ alignSelf: 'center', display: 'flex', width: width, backgroundColor: '#28b8dc', height: 200, alignItems: 'center', justifyContent: 'center' }}>
                <div style={{ width: '90%', alignSelf: 'center', display: 'flex', flexDirection: 'row', height: 180, alignItems: 'center', justifyContent: 'space-between' }}>
                    <div style={{ flexDirection: 'column', display: 'flex', alignItems: 'flex-start', width: '40%', justifyContent: 'center' }}>
                        <img src={require('../nwlb.jpg')} style={{ height: 60, width: 60, borderRadius: 30, objectFit: 'contain', marginBottom: 6 }} />
                        <text style={{ color: 'white', fontSize: 12, fontWeight: '300', textAlign: 'left' }}>
                            Download our app North West Local Business - Easily find the services and products you need while enjoying
                            exclusive features not available on our website.
                        </text>
                    </div>
                    <div style={{ flexDirection: 'column', display: 'flex', alignItems: 'flex-start', width: '14%' }}>
                        <text style={{ fontSize: 14, color: 'white', fontWeight: '800' }}>OUR LOCATION</text>
                        <text style={{ color: 'white', fontSize: 12, fontWeight: '300', marginTop: 6 }}>
                            Northwest, UK
                        </text>
                    </div>
                    <div style={{ flexDirection: 'column', display: 'flex', alignItems: 'flex-start', width: '14%' }}>
                        <text style={{ fontSize: 14, color: 'white', fontWeight: '800' }}>CONTACT US</text>
                        <text style={{ color: 'white', fontSize: 12, fontWeight: '300', marginTop: 6 }}>
                            Tel: 0800 9750341
                        </text>
                    </div>
                </div>
            </div>

            <div style={{ backgroundColor: '#089ec4', width: width, display: 'flex', alignItems: 'center', height: 60, alignSelf: 'center', justifyContent: 'center' }}>
                <div style={{ width: isMobile ? '95%' : '90%', alignSelf: 'center', height: 50, display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between' }}>

                    <div style={{ width: '40%', display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between' }}>
                        <text onClick={() => navigate('/Condition')} style={{ cursor: 'pointer', color: 'white', fontWeight: '400', fontSize: 13, textDecorationLine: 'underline', textDecorationColor: 'white' }}>Terms & Conditions</text>
                        <text style={{ color: 'white', fontWeight: '400', fontSize: 13, textDecorationLine: 'underline', textDecorationColor: 'white' }}>Cookie Policy</text>
                        <text onClick={() => navigate('/Term')} style={{ cursor: 'pointer', color: 'white', fontWeight: '400', fontSize: 13, textDecorationLine: 'underline', textDecorationColor: 'white' }}>Privacy Policy</text>
                    </div>

                    <div style={{ width: isMobile ? '50%' : '25%', display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-around' }}>
                        <div style={{ display: 'flex', alignContent: 'center', alignItems: 'center', backgroundColor: '#28b8dc', height: 40, width: 40, borderRadius: 20, justifyContent: 'center' }}>
                            {instagram_icon}
                        </div>
                        <div style={{ display: 'flex', alignContent: 'center', alignItems: 'center', backgroundColor: '#28b8dc', height: 40, width: 40, borderRadius: 20, justifyContent: 'center' }}>
                            {twitter_icon}
                        </div>
                        <div style={{ display: 'flex', alignContent: 'center', alignItems: 'center', backgroundColor: '#28b8dc', height: 40, width: 40, borderRadius: 20, justifyContent: 'center' }}>
                            {facebook_icon}
                        </div>
                        <div style={{ display: 'flex', alignContent: 'center', alignItems: 'center', backgroundColor: '#28b8dc', height: 40, width: 40, borderRadius: 20, justifyContent: 'center' }}>
                            {instagram_icon}
                        </div>
                        <div style={{ display: 'flex', alignContent: 'center', alignItems: 'center', backgroundColor: '#28b8dc', height: 40, width: 40, borderRadius: 20, justifyContent: 'center' }}>
                            {instagram_icon}
                        </div>
                    </div>

                </div>
            </div>

            {loading && <div style={{ position: 'absolute', top: '35%', left: '48%', backgroundColor: 'white', padding: 10 }}><Levels size={35} /></div>}


        </div>
    );
}

export default Favourite;

/*
 <div style={{ display: 'flex', overflow: "auto",overflowX: "none", height: 550, marginTop: 1, flexDirection: 'column', width: '88%', alignSelf: 'center' }}>
                <FlatList
                    list={['', '', '', '', '', '', '', '']}
                    renderItem={(id) => renderStandings(id)}
                    renderWhenEmpty={() => <div style={{ height: 1, backgroundColor: '#EEEEEE' }} />}
                />
            </div>
            */