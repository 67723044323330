import React, { useState, useEffect, useRef } from 'react';
import { address_big, back_icon, face_big, facebook_icon, favourite_icon, instagram_icon, mail_big, plus_icon, search_icon, tiktok_big, tube_big, twitter_icon, user_icon, whatsapp_big } from './icons';
import '../App.css';
import { useNavigate, useLocation } from 'react-router-dom';
import { toast } from 'wc-toast';
import { db, storage } from '../config';
import "react-activity/dist/library.css";
import 'react-dropdown/style.css';
import { AiOutlineStar, AiTwotoneStar, AiOutlineCopy } from 'react-icons/ai'
import { FaUser } from 'react-icons/fa'

import { BsFacebook, BsTiktok, BsYoutube, BsTwitter, BsSnapchat, BsInstagram, BsWhatsapp } from 'react-icons/bs'
import { FaSoundcloud } from 'react-icons/fa'
import { BiComment } from 'react-icons/bi'
import { LiaAngleUpSolid, LiaAngleDownSolid } from 'react-icons/lia'
import { FacebookIcon, FacebookShareButton, TwitterIcon, TwitterShareButton } from 'react-share';


const up = <LiaAngleUpSolid size={20} color='#089ec4' />
const down = <LiaAngleDownSolid size={20} color='#089ec4' />

const comment_input = <BiComment size={20} color='#089ec4' />

const facebook_input = <BsFacebook size={20} color='#089ec4' />
const tiktok_input = <BsTiktok size={20} color='#089ec4' />
const youtube_input = <BsYoutube size={20} color='#089ec4' />
const twitter_input = <BsTwitter size={20} color='#089ec4' />

const snap_input = <BsSnapchat size={20} color='#089ec4' />
const instagam_input = <BsInstagram size={20} color='#089ec4' />
const whatsapp_input = <BsWhatsapp size={20} color='#089ec4' />
const sound_input = <FaSoundcloud size={20} color='#089ec4' />


const user_profile = <FaUser size={35} color='gray' />

const empty = <AiOutlineStar size={30} color='black' />
const fill = <AiTwotoneStar size={30} color='#FDCC0D' />
//const newdb = db.ref('new')

const field_background = "#E4ECF7"

const waiting_db = db.ref('waiting_db');
const accepted_db = db.ref('accepted_db')
const admin_distance_db = db.ref('admin_distance_db');
const default_price_db = db.ref('default_price_db')

const review_db = db.ref('review_db')

function id(mail) {
    let str = ''
    for (let i = 0; i < mail.length; i++) {
        if ((mail[i] >= 'a' && mail[i] <= 'z') || (mail[i] >= '0' && mail[i] <= '9')) str += mail[i]
    }
    return str;
}

function Policy(props) {

    const navigate = useNavigate();

    const [item, setItem] = useState(null);

    const [width, setWidth] = useState(window.innerWidth);
    const [height, setHeight] = useState(window.innerHeight);


    function handleWindowSizeChange() {
        setWidth(window.innerWidth);
        setHeight(window.innerHeight);
    }

    useEffect(() => {
        window.addEventListener('resize', handleWindowSizeChange);
        return () => {
            window.removeEventListener('resize', handleWindowSizeChange);
        }
    }, []);

    const isMobile = width <= 768;

    // https://api.whatsapp.com/send?phone=1234567890

    // window.open('mailto:email@example.com?subject=Subject&body=Body%20goes%20here')

    return (
        <div style={{ display: 'flex', flexDirection: 'column', width: '100%', overflow: 'hidden', padding: 2 }}>

            <wc-toast></wc-toast>
            <div style={{ cursor: 'pointer', alignSelf: 'center', display: 'flex', alignContent: 'center', alignItems: 'center', height: 45, borderRadius: 4, justifyContent: 'space-between', flexDirection: 'row', width: '80%', marginTop: 15 }}>
                <div onClick={() => navigate('/')} style={{ cursor: 'pointer', backgroundColor: '#28b8dc', display: 'flex', height: 42, width: 40, borderRadius: 10, alignItems: 'center', justifyContent: 'center', alignContent: 'center' }}>
                    {back_icon}
                </div>
                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                    <text></text>
                </div>
                <text></text>
            </div>

            <div style={{ width: isMobile ? '90%' : '95%', height: isMobile ? 400 : 500, alignSelf: 'center', flexDirection: 'column', marginTop: 30 }}>
                <iframe src={'https://firebasestorage.googleapis.com/v0/b/nwlb-fb8ad.appspot.com/o/NWLB%20-%20Privacy%20Policy.pdf?alt=media&token=9e315ea9-d5bd-47c0-bb0a-78486ceee041'} width="95%" height={isMobile ? 400 : 500} />
            </div>

        </div>
    );
}

export default Policy;

// https://firebasestorage.googleapis.com/v0/b/nwlb-fb8ad.appspot.com/o/NWLB%20-%20Privacy%20Policy.pdf?alt=media&token=9e315ea9-d5bd-47c0-bb0a-78486ceee041