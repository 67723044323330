import React from 'react'
import '../App.css';
import {
    BrowserRouter,
    Routes, //replaces "Switch" used till v5
    Route,
    HashRouter
} from "react-router-dom";

import Dashboard from './Dashboard';
import Contact from './Contact';
import AddListing from './AddListing';
import SearchList from './SearchList';
import Login from './Login';
import Favourite from './Favourite';
import SingleListing from './SingleListing';
import SingleListingW from './SingleListingW';
import News from './News';
import SingleProduct from './SingleProduct';
import SingleProductW from './SingleProductW';
import Join from './Join';
import Policy from './Policy';
import Condition from './Condition';
import Delete from './Delete';

export default function Main() {
    return (
        <div className="App">
            <HashRouter>
                <Routes>

                    <Route path="*" element={<SingleProduct />} />
                    <Route path="/SingleProduct" element={<SingleProduct />} />
                    <Route path="/SingleProductW" element={<SingleProductW />} />
                    <Route path="/" element={<Dashboard />} />
                    <Route path="/Contact" element={<Contact />} />
                    <Route path="/AddListing" element={<AddListing />} />
                    <Route path="/SearchList" element={<SearchList />} />
                    <Route path="/Login" element={<Login />} />
                    <Route path="/Favourite" element={<Favourite />} />
                    <Route path="/SingleListing" element={<SingleListing />} />
                    <Route path="/SingleListingW" element={<SingleListingW />} />
                    <Route path="/News" element={<News />} />
                    <Route path="/Join" element={<Join />} />
                    <Route path="/Term" element={<Policy />} />
                    <Route path="/Condition" element={<Condition />} />
                    <Route path="/Delete" element={<Delete />} />

                </Routes>
            </HashRouter>
        </div>
    );
}