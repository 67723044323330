import React, { useState, useEffect, useRef } from 'react';
import { address_big, face_big, facebook_icon, favourite_icon, instagram_icon, mail_big, plus_icon, search_icon, tiktok_big, tube_big, twitter_icon, user_icon, whatsapp_big } from './icons';
import FlatList from 'flatlist-react/lib';
import back from './back.png'
import customer from './customer1.jpg'
import { useNavigate, useLocation } from 'react-router-dom';
import ReactDropdown from 'react-dropdown';
import 'react-dropdown/style.css';
//import './App.css';
//import { db } from './config';

//const newdb = db.ref('new')

function Join() {

    const navigate = useNavigate();

    const { state } = useLocation();

    const [width, setWidth] = useState(window.innerWidth);
    const [height, setHeight] = useState(window.innerHeight);

    function handleWindowSizeChange() {
        setWidth(window.innerWidth);
        setHeight(window.innerHeight);
    }

    const isMobile = width <= 768;

    useEffect(() => {
        window.addEventListener('resize', handleWindowSizeChange);
        return () => {
            window.removeEventListener('resize', handleWindowSizeChange);
        }
    }, []);


    return (
        <div style={{ display: 'flex', flexDirection: 'column', width: '100%', overflow: 'hidden', padding: 2 }}>

            <div style={{ alignItems: 'center', width: '90%', display: 'flex', flexDirection: 'row', alignSelf: 'center', height: 80, justifyContent: 'space-between' }}>
                <div onClick={() => navigate('/')} style={{ cursor: 'pointer', width: '30%', display: 'flex', justifyContent: 'flex-start' }}>
                    <img src={require('../nwlb.jpg')} style={{ height: 60, width: 60, borderRadius: 30, objectFit: 'contain' }} />

                </div>
                <div style={{ width: '50%', display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-evenly' }}>
                    {isMobile && <ReactDropdown
                        arrowClosed={<text></text>}
                        arrowOpen={<text></text>}
                        options={['Favourite', 'Learn', 'Join', 'Contact us', 'Profile', 'Add Listing']}
                        onChange={(e) => {
                            let command = e.value;
                            if (command.includes('Favourite')) {
                                navigate('/Favourite')
                            }
                            else if (command.includes("Contact")) {
                                navigate('/Contact')
                            }
                            else if (command.includes("Profile")) {
                                navigate('/Login')
                            }
                            else if (command.includes("Add")) {
                                navigate('/AddListing')
                            }
                            else if (command.includes("Join")) {
                                navigate('/Join')
                            }
                        }}
                        placeholder="     Menu     "
                    />}
                    {!isMobile && <text onClick={() => navigate('/Favourite')} style={{ cursor: 'pointer', fontSize: 16, color: 'black', fontWeight: '500' }}>Favourite</text>}
                    {!isMobile && <text style={{ fontSize: 16, color: 'black', fontWeight: '500' }}>Learn</text>}
                    {!isMobile && <text style={{ fontSize: 16, color: 'black', fontWeight: '500' }}>Join</text>}
                    {!isMobile && <text onClick={() => navigate('/Contact')} style={{ cursor: 'pointer', fontSize: 16, color: 'black', fontWeight: '500' }}>Contact us</text>}
                    {!isMobile && <div onClick={() => navigate('/Login')} style={{ cursor: 'pointer', display: 'flex', alignContent: 'center', alignItems: 'center', backgroundColor: '#28b8dc', height: 35, width: 35, borderRadius: 4, justifyContent: 'center' }}>
                        {user_icon}
                    </div>}
                    {!isMobile && <div onClick={() => navigate('/AddListing')} style={{ cursor: 'pointer', display: 'flex', alignContent: 'center', flexDirection: 'row', alignItems: 'center', backgroundColor: '#28b8dc', height: 35, width: 130, borderRadius: 4, justifyContent: 'center' }}>
                        {plus_icon}
                        <text style={{ marginLeft: 4, color: 'white', fontSize: 12 }}>Add Listing</text>
                    </div>}
                </div>
            </div>

            <div style={{ display: 'flex', width: '80%', alignSelf: 'center', marginTop: 90, height: isMobile ? 490 : 270, flexDirection: 'column', marginBottom: 20, justifyContent: 'space-between' }}>
                <text style={{ textAlign: 'left', letterSpacing: 1.02, fontSize: 18, marginTop: 2 }}>Every independent business needs the chance to succeed. We at Northwest Local Business want the local community to thrive.

                    Our advertising brochure will be delivered to homes throughout the Northwest right throughout the year, starting in Liverpool. </text>

                <text style={{ textAlign: 'left', letterSpacing: 1.05, fontSize: 18, marginTop: 2 }}> There is no better way to reach a wide customer base and to amplify your brands visibility.

                    Here at Northwest Local Business, we do it all, we advertise your business online and offline, targeting your customers.

                    Leverage our app and tap into our extensive platform as well as our partner platforms.</text>
                <text style={{ textAlign: 'left', letterSpacing: 1.02, fontSize: 18, marginTop: 2 }}> With a dedicated following of over 50,000 on our social media channels, listing your business with us guarantees exposure to an engaged audience.

                    Sign up today and take advantage of this opportunity to showcase your business via our app, social media channels, our partner network, and our extensive brochure distribution service.
                </text>
            </div>


            <div style={{ alignSelf: 'center', display: 'flex', width: width, backgroundColor: '#28b8dc', height: 200, alignItems: 'center', justifyContent: 'center' }}>
                <div style={{ width: '90%', alignSelf: 'center', display: 'flex', flexDirection: 'row', height: 180, alignItems: 'center', justifyContent: 'space-between' }}>
                    <div style={{ flexDirection: 'column', display: 'flex', alignItems: 'flex-start', width: '40%', justifyContent: 'center' }}>
                        <img src={require('../nwlb.jpg')} style={{ height: 60, width: 60, borderRadius: 30, objectFit: 'contain', marginBottom: 6 }} />
                        <text style={{ color: 'white', fontSize: 12, fontWeight: '300', textAlign: 'left' }}>
                            Download our app North West Local Business - Easily find the services and products you need while enjoying
                            exclusive features not available on our website.
                        </text>
                    </div>
                    <div style={{ flexDirection: 'column', display: 'flex', alignItems: 'flex-start', width: '14%' }}>
                        <text style={{ fontSize: 14, color: 'white', fontWeight: '800' }}>OUR LOCATION</text>
                        <text style={{ color: 'white', fontSize: 12, fontWeight: '300', marginTop: 6 }}>
                            Northwest, UK
                        </text>
                    </div>
                    <div style={{ flexDirection: 'column', display: 'flex', alignItems: 'flex-start', width: '14%' }}>
                        <text style={{ fontSize: 14, color: 'white', fontWeight: '800' }}>CONTACT US</text>
                        <text style={{ color: 'white', fontSize: 12, fontWeight: '300', marginTop: 6 }}>
                            Tel: 0800 9750341
                        </text>
                    </div>
                </div>
            </div>

            <div style={{ backgroundColor: '#089ec4', width: '100%', display: 'flex', alignItems: 'center', height: 60, alignSelf: 'center', justifyContent: 'center' }}>
                <div style={{ width: '90%', alignSelf: 'center', height: 50, display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between' }}>

                    <div style={{ width: '40%', display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between' }}>
                        <text onClick={() => navigate('/Condition')} style={{ cursor: 'pointer', color: 'white', fontWeight: '400', fontSize: 13, textDecorationLine: 'underline', textDecorationColor: 'white' }}>Terms & Conditions</text>
                        <text style={{ color: 'white', fontWeight: '400', fontSize: 13, textDecorationLine: 'underline', textDecorationColor: 'white' }}>Cookie Policy</text>
                        <text onClick={() => navigate('/Term')} style={{ cursor: 'pointer', color: 'white', fontWeight: '400', fontSize: 13, textDecorationLine: 'underline', textDecorationColor: 'white' }}>Privacy Policy</text>
                    </div>

                    <div style={{ width: '25%', display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-around' }}>
                        <div style={{ display: 'flex', alignContent: 'center', alignItems: 'center', backgroundColor: '#28b8dc', height: 40, width: 40, borderRadius: 20, justifyContent: 'center' }}>
                            {instagram_icon}
                        </div>
                        <div style={{ display: 'flex', alignContent: 'center', alignItems: 'center', backgroundColor: '#28b8dc', height: 40, width: 40, borderRadius: 20, justifyContent: 'center' }}>
                            {twitter_icon}
                        </div>
                        <div style={{ display: 'flex', alignContent: 'center', alignItems: 'center', backgroundColor: '#28b8dc', height: 40, width: 40, borderRadius: 20, justifyContent: 'center' }}>
                            {facebook_icon}
                        </div>
                        <div style={{ display: 'flex', alignContent: 'center', alignItems: 'center', backgroundColor: '#28b8dc', height: 40, width: 40, borderRadius: 20, justifyContent: 'center' }}>
                            {instagram_icon}
                        </div>
                        <div style={{ display: 'flex', alignContent: 'center', alignItems: 'center', backgroundColor: '#28b8dc', height: 40, width: 40, borderRadius: 20, justifyContent: 'center' }}>
                            {instagram_icon}
                        </div>
                    </div>

                </div>
            </div>

        </div>
    );
}

export default Join;