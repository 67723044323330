import firebase from 'firebase/compat/app';
//import firebase from "firebase";
import "firebase/compat/database";
import "firebase/compat/storage";
import "firebase/compat/auth";
import "firebase/compat/firestore"
import "firebase/compat/messaging"
//import "firebase/compat/messaging"

const config = {
    apiKey: "AIzaSyDxNLBODql45bd7K1Ug223bkjjF2f7ifnw",
    authDomain: "nwlb-fb8ad.firebaseapp.com",
    databaseURL: "https://nwlb-fb8ad-default-rtdb.firebaseio.com",
    projectId: "nwlb-fb8ad",
    storageBucket: "nwlb-fb8ad.appspot.com",
    messagingSenderId: "447739160844",
    appId: "1:447739160844:web:5c82a457450a2d3a7c4129",
    measurementId: "G-XGVNXPN57R"
};

firebase.initializeApp(config);
// const db=firebase.database();
export const db = firebase.database();
export const storage = firebase.storage();
export const auth = firebase.auth();
export const firestore = firebase.firestore()
//export const firebases = firebase;