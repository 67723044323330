import React, { useState, useEffect, useRef } from 'react';
import { facebook_icon, favourite_icon, instagram_icon, non_favourite_icon, plus_icon, search_icon, twitter_icon, user_icon } from './icons';
import FlatList from 'flatlist-react/lib';
import { useNavigate, useLocation } from 'react-router-dom';
import { db } from '../config';
import { toast } from 'wc-toast';
import { Levels } from 'react-activity';
import "react-activity/dist/library.css";
import { AiOutlineMail, AiOutlinePhone } from 'react-icons/ai'
import { CgWebsite } from 'react-icons/cg'
import { ImAddressBook } from 'react-icons/im'
import error from './assets/error.png'
import ReactDropdown from 'react-dropdown';
import 'react-dropdown/style.css';
import { Img } from 'react-image'
import moment from 'moment/moment';
import { AiOutlineStar, AiTwotoneStar } from 'react-icons/ai'
import Rating from 'react-rating';
import { LiaMapMarkerAltSolid } from 'react-icons/lia'
import SingleTest from './SingleTest';
import SingleTestW from './SingleTestW';

const mail_icon = <AiOutlineMail size={15} color="#28b8dc" />
const phone_icon = <AiOutlinePhone size={15} color="#28b8dc" />
const web_icon = <CgWebsite size={15} color="#28b8dc" />
const address_icon = <ImAddressBook size={15} color="#28b8dc" />

const map_icon = <LiaMapMarkerAltSolid size={13} color="black" />

const empty = <AiOutlineStar size={10} color='black' />
const fill = <AiTwotoneStar size={10} color='#FDCC0D' />

const accepted_db = db.ref('accepted_db')
const wordpress_db = db.ref('wordpress')
//import './App.css';
//import { db } from './config';

//const newdb = db.ref('new')

function SearchList() {

    const navigate = useNavigate();

    const { state } = useLocation();

    const { search_word, post_code, isAble } = state

    console.log(isAble, search_word, post_code)

    const [search, setSearch] = useState(search_word)
    const [post, setPost] = useState(post_code)
    const [isopen, setIsOpen] = useState(isAble)


    const [width, setWidth] = useState(window.innerWidth);
    const [height, setHeight] = useState(window.innerHeight);

    const [fav, setFav] = useState(null)
    const [paid, setPaid] = useState(null)
    const [all, setAll] = useState([])

    const [curr, setCurr] = useState(null)

    useEffect(() => {
        const keyDownHandler = event => {
            console.log('User pressed: ', event.key);

            if (event.key === 'Enter') {
                event.preventDefault();
                getting_long_lat()
            }
        };

        document.addEventListener('keydown', keyDownHandler);

        return () => {
            document.removeEventListener('keydown', keyDownHandler);
        };
    }, [post, search, isopen]);


    function getting_fav() {
        let db = localStorage.getItem('favourite');
        if (db != null) {
            db = JSON.parse(db);
            db = db.arr
            setFav([...db])
        }
        else {
            setFav([])
        }
    }

    function remove_favourite(id) {
        let new_fav = []
        for (let i = 0; i < fav.length; i++) {
            if (fav[i].id != id) {
                new_fav.push(fav[i])
            }
        }
        setFav([...new_fav])
        localStorage.setItem('favourite', JSON.stringify({ arr: new_fav }))
    }

    function sendMailFavourite(rat_email) {
        // 192.168.0.107
        // https://us-central1-nwlb-fb8ad.cloudfunctions.net/app/mail_favourite
        console.log('Sending favourite')
        fetch(`https://us-central1-nwlb-fb8ad.cloudfunctions.net/app/mail_favourite?email=${rat_email}`).then((val) => val.json().then((val) => {

        })).catch(e => console.log(e))
    }

    function setting_favourite(id, rat_email) {
        let item;
        for (let i = 0; i < paid.length; i++) {
            if (paid[i].id == id) {
                item = paid[i]
                break;
            }
        }
        let new_fav = fav;
        new_fav.push(item)
        setFav([...new_fav])
        localStorage.setItem('favourite', JSON.stringify({ arr: new_fav }))
    }

    function isfavourite(id) {
        for (let i = 0; i < fav.length; i++) {
            if (fav[i].id == id) return true;
        }
        return false
    }

    useEffect(() => {
        if (fav != null && paid != null) {
            console.log('Changes in fav ', fav)
            let new_temp = paid;
            for (let i = 0; i < new_temp.length; i++) {
                if (isfavourite(new_temp[i].id)) {
                    new_temp[i].favourite = true
                }
                else {
                    new_temp[i].favourite = false
                }
            }
            console.log('New all ', new_temp)
            setAll([...new_temp])
        }
    }, [fav, paid])

    useEffect(() => {
        if (fav == null) {
            getting_fav()
        }
    })

    const [loading, setLoading] = useState(false)

    async function getting_long_lat() {
        console.log(post)
        let fetchRes = fetch(`https://api.mapbox.com/geocoding/v5/mapbox.places/${String(post).toUpperCase().trim()}.json?access_token=pk.eyJ1IjoiYm9vbXNvZnR3YXJlIiwiYSI6ImNsZzg3NWpudjB1b2szZnA4aW5heTBvY2sifQ.uiP6gq8iyvMNPH9JaCR9MQ`);
        let res = await fetchRes;
        res = await res.json()
        console.log(res.features[0])
        if (res.features != undefined && res.features[0].center != undefined) paid_advertisements_search(res.features[0].center[1], res.features[0].center[0])
        else {
            toast('No advertisements found !')
            setPaid([])
        }
    }

    useEffect(() => {
        if (post != '' && search != '' && paid == null) {
            getting_long_lat()
        }
    }, [post, search, isopen])

    const isMobile = width <= 768;

    function Distance(lat1, lon1, lat2, lon2) {
        var p = 0.017453292519943295;    // Math.PI / 180
        var c = Math.cos;
        var a = 0.5 - c((lat2 - lat1) * p) / 2 +
            c(lat1 * p) * c(lat2 * p) *
            (1 - c((lon2 - lon1) * p)) / 2;

        return 12742 * Math.asin(Math.sqrt(a)); // 2 * R; R = 6371 km
    }

    function exist(arr, id) {
        for (let i = 0; i < arr.length; i++) {
            if (arr[i].id == id) return true;
        }
        return false
    }

    function exist_free(arr, id) {
        for (let i = 0; i < arr.length; i++) {
            if (arr[i].id == id) return true;
        }
        return false
    }

    async function paid_advertisements_search(lat, long) {

        let temp = [];
        setLoading(true)

        const ends = String(search).toLowerCase().trim() + `\uf8ff`

        // sponsored
        const snapshot = await accepted_db.orderByChild('keyword').startAt(String(search).toLowerCase().trim()).endAt(ends).once('value')
        if (snapshot.val() != null) {
            let arr = Object.values(snapshot.val());
            console.log("Arr ", arr)
            arr.sort(compare)
            let temp_obj;
            for (let i = 0; i < arr.length; i++) {
                if (Math.floor(Distance(lat, long, arr[i].lat, arr[i].long)) <= arr[i].distance && isopen == false) {
                    temp_obj = arr[i]
                    temp_obj.far_from = Math.floor(Distance(lat, long, arr[i].lat, arr[i].long))
                    temp_obj.favourite = false
                    temp.push(temp_obj)
                }
                else if (Math.floor(Distance(lat, long, arr[i].lat, arr[i].long)) <= arr[i].distance && isopen == true) {

                    let curr = new Date();
                    curr.setHours(0);
                    curr.setMinutes(0);
                    curr.setMilliseconds(0);

                    let currs = new Date();
                    currs.setHours(0);
                    currs.setMinutes(0);
                    currs.setMilliseconds(0);

                    currs.setHours(moment(arr[i].start, 'hh:mm a').hours())
                    currs.setMinutes(moment(arr[i].start, 'hh:mm a').minutes())

                    let start_stamp = currs.getTime();

                    curr.setHours(moment(arr[i].end, 'hh:mm a').hours())
                    curr.setMinutes(moment(arr[i].end, 'hh:mm a').minutes())

                    let end_stamp = curr.getTime();
                    temp_obj = arr[i]
                    temp_obj.far_from = Math.floor(Distance(lat, long, arr[i].lat, arr[i].long))
                    temp_obj.favourite = false

                    if (start_stamp <= new Date().getTime() && new Date().getTime() <= end_stamp) temp.push(temp_obj)

                }
            }
            // toast(`${temp.length} results have been found`)
        }

        if (temp.length > 3) {
            temp = [temp[0], temp[1], temp[2]]
        }

        let new_free = []

        const free_snapshot0 = await accepted_db.orderByChild('search_business_name').startAt(String(search).toLowerCase().trim()).endAt(ends).once('value')
        if (free_snapshot0.val() != null) {
            let new_temp = Object.values(free_snapshot0.val())
            new_free = new_free.concat(new_temp)
        }

        const free_snapshot2 = await accepted_db.orderByChild('search2').startAt(String(search).toLowerCase().trim()).endAt(ends).once('value')
        if (free_snapshot2.val() != null) {
            let new_temp = Object.values(free_snapshot2.val())
            console.log("Free 2 ", new_temp)
            if (new_temp.length > 0) new_free = new_free.concat(new_temp)
        }

        const free_snapshot3 = await accepted_db.orderByChild('search3').startAt(String(search).toLowerCase().trim()).endAt(ends).once('value')
        if (free_snapshot3.val() != null) {
            let new_temp = Object.values(free_snapshot3.val())
            console.log("Free 3 ", new_temp)
            if (new_temp.length > 0) new_free = new_free.concat(new_temp)
        }

        const free_snapshot4 = await accepted_db.orderByChild('search4').startAt(String(search).toLowerCase().trim()).endAt(ends).once('value')
        if (free_snapshot4.val() != null) {
            let new_temp = Object.values(free_snapshot4.val())
            if (new_temp.length > 0) new_free = new_free.concat(new_temp)
        }


        const free_snapshot5 = await accepted_db.orderByChild('tag1').startAt(String(search).toLowerCase().trim()).endAt(ends).once('value')
        if (free_snapshot5.val() != null) {
            let new_temp = Object.values(free_snapshot5.val())
            console.log("Free 2 ", new_temp)
            if (new_temp.length > 0) new_free = new_free.concat(new_temp)
        }

        const free_snapshot6 = await accepted_db.orderByChild('tag2').startAt(String(search).toLowerCase().trim()).endAt(ends).once('value')
        if (free_snapshot6.val() != null) {
            let new_temp = Object.values(free_snapshot6.val())
            console.log("Free 3 ", new_temp)
            if (new_temp.length > 0) new_free = new_free.concat(new_temp)
        }

        const free_snapshot7 = await accepted_db.orderByChild('tag3').startAt(String(search).toLowerCase().trim()).endAt(ends).once('value')
        if (free_snapshot7.val() != null) {
            let new_temp = Object.values(free_snapshot7.val())
            if (new_temp.length > 0) new_free = new_free.concat(new_temp)
        }

        const free_snapshot8 = await accepted_db.orderByChild('key').startAt(String(search).toLowerCase().trim()).endAt(ends).once('value')
        if (free_snapshot8.val() != null) {
            let new_temp = Object.values(free_snapshot8.val())
            if (new_temp.length > 0) new_free = new_free.concat(new_temp)
        }


        console.log('All free ', new_free);


        let all_free = [];
        for (let i = 0; i < new_free.length; i++) {
            if (!exist_free(all_free, new_free[i].id, i) && (new_free[i].free != undefined || new_free[i].wordpress == true)) {
                all_free.push(new_free[i])
            }
        }


        for (let i = 0; i < all_free.length; i++) {

            if (all_free[i].wordpress != true) all_free[i].far_from = Distance(lat, long, all_free[i].lat, all_free[i].long)

            else if (all_free[i]['geolocation_lat'] != '') {
                all_free[i]['far_from'] = Distance(lat, long, all_free[i]['geolocation_lat'], all_free[i]['geolocation_long'])
            }

        }

        all_free.sort(function (a, b) { return a.far_from - b.far_from });

        // at last
        temp = temp.concat(all_free)

        if (temp.length == 0) toast('No result found !')
        else toast(`${temp.length} results have been found`)
        setPaid([...temp])
        setLoading(false)
    }

    function compare(a, b) {
        if (a.distance_reveneu < b.distance_reveneu) {
            return 1;
        }
        else if (a.distance_reveneu == b.distance_reveneu) {
            if (a.place > b.place) {
                return -1;
            }
            else if (a.place == b.place && a.date > b.date) {
                return 1;
            }
            else if (a.place == b.place && a.date < b.date) {
                return -1;
            }
            else return 0;
        }
        else if (a.distance_reveneu > b.distance_reveneu) {
            return -1;
        }
        return 0;
    }

    function handleWindowSizeChange() {
        setWidth(window.innerWidth);
        setHeight(window.innerHeight);
    }

    useEffect(() => {
        window.addEventListener('resize', handleWindowSizeChange);
        return () => {
            window.removeEventListener('resize', handleWindowSizeChange);
        }
    }, []);

    function exist_rating(item) {
        let all = (item.rating1 == undefined ? 0 : item.rating1) + (item.rating2 == undefined ? 0 : item.rating2) + (item.rating3 == undefined ? 0 : item.rating3) + (item.rating4 == undefined ? 0 : item.rating4) + (item.rating5 == undefined ? 0 : item.rating5)
        if (all > 0) return true;
        else return false
    }

    function this_rating(item) {
        let five = item.rating5 == undefined ? 0 : item.rating5;
        let four = item.rating4 == undefined ? 0 : item.rating4;
        let three = item.rating3 == undefined ? 0 : item.rating3;
        let two = item.rating2 == undefined ? 0 : item.rating2;
        let one = item.rating1 == undefined ? 0 : item.rating1;

        let all = (item.rating1 == undefined ? 0 : item.rating1) + (item.rating2 == undefined ? 0 : item.rating2) + (item.rating3 == undefined ? 0 : item.rating3) + (item.rating4 == undefined ? 0 : item.rating4) + (item.rating5 == undefined ? 0 : item.rating5)

        let avg = 0;
        if (all != 0) avg = ((five * 5 + four * 4 + three * 3 + two * 2 + one * 1) / (all)).toFixed(1)

        return avg;

    }

    function my_description(str) {
        let ret = String(str);
        ret = ret.replaceAll('<span>', '')
        ret = ret.replaceAll('<span', '')
        ret = ret.replaceAll('<img', '')
        ret = ret.replaceAll('class=', '')
        ret = ret.replaceAll('gt3_dl', '')
        ret = ret.replaceAll('</span>', '')
        ret = ret.replaceAll('</div>', '')
        ret = ret.replaceAll('<div>', '')
        ret = ret.replaceAll('<div', '')
        ret = ret.replaceAll('</dt>', '')
        ret = ret.replaceAll('<dt>', '')
        ret = ret.replaceAll('<dt', '')
        ret = ret.replaceAll('<dl>', '')
        ret = ret.replaceAll('<dl', '')
        ret = ret.replaceAll('</dl>', '')
        ret = ret.replaceAll('<dd>', '')
        ret = ret.replaceAll('<dd', '')
        ret = ret.replaceAll('</dd>', '')
        ret = ret.replaceAll('header', '')
        ret = ret.replaceAll('&amp', '')
        ret = ret.replaceAll('""', '')

        ret = ret.replaceAll('>', '')
        ret = ret.replaceAll('<', '')

        ret = ret.replaceAll('_aac', '')
        return ret;
    }

    function format(str) {

        if (str == undefined || str == null) return null
        else if (str == '24h') return str;
        let start = String(str).substring(0, String(str).indexOf('m') + 2)
        let end = String(str).substring(String(str).indexOf('m') + 2, String(str).length)

        return start + '-' + end
    }

    function days_select(ind) {
        if (ind == 0) return 'Mon'
        else if (ind == 1) return 'Tues'
        else if (ind == 2) return 'Wed'
        else if (ind == 3) return 'Thu'
        else if (ind == 4) return 'Fri'
        else if (ind == 5) return 'Sat'
        else if (ind == 6) return 'Sun'
    }

    const renderStandings = (item) => {

        if (item.Title == undefined) return (<div style={{ padding: 6, borderStyle: 'solid', borderWidth: 0.3, borderColor: '#28b8dc', cursor: 'pointer', display: 'flex', width: isMobile ? '96%' : '98%', flexDirection: 'column', backgroundColor: '#f8fbfe', justifyContent: 'center', marginBottom: 8, alignItems: 'center', maxHeight: isMobile ? 1020 : 285, alignSelf: 'center', borderRadius: 6 }}>
            <div onClick={() => {
                if (item.favourite == false) {
                    setting_favourite(item.id, item.email)
                }
                else {
                    remove_favourite(item.id)
                }
            }} style={{ cursor: 'pointer', width: '98%', display: 'flex', justifyContent: 'space-between', flexDirection: 'row', alignItems: 'center', marginBottom: 4 }}>
                <text style={{ color: '#0066ff', fontWeight: '400', fontSize: 12 }}>{item.free != undefined ? '' : 'Sponsored'}</text>
                {item.favourite == true ? favourite_icon : non_favourite_icon}
            </div>
            <div onClick={() => setCurr(item)} style={{ cursor: 'pointer', width: '98%', alignSelf: 'center', alignItems: 'center', display: 'flex', flexDirection: 'row', maxHeight: isMobile ? 1090 : 230 }}>
                <div style={{ display: 'flex', maxHeight: isMobile ? 1090 : 228, width: isMobile ? '38%' : 140, justifyContent: 'center' }}>
                    <Img
                        style={{ width: 140, height: 120, borderRadius: 2, objectFit: 'contain' }}
                        src={item.uri}
                        loader={<img src={error} alt="img" style={{ width: 140, height: 119, borderRadius: 2, objectFit: 'fill' }} />}
                    />
                </div>
                <div style={{ marginTop: isMobile ? 19 : 0, display: 'flex', flexDirection: 'column', maxHeight: isMobile ? 1090 : 220, width: isMobile ? '57%' : width - 220, marginLeft: 10, alignItems: 'flex-start', marginBottom: 6 }}>
                    <text style={{ color: '#28b8dc', fontSize: 16, fontWeight: '700', marginLeft: 6 }}>{item.business_name}</text>
                    <div style={{ overflow: 'hidden', wordBreak: 'break-all', display: 'flex', alignItems: 'center', flexDirection: 'row', marginTop: 4 }}>
                        <text style={{ color: 'black', fontWeight: '400', fontSize: 12, marginLeft: 10, textAlign: 'left' }}>{item.website}</text>
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'row', marginTop: 4, alignItems: 'center' }}>
                        {exist_rating(item) && <div style={{ display: 'flex', alignItems: 'center', flexDirection: 'row', marginLeft: 6, backgroundColor: '#e7eff5', padding: 2, borderRadius: 4 }}>
                            <Rating
                                emptySymbol={empty}
                                fullSymbol={fill}
                                fractions={1}
                                initialRating={this_rating(item)}
                                readonly={true}
                                stop={5}
                            />
                            <text style={{ fontSize: 11, fontWeight: '400', marginLeft: 4 }}>{this_rating(item)}</text>
                        </div>}
                        <div style={{ display: 'flex', alignItems: 'center', flexDirection: 'row', marginLeft: 10, backgroundColor: '#e7eff5', padding: 4, borderRadius: 4 }}>
                            {map_icon}
                            <text style={{ fontSize: 11, letterSpacing: 1.01 }}>{item.far_from.toFixed(1)} KM</text>
                        </div>
                    </div>
                    <div style={{ display: 'flex', alignItems: 'center', flexDirection: 'row', marginTop: 4, marginLeft: 6 }}>
                        {address_icon}
                        <text style={{ color: 'black', fontWeight: '400', fontSize: 12, marginLeft: 10 }}>{item.address1} , {item.country}</text>
                    </div>
                    <div style={{ display: 'flex', alignItems: 'center', flexDirection: 'row', marginTop: 4, justifyContent: 'left', flexWrap: 'wrap' }}>

                        <div style={{ display: 'flex', alignItems: 'center', flexDirection: 'row', marginLeft: 10, marginTop: 8, backgroundColor: '#e7eff5', padding: 4, borderRadius: 4 }}>
                            <text style={{ fontSize: 11, letterSpacing: 1.01 }}>{(item.monday == undefined || item.monday == 'Closed') ? 'Mon 🔒 Closed' : `Mon 🕛 ${item.monday.start} - ${item.monday.end}`}</text>
                        </div>
                        <div style={{ display: 'flex', alignItems: 'center', flexDirection: 'row', marginLeft: 10, marginTop: 8, backgroundColor: '#e7eff5', padding: 4, borderRadius: 4 }}>
                            <text style={{ fontSize: 11, letterSpacing: 1.01 }}>{(item.tuesday == undefined || item.tuesday == 'Closed') ? 'Tue 🔒 Closed' : `Tue 🕛 ${item.tuesday.start} - ${item.tuesday.end}`}</text>
                        </div>
                        <div style={{ display: 'flex', alignItems: 'center', flexDirection: 'row', marginLeft: 10, marginTop: 8, backgroundColor: '#e7eff5', padding: 4, borderRadius: 4 }}>
                            <text style={{ fontSize: 11, letterSpacing: 1.01 }}>{(item.wednesday == undefined || item.wednesday == 'Closed') ? 'Wed 🔒 Closed' : `Wed 🕛 ${item.wednesday.start} - ${item.wednesday.end}`}</text>
                        </div>
                        <div style={{ display: 'flex', alignItems: 'center', flexDirection: 'row', marginLeft: 10, marginTop: 8, backgroundColor: '#e7eff5', padding: 4, borderRadius: 4 }}>
                            <text style={{ fontSize: 11, letterSpacing: 1.01 }}>{(item.thursday == undefined || item.thursday == 'Closed') ? 'Thu 🔒 Closed' : `Thu 🕛 ${item.thursday.start} - ${item.thursday.end}`}</text>
                        </div>
                        <div style={{ display: 'flex', alignItems: 'center', flexDirection: 'row', marginLeft: 10, marginTop: 8, backgroundColor: '#e7eff5', padding: 4, borderRadius: 4 }}>
                            <text style={{ fontSize: 11, letterSpacing: 1.01 }}>{(item.friday == undefined || item.friday == 'Closed') ? 'Fri 🔒 Closed' : `Fri 🕛 ${item.friday.start} - ${item.friday.end}`} </text>
                        </div>
                        <div style={{ display: 'flex', alignItems: 'center', flexDirection: 'row', marginLeft: 10, marginTop: 8, backgroundColor: '#e7eff5', padding: 4, borderRadius: 4 }}>
                            <text style={{ fontSize: 11, letterSpacing: 1.01 }}>{(item.saturday == undefined || item.saturday == 'Closed') ? 'Sat 🔒 Closed' : `Sat 🕛 ${item.saturday.start} - ${item.saturday.end}`}</text>
                        </div>
                        <div style={{ display: 'flex', alignItems: 'center', flexDirection: 'row', marginLeft: 10, marginTop: 8, backgroundColor: '#e7eff5', padding: 4, borderRadius: 4 }}>
                            <text style={{ fontSize: 11, letterSpacing: 1.01 }}>{(item.sunday == undefined || item.sunday == 'Closed') ? ' Sun 🔒 Closed' : `Sun 🕛 ${item.sunday.start} - ${item.sunday.end}`}</text>
                        </div>
                    </div>

                    <div style={{ overflow: 'hidden', wordBreak: 'break-all', marginLeft: 10, display: 'flex', alignItems: 'center', textAlign: 'left', marginTop: 4, justifyContent: 'left', maxHeight: 240, fontSize: 12, width: isMobile ? 0.50 * width : '85%' }}>
                        {item.description == undefined ? '' : item.description}
                    </div>
                </div>
            </div>
        </div >
        );

        else return (<div style={{ padding: 6, borderStyle: 'solid', borderWidth: 0.3, borderColor: '#28b8dc', cursor: 'pointer', display: 'flex', width: isMobile ? 0.96 * width : '98%', flexDirection: 'column', backgroundColor: '#f8fbfe', justifyContent: 'center', marginBottom: 8, alignItems: 'center', maxHeight: isMobile ? 4520 : 795, alignSelf: 'center', borderRadius: 6 }}>
            <div onClick={() => {
                if (item.favourite == false) {
                    setting_favourite(item.id);
                    sendMailFavourite(item.email)
                }
                else {
                    remove_favourite(item.id)
                }
            }} style={{ cursor: 'pointer', width: '98%', display: 'flex', justifyContent: 'space-between', flexDirection: 'row', alignItems: 'center', marginBottom: 4 }}>

                {item.favourite == true ? favourite_icon : non_favourite_icon}
            </div>
            <div onClick={() => setCurr(item)} style={{ cursor: 'pointer', width: '98%', alignSelf: 'center', alignItems: 'center', display: 'flex', flexDirection: 'row', maxHeight: isMobile ? 4560 : 770 }}>
                <div style={{ display: 'flex', maxHeight: isMobile ? 4550 : 760, width: 130, justifyContent: 'center' }}>
                    <Img
                        style={{ width: 130, height: 145, borderRadius: 2, objectFit: 'contain' }}
                        src={item['Image URL']}
                        loader={<img src={error} alt="img" style={{ width: 130, height: 145, borderRadius: 2, objectFit: 'cover' }} />}
                        unloader={<img src={error} alt="img" style={{ width: 130, height: 145, borderRadius: 2, objectFit: 'contain' }} />}
                    />
                </div>
                <div style={{ display: 'flex', flexDirection: 'column', maxHeight: isMobile ? 4560 : 770, justifyContent: 'space-around', marginLeft: 10, alignItems: 'flex-start', marginBottom: 6 }}>
                    <text style={{ color: '#28b8dc', fontSize: 16, fontWeight: '700', marginLeft: 8, width: 0.55 * width, textAlign: 'left' }}>{item.Title}</text>

                    <text style={{ overflow: 'hidden', wordBreak: 'break-all', color: 'black', fontSize: 12, fontWeight: '300', marginLeft: 8, width: 0.55 * width, textAlign: 'left' }}>{item._company_website}</text>

                    <div style={{ display: 'flex', flexDirection: 'row', marginTop: 4, alignItems: 'center', marginLeft: 6, width: '80%' }}>
                        {exist_rating(item) && <div style={{ display: 'flex', alignItems: 'center', flexDirection: 'row', backgroundColor: '#e7eff5', padding: 2, borderRadius: 4 }}>
                            <Rating
                                emptySymbol={empty}
                                fullSymbol={fill}
                                fractions={1}
                                initialRating={this_rating(item)}
                                readonly={true}
                                stop={5}
                            />
                            <text style={{ fontSize: 11, fontWeight: '400', marginLeft: 4 }}>{this_rating(item)}</text>
                        </div>}
                        {item.far_from != undefined && <div style={{ display: 'flex', alignItems: 'center', flexDirection: 'row', marginLeft: 10, backgroundColor: '#e7eff5', padding: 4, borderRadius: 4 }}>
                            {map_icon}
                            <text style={{ fontSize: 11, letterSpacing: 1.01 }}>{item.far_from.toFixed(1)} KM</text>
                        </div>}
                    </div>

                    <div style={{ display: 'flex', alignItems: 'center', flexDirection: 'row', marginTop: 4, justifyContent: 'left', marginLeft: 6, flexWrap: 'wrap', width: '70%' }}>
                        {item['_job_hours'] != undefined && item['_job_hours'] != '' && item['_job_hours'].length > 0 && item['_job_hours'].map((id, indx) => <div style={{ backgroundColor: '#e7eff5', display: 'flex', flexDirection: 'row', marginTop: 4, marginLeft: 4, padding: 2, borderRadius: 4, alignItems: 'center' }}>
                            {id.days != undefined ? <text style={{ textAlign: 'left', color: 'black', fontSize: 11, }}>{`${id.days[0]}` + `${id.days[1]}` + `${id.days[2]}`} 🕛 </text> : <text style={{ textAlign: 'left', color: 'black', fontSize: 11, }}>{days_select(indx)} 🕛 </text>}
                            <text style={{ color: 'gray', fontSize: 11, fontWeight: '400' }}>{format(id.hours)}</text>
                        </div>)}
                    </div>

                    <div style={{ overflow: 'hidden', wordBreak: 'break-all', marginLeft: 10, display: 'flex', alignItems: 'flex-start', flexDirection: 'column', marginTop: 4, justifyContent: 'left', width: isMobile ? 0.50 * width : '85%' }}>
                        <text style={{ color: 'black', fontWeight: '500', fontSize: 12, textAlign: 'left' }}>{my_description(item.Content)}</text>
                    </div>
                </div>
            </div>
        </div>
        );
    }

    //const error = "https://ci3.googleusercontent.com/mail-img-att/AIFldNqrnyahJE2M4OTnMEZvQ0EGUtN00rcRok57vPOtuEsabVnY0eOjmSnidjcv8E4jaz6vUyAHHlaNOPDH7DADCHCimAl-Fj4FsWQ9V3eQNNGFG5-O1fYq_jTQozwuZHOUWBrzvQzOC7z2jQtKJHDe6SNnz5TZpa5L17pBlPHG-3lzOo8M4YR2SROi9TukZ-Lm2xxwVxd1a3O1D4qmlu_KwdfdIobGMrqx7gRxDqdeafgqJ2KaPTGFq4Jz7SD0eqmya1PD71JtjhnIffTPTcXc9rniIUo3pwdU1THkGZdJhZ8zUhp15MCI6S-SjzCEjWBhP-JSvYuWq3KlqrPcpSuFn6VxzEHEndUipB2vRUkJ8pD7LDsNphnZAaje7z-KRkTSS0Ml3pK6d6cd9dni0lapH_JPBJLOx4yxgbLU_3MgUy2bhLMeRhfm75xOvEOWN3iCdG56F30exFeE7zdNVz9Fvzstk9QwcXN4VClZBOvS4tN_xYWVEtm9lrGEQmNFC6ah4zgrVNVh4WcwleAD1F5oGHwNiZKGrRgQtvwFccG8-Fp-M7FEWb28SicU8xXs1RQfDnSnDXmgDobMFJwAQW4NREjBLFQtfUp4PGlNdo9jmLMUd6CvW16RBbxQFPrZU6fNBFOKpjY4MNGCFLbtk5umUosK3fALBx1VI_BjjLSxZvxzFfWGATz1arqlSQV_ctY4EClKGlS4kjik1ddeBuohW7p2o4EvJbJ2C4uWRP8h_w-A2L6FZNR-SioDJrV-p4Km8pO7mpyPY3SZgEJp5OF3sr-50JeHyf5Z3XjBBDV77SDK5ShoCXiIfda4E-u5fiFOtp4FLaeSunvHxwdDKp5dp7AO0jFz25k0rOLbG9w7wT0dMY8q35YekNf1alqgp2RNvUFIfydK5-SED6LYbl8BhiZ-2LZ8kPeZka-LpBRLjGoq8DdAWWjUnDT2UfPDqUwWlsI1CWHd_r_o4a7eVamShOouHEcwyPvSqsg5O8rZDy1CpDWAcpDQUMkrGBKBLaIMLAWtVJCIZgiv244u2QbfnQ=s0-l75-ft"



    return (
        <div style={{ display: 'flex', flexDirection: 'column', width: '100%', overflow: 'hidden', padding: 2 }}>

            <wc-toast></wc-toast>

            <div style={{ alignItems: 'center', width: '90%', display: 'flex', flexDirection: 'row', alignSelf: 'center', height: 80, justifyContent: 'space-between' }}>
                <div onClick={() => navigate('/')} style={{ cursor: 'pointer', width: '30%', display: 'flex', justifyContent: 'flex-start' }}>
                    <img src={require('../nwlb.jpg')} style={{ height: 60, width: 60, borderRadius: 30, objectFit: 'contain' }} />
                </div>
                <div style={{ width: '50%', display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-evenly' }}>
                    {isMobile && <ReactDropdown
                        arrowClosed={<text></text>}
                        arrowOpen={<text></text>}
                        options={['Favourite', 'Learn', 'Join', 'Contact us', 'Profile', 'Add Listing']}
                        onChange={(e) => {
                            let command = e.value;
                            if (command.includes('Favourite')) {
                                navigate('/Favourite')
                            }
                            else if (command.includes("Contact")) {
                                navigate('/Contact')
                            }
                            else if (command.includes("Profile")) {
                                navigate('/Login')
                            }
                            else if (command.includes("Add")) {
                                navigate('/AddListing')
                            }
                        }}
                        placeholder="     Menu     "
                    />}
                    {!isMobile && <text onClick={() => navigate('/Favourite')} style={{ cursor: 'pointer', fontSize: 16, color: 'black', fontWeight: '500' }}>Favourite</text>}
                    {!isMobile && <text style={{ fontSize: 16, color: 'black', fontWeight: '500' }}>Learn</text>}
                    {!isMobile && <text onClick={() => navigate('/Join')} style={{ fontSize: 16, color: 'black', fontWeight: '500' }}>Join</text>}
                    {!isMobile && <text onClick={() => navigate('/Contact')} style={{ cursor: 'pointer', fontSize: 16, color: 'black', fontWeight: '500' }}>Contact us</text>}
                    {!isMobile && <div onClick={() => navigate('/Login')} style={{ cursor: 'pointer', display: 'flex', alignContent: 'center', alignItems: 'center', backgroundColor: '#28b8dc', height: 35, width: 35, borderRadius: 4, justifyContent: 'center' }}>
                        {user_icon}
                    </div>}
                    {!isMobile && <div onClick={() => navigate('/AddListing')} style={{ cursor: 'pointer', display: 'flex', alignContent: 'center', flexDirection: 'row', alignItems: 'center', backgroundColor: '#28b8dc', height: 35, width: 130, borderRadius: 4, justifyContent: 'center' }}>
                        {plus_icon}
                        <text style={{ marginLeft: 4, color: 'white', fontSize: 12 }}>Add Listing</text>
                    </div>}
                </div>
            </div>

            {!curr && !isMobile && <div style={{ width: isMobile ? '95%' : '90%', display: 'flex', marginTop: 40, alignSelf: 'center', alignItems: 'flex-start' }}>
                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', alignSelf: 'center', width: 600, justifyContent: 'space-between', marginTop: 14, marginBottom: 10 }}>
                    <input value={search} onChange={(e) => setSearch(e.currentTarget.value)} type='text' placeholder='    Search NWLB' style={{ height: 40, width: isMobile ? '40%' : 270, borderRadius: 10, outline: 'none', borderStyle: 'solid', borderWidth: 0.5, padding: 4, borderColor: 'lightgray' }} />
                    <input value={post} onChange={(e) => setPost(e.currentTarget.value)} type='text' placeholder='  Postcode' style={{ height: 40, width: isMobile ? '40%' : 120, borderRadius: 10, outline: 'none', borderStyle: 'solid', borderWidth: 0.5, padding: 4, borderColor: 'lightgray' }} />
                    <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                        <text style={{ marginRight: 4, color: '#28b8dc' }}> Open ? </text>
                        <input checked={isopen} type="checkbox" onChange={(e) => setIsOpen(!isopen)} />
                    </div>
                    <div onClick={() => getting_long_lat()} style={{ cursor: 'pointer', display: 'flex', alignContent: 'center', alignItems: 'center', backgroundColor: '#28b8dc', height: 35, width: 35, borderRadius: 4, justifyContent: 'center' }}>
                        {search_icon}
                    </div>
                </div>

            </div>}

            {!curr && isMobile && <div style={{ width: isMobile ? '96%' : '90%', display: 'flex', marginTop: 40, alignSelf: 'center', alignItems: 'flex-start' }}>
                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', alignSelf: 'center', width: 500, justifyContent: 'space-between', marginTop: 14, marginBottom: 10 }}>
                    <input value={search} onChange={(e) => setSearch(e.currentTarget.value)} type='text' placeholder='    Search NWLB' style={{ height: 40, width: isMobile ? '30%' : 270, borderRadius: 10, outline: 'none', borderStyle: 'solid', borderWidth: 0.5, padding: 4, borderColor: 'lightgray' }} />
                    <input value={post} onChange={(e) => setPost(e.currentTarget.value)} type='text' placeholder='  Postcode' style={{ height: 40, width: isMobile ? '30%' : 120, borderRadius: 10, outline: 'none', borderStyle: 'solid', borderWidth: 0.5, padding: 4, borderColor: 'lightgray' }} />
                    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                        <text style={{ color: '#28b8dc' }}> Open ? </text>
                        <input checked={isopen} type="checkbox" onChange={(e) => setIsOpen(!isopen)} />
                    </div>
                    <div onClick={() => getting_long_lat()} style={{ cursor: 'pointer', display: 'flex', alignContent: 'center', alignItems: 'center', backgroundColor: '#28b8dc', height: 35, width: 35, borderRadius: 4, justifyContent: 'center' }}>
                        {search_icon}
                    </div>
                </div>

            </div>}

            <text style={{ color: 'black', textAlign: 'center', fontSize: 13, marginTop: 7, fontStyle: 'inherit', fontWeight: '600' }}>
                WIN MONTHLY CASH PRIZES COMING SOON....
            </text>

            {!curr && <div style={{ width: '90%', height: 0.5, backgroundColor: '#28b8dc', alignSelf: 'center', marginTop: 20, marginBottom: 30 }} />}

            {!curr && <div style={{ display: 'flex', overflow: "auto", overflowX: "none", height: 580, marginTop: 1, flexDirection: 'column', width: isMobile ? 0.99 * width : '88%', alignSelf: 'center' }}>
                {all.length > 0 && <FlatList
                    list={all}
                    renderItem={(id) => renderStandings(id)}
                    renderWhenEmpty={() => <div style={{ height: 1, backgroundColor: '#EEEEEE' }} />}
                />}
            </div>}

            {curr != null && (curr.Title == undefined ? <SingleTest remove_fav={remove_favourite} make_fav={setting_favourite} setBack={setCurr} item={curr} /> : <SingleTestW remove_fav={remove_favourite} make_fav={setting_favourite} setBack={setCurr} item={curr} />)}


            <div style={{ display: 'flex', width: '100%', backgroundColor: '#28b8dc', height: 200, alignItems: 'center', justifyContent: 'center' }}>
                <div style={{ width: '90%', alignSelf: 'center', display: 'flex', flexDirection: 'row', height: 180, alignItems: 'center', justifyContent: 'space-between' }}>
                    <div style={{ flexDirection: 'column', display: 'flex', alignItems: 'flex-start', width: '40%', justifyContent: 'center' }}>
                        <img src={require('../nwlb.jpg')} style={{ height: 60, width: 60, borderRadius: 30, objectFit: 'contain', marginBottom: 6 }} />
                        <text style={{ color: 'white', fontSize: 12, fontWeight: '300', textAlign: 'left' }}>
                            Download our app North West Local Business - Easily find the services and products you need while enjoying
                            exclusive features not available on our website.
                        </text>
                    </div>
                    <div style={{ flexDirection: 'column', display: 'flex', alignItems: 'flex-start', width: '14%' }}>
                        <text style={{ fontSize: 14, color: 'white', fontWeight: '800' }}>OUR LOCATION</text>
                        <text style={{ color: 'white', fontSize: 12, fontWeight: '300', marginTop: 6 }}>
                            Northwest, UK
                        </text>
                    </div>
                    <div style={{ flexDirection: 'column', display: 'flex', alignItems: 'flex-start', width: '14%' }}>
                        <text style={{ fontSize: 14, color: 'white', fontWeight: '800' }}>CONTACT US</text>
                        <text style={{ color: 'white', fontSize: 12, fontWeight: '300', marginTop: 6 }}>
                            Tel: 0800 9750341
                        </text>
                    </div>
                </div>
            </div>

            <div style={{ backgroundColor: '#089ec4', width: '100%', display: 'flex', alignItems: 'center', height: 60, alignSelf: 'center', justifyContent: 'center' }}>
                <div style={{ width: '90%', alignSelf: 'center', height: 50, display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between' }}>

                    <div style={{ width: '40%', display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between' }}>
                        <text onClick={() => navigate('/Condition')} style={{ cursor: 'pointer', color: 'white', fontWeight: '400', fontSize: 13, textDecorationLine: 'underline', textDecorationColor: 'white' }}>Terms & Conditions</text>
                        <text style={{ color: 'white', fontWeight: '400', fontSize: 13, textDecorationLine: 'underline', textDecorationColor: 'white' }}>Cookie Policy</text>
                        <text onClick={() => navigate('/Term')} style={{ cursor: 'pointer', color: 'white', fontWeight: '400', fontSize: 13, textDecorationLine: 'underline', textDecorationColor: 'white' }}>Privacy Policy</text>
                    </div>

                    <div style={{ width: '25%', display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-around' }}>
                        <div style={{ display: 'flex', alignContent: 'center', alignItems: 'center', backgroundColor: '#28b8dc', height: 40, width: 40, borderRadius: 20, justifyContent: 'center' }}>
                            {instagram_icon}
                        </div>
                        <div style={{ display: 'flex', alignContent: 'center', alignItems: 'center', backgroundColor: '#28b8dc', height: 40, width: 40, borderRadius: 20, justifyContent: 'center' }}>
                            {twitter_icon}
                        </div>
                        <div style={{ display: 'flex', alignContent: 'center', alignItems: 'center', backgroundColor: '#28b8dc', height: 40, width: 40, borderRadius: 20, justifyContent: 'center' }}>
                            {facebook_icon}
                        </div>
                        <div style={{ display: 'flex', alignContent: 'center', alignItems: 'center', backgroundColor: '#28b8dc', height: 40, width: 40, borderRadius: 20, justifyContent: 'center' }}>
                            {instagram_icon}
                        </div>
                        <div style={{ display: 'flex', alignContent: 'center', alignItems: 'center', backgroundColor: '#28b8dc', height: 40, width: 40, borderRadius: 20, justifyContent: 'center' }}>
                            {instagram_icon}
                        </div>
                    </div>

                </div>
            </div>

            {loading && <div style={{ position: 'absolute', top: '35%', left: '48%', backgroundColor: 'white', padding: 10 }}><Levels size={35} /></div>}


        </div>
    );
}

export default SearchList;