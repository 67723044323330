import React, { useState, useEffect, useRef } from 'react';
import { address_big, face_big, facebook_icon, favourite_icon, instagram_icon, mail_big, plus_icon, search_icon, tiktok_big, tube_big, twitter_icon, user_icon, whatsapp_big } from './icons';
import FlatList from 'flatlist-react/lib';
import back from './back.png'
import customer from './customer1.jpg'
import upload from './upload.png'
import { useSelector, useDispatch } from 'react-redux';
import { MAKE_USER } from '../Redux/Reducer';
import { db } from '../config';
import { toast } from 'wc-toast';
import { Levels } from 'react-activity';
import "react-activity/dist/library.css";
import { useNavigate, useLocation } from 'react-router-dom';
import ReactDropdown from 'react-dropdown';
import 'react-dropdown/style.css';
//const newdb = db.ref('new')

const advertiser_db = db.ref('advertiser')

function id(mail) {
    let str = ''
    for (let i = 0; i < mail.length; i++) {
        if ((mail[i] >= 'a' && mail[i] <= 'z') || (mail[i] >= '0' && mail[i] <= '9')) str += mail[i]
    }
    return str;
}

function Delete() {

    const navigate = useNavigate();

    const { state } = useLocation();

    const [mail, setMail] = useState('')
    const [username, setUsername] = useState('')

    function makeUser(name) {
        localStorage.setItem('login', JSON.stringify({ mail: mail, name: name }))
    }

    useEffect(() => {
        let db = localStorage.getItem('login');
        console.log(JSON.parse(db))
        if (db != null) {
            db = JSON.parse(db);
            setMail(db.mail)
            setUsername(db.name)
            // setLogin(3)
        }
    })

    const [width, setWidth] = useState(window.innerWidth);
    const [height, setHeight] = useState(window.innerHeight);

    const [login, setLogin] = useState(2)
    const [loading, setLoading] = useState(false)
    const [pass, setPass] = useState('')
    const [otp, setOTP] = useState('')

    function create() {
        console.log('Creating ')
        if (mail != '' && pass != '') {
            setLoading(true)
            let ret = id(mail)
            advertiser_db.child(String(ret)).once('value').then((snapshot) => {
                if (snapshot.val() != null) {
                    toast('This email exists , try another one .')
                    setLoading(false)
                }
                else {
                    let obj = {
                        email: mail,
                        name: username,
                        pass: pass,
                    }
                    advertiser_db.child(String(ret)).set(obj).then(() => {
                        setLoading(false)
                        setLogin(3)
                        toast(`Your account has been created !`)
                    })
                }
            })
        }
    }

    function check() {
        console.log('Checking ', mail, pass)
        if (mail != '' && pass != '') {
            setLoading(true)
            let ret = id(mail)
            advertiser_db.child(String(ret)).once('value').then((snapshot) => {
                if (snapshot.val() != null) {
                    if (snapshot.val().pass == pass) {
                        toast('You are logged in !')
                        makeUser(snapshot.val().name)
                        setLoading(false)
                        setLogin(3)
                    }
                    else {
                        setLoading(false)
                        // toast('Your password is wrong !')
                    }
                }
                else {
                    console.log('Not found ');
                    setLoading(false)
                    toast(`This account isn't found !`)
                }
            })
        }
    }
    // https://us-central1-nwlb-fb8ad.cloudfunctions.net/app

    function isExist() {
        setLoading(true)
        console.log('Entering', mail)
        let ret = id(mail)
        advertiser_db.child(String(ret)).once('value').then((snapshot) => {
            if (snapshot.val() != null) {
                setUsername(snapshot.val().name)
                // let arr = Object.values(snapshot.val())
                // setUserid(arr[0].user_id)
                fetch(`https://us-central1-nwlb-fb8ad.cloudfunctions.net/app/mail_verification?email=${mail}`).then((val) => val.json().then((val) => {
                    console.log(val);
                    setOTP(val.otp);
                    toast('A verification code has been sent to your Email . Fill your verification code to enter');
                    setLoading(false)
                    setLogin(4)
                })).catch(e => console.log(e))
            }
            else {
                setLoading(false)
                toast('Sorry this email is not found !');
            }
        }).catch(e => {
            console.log(e)
        })
    }


    function verify() {
        if (otp == pass) {
            setLogin(3);
            toast('Your details has been deleted !')
        }
        else {
            toast('Verification code is wrong !')
        }
    }

    useEffect(() => {
        const keyDownHandler = event => {
            console.log('User pressed: ', event.key);

            if (event.key === 'Enter') {
                event.preventDefault();
                decide()
            }
        };

        document.addEventListener('keydown', keyDownHandler);

        return () => {
            document.removeEventListener('keydown', keyDownHandler);
        };
    }, [login, mail, username]);

    function decide() {
        if (login == true) check()
        else if (login == false) create()
        else if (login == 2) isExist()
        else if (login == 4) verify()
    }

    function handleWindowSizeChange() {
        setWidth(window.innerWidth);
        setHeight(window.innerHeight);
    }

    useEffect(() => {
        window.addEventListener('resize', handleWindowSizeChange);
        return () => {
            window.removeEventListener('resize', handleWindowSizeChange);
        }
    }, []);

    const isMobile = width <= 768;

    return (
        <div style={{ display: 'flex', flexDirection: 'column', width: '100%', overflow: 'hidden', padding: 2 }}>

            <wc-toast></wc-toast>

            <div style={{ alignItems: 'center', width: '90%', display: 'flex', flexDirection: 'row', alignSelf: 'center', height: 80, justifyContent: 'space-between' }}>
                <div onClick={() => navigate('/')} style={{ cursor: 'pointer', width: '30%', display: 'flex', justifyContent: 'flex-start' }}>
                    <img src={require('../nwlb.jpg')} style={{ height: 60, width: 60, borderRadius: 30, objectFit: 'contain' }} />
                </div>
                <div style={{ width: '50%', display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-evenly' }}>
                    {isMobile && <ReactDropdown
                        arrowClosed={<text></text>}
                        arrowOpen={<text></text>}
                        options={['Favourite', 'Learn', 'Join', 'Contact us', 'Add Listing']}
                        onChange={(e) => {
                            let command = e.value;
                            if (command.includes('Favourite')) {
                                navigate('/Favourite')
                            }
                            else if (command.includes("Contact")) {
                                navigate('/Contact')
                            }
                            else if (command.includes("Profile")) {
                                navigate('/Login')
                            }
                            else if (command.includes("Add")) {
                                navigate('/AddListing')
                            }
                        }}
                        placeholder="     Menu     "
                    />}
                    {!isMobile && <text onClick={() => navigate('/Favourite')} style={{ cursor: 'pointer', fontSize: 16, color: 'black', fontWeight: '500' }}>Favourite</text>}
                    {!isMobile && <text style={{ fontSize: 16, color: 'black', fontWeight: '500' }}>Learn</text>}
                    {!isMobile && <text onClick={() => navigate('/Join')} style={{ fontSize: 16, color: 'black', fontWeight: '500' }}>Join</text>}
                    {!isMobile && <text onClick={() => navigate('/Contact')} style={{ cursor: 'pointer', fontSize: 16, color: 'black', fontWeight: '500' }}>Contact us</text>}
                    {!isMobile && <div style={{ display: 'flex', alignContent: 'center', alignItems: 'center', backgroundColor: '#28b8dc', height: 35, width: 35, borderRadius: 4, justifyContent: 'center' }}>
                        {user_icon}
                    </div>}
                    {!isMobile && <div onClick={() => navigate('/AddListing')} style={{ cursor: 'pointer', display: 'flex', alignContent: 'center', flexDirection: 'row', alignItems: 'center', backgroundColor: '#28b8dc', height: 35, width: 130, borderRadius: 4, justifyContent: 'center' }}>
                        {plus_icon}
                        <text style={{ marginLeft: 4, color: 'white', fontSize: 12 }}>Add Listing</text>
                    </div>}
                </div>
            </div>

            <div style={{ height: 140, backgroundColor: '#d4f1f8', display: 'flex', justifyContent: 'center', marginTop: 20 }}>
                <text style={{ fontWeight: '600', fontSize: 45, alignSelf: 'center', color: '#089ec4' }}>{'Delete Account'}</text>
            </div>


            {login == true && <div style={{ marginTop: 15, display: 'flex', alignItems: 'center', width: isMobile ? '94%' : '80%', alignSelf: 'center', height: 290, flexDirection: 'column', marginBottom: 30, justifyContent: 'center' }}>

                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', width: isMobile ? '84%' : '40%' }}>
                    <text style={{ color: 'gray', fontWeight: '500', fontSize: 12, textAlign: 'left' }}>Email :</text>
                    <input type='text' onChange={(e) => setMail(e.currentTarget.value)} placeholder=' Your Email' style={{ backgroundColor: '#f8f8f8', marginTop: 4, height: 38, width: '96%', borderRadius: 10, outline: 'none', borderStyle: 'solid', borderWidth: 0.5, padding: 4, borderColor: 'lightgray' }} />
                </div>

                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', width: isMobile ? '84%' : '40%', marginTop: 16 }}>
                    <text style={{ color: 'gray', fontWeight: '500', fontSize: 12, textAlign: 'left' }}>Password : </text>
                    <input type='text' onChange={(e) => setPass(e.currentTarget.value)} placeholder=' Your Password' style={{ backgroundColor: '#f8f8f8', marginTop: 4, height: 38, width: '96%', borderRadius: 10, outline: 'none', borderStyle: 'solid', borderWidth: 0.5, padding: 4, borderColor: 'lightgray' }} />
                </div>

                <div onClick={() => decide()} style={{ alignItems: 'center', cursor: 'pointer', width: 100, height: 40, padding: 10, display: 'flex', justifyContent: 'center', backgroundColor: '#28b8dc', borderRadius: 4, alignSelf: 'center', marginTop: 10 }}>
                    <text style={{ color: 'white', fontSize: 12, fontWeight: '500' }}>Login</text>
                </div>

                <text onClick={() => setLogin(false)} style={{ cursor: 'pointer', marginTop: 10, textAlign: 'center', color: '#28b8dc', textDecorationColor: '#28b8dc', textDecorationLine: 'underline', fontSize: 15 }}>Register</text>

                <text onClick={() => setLogin(2)} style={{ cursor: 'pointer', marginTop: 5, textAlign: 'center', color: 'gray', textDecorationColor: 'gray', textDecorationLine: 'underline', fontSize: 13 }}>Forgot Password ?</text>

            </div>}

            {(login == 2 || login == 4) && <div style={{ marginTop: 15, display: 'flex', alignItems: 'center', width: isMobile ? '94%' : '80%', alignSelf: 'center', height: 290, flexDirection: 'column', marginBottom: 30, justifyContent: 'center' }}>

                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', width: isMobile ? '84%' : '40%' }}>
                    <text style={{ color: 'gray', fontWeight: '500', fontSize: 12, textAlign: 'left' }}>Email :</text>
                    <input type='text' onChange={(e) => setMail(e.currentTarget.value)} placeholder=' Your Email' style={{ backgroundColor: '#f8f8f8', marginTop: 4, height: 38, width: '96%', borderRadius: 10, outline: 'none', borderStyle: 'solid', borderWidth: 0.5, padding: 4, borderColor: 'lightgray' }} />
                </div>

                {login == 4 && <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', width: isMobile ? '84%' : '40%', marginTop: 16 }}>
                    <text style={{ color: 'gray', fontWeight: '500', fontSize: 12, textAlign: 'left' }}>Verification code : </text>
                    <input type='text' onChange={(e) => setPass(e.currentTarget.value)} placeholder=' Your verification code' style={{ backgroundColor: '#f8f8f8', marginTop: 4, height: 38, width: '96%', borderRadius: 10, outline: 'none', borderStyle: 'solid', borderWidth: 0.5, padding: 4, borderColor: 'lightgray' }} />
                </div>}

                <div onClick={() => decide()} style={{ alignItems: 'center', cursor: 'pointer', width: 140, height: 40, padding: 10, display: 'flex', justifyContent: 'center', backgroundColor: '#28b8dc', borderRadius: 4, alignSelf: 'center', marginTop: 10 }}>
                    <text style={{ color: 'white', fontSize: 12, fontWeight: '500' }}>{login == 4 ? 'Delete account' : 'Send verification code'}</text>
                </div>

            </div>}

            {login == 3 && <text style={{ marginTop: 30, textAlign: 'center', fontWeight: '700', fontSize: 20, color: 'black', marginBottom: 30 }}>Your account and it's details has been deleted !</text>}

            {login == false && <div style={{ marginTop: 20, display: 'flex', alignItems: 'center', width: isMobile ? '94%' : '80%', alignSelf: 'center', height: 320, flexDirection: 'column', marginBottom: 30, justifyContent: 'center' }}>

                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', width: isMobile ? '84%' : '40%' }}>
                    <text style={{ color: 'gray', fontWeight: '500', fontSize: 12, textAlign: 'left' }}>Email :</text>
                    <input onChange={(e) => setMail(e.currentTarget.value)} type='text' placeholder=' Your Email' style={{ backgroundColor: '#f8f8f8', marginTop: 4, height: 38, width: '96%', borderRadius: 10, outline: 'none', borderStyle: 'solid', borderWidth: 0.5, padding: 4, borderColor: 'lightgray' }} />
                </div>

                <div onClick={() => decide()} style={{ cursor: 'pointer', width: 120, height: 40, alignItems: 'center', padding: 10, display: 'flex', justifyContent: 'center', backgroundColor: '#28b8dc', borderRadius: 4, alignSelf: 'center', marginTop: 10 }}>
                    <text style={{ color: 'white', fontSize: 12, fontWeight: '500' }}>Delete Account</text>
                </div>

            </div>}


            <div style={{ marginTop: 30, display: 'flex', width: width, alignSelf: 'center', backgroundColor: '#28b8dc', height: 200, alignItems: 'center', justifyContent: 'center' }}>
                <div style={{ width: '90%', alignSelf: 'center', display: 'flex', flexDirection: 'row', height: 180, alignItems: 'center', justifyContent: 'space-between' }}>
                    <div style={{ flexDirection: 'column', display: 'flex', alignItems: 'flex-start', width: '40%', justifyContent: 'center' }}>
                        <img src={require('../nwlb.jpg')} style={{ height: 60, width: 60, borderRadius: 30, objectFit: 'contain', marginBottom: 6 }} />
                        <text style={{ color: 'white', fontSize: 12, fontWeight: '300', textAlign: 'left' }}>
                            Download our app North West Local Business - Easily find the services and products you need while enjoying
                            exclusive features not available on our website.
                        </text>
                    </div>
                    <div style={{ flexDirection: 'column', display: 'flex', alignItems: 'flex-start', width: '14%' }}>
                        <text style={{ fontSize: 14, color: 'white', fontWeight: '800' }}>OUR LOCATION</text>
                        <text style={{ color: 'white', fontSize: 12, fontWeight: '300', marginTop: 6 }}>
                            Northwest, UK
                        </text>
                    </div>
                    <div style={{ flexDirection: 'column', display: 'flex', alignItems: 'flex-start', width: '14%' }}>
                        <text style={{ fontSize: 14, color: 'white', fontWeight: '800' }}>CONTACT US</text>
                        <text style={{ color: 'white', fontSize: 12, fontWeight: '300', marginTop: 6 }}>
                            Tel: 0800 9750341
                        </text>
                    </div>
                </div>
            </div>

            <div style={{ backgroundColor: '#089ec4', width: width, display: 'flex', alignItems: 'center', height: 60, alignSelf: 'center', justifyContent: 'center' }}>
                <div style={{ width: isMobile ? '95%' : '90%', alignSelf: 'center', height: 50, display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between' }}>

                    <div style={{ width: '40%', display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between' }}>
                        <text onClick={() => navigate('/Condition')} style={{ cursor: 'pointer', color: 'white', fontWeight: '400', fontSize: 13, textDecorationLine: 'underline', textDecorationColor: 'white' }}>Terms & Conditions</text>
                        <text style={{ color: 'white', fontWeight: '400', fontSize: 13, textDecorationLine: 'underline', textDecorationColor: 'white' }}>Cookie Policy</text>
                        <text onClick={() => navigate('/Term')} style={{ cursor: 'pointer', color: 'white', fontWeight: '400', fontSize: 13, textDecorationLine: 'underline', textDecorationColor: 'white' }}>Privacy Policy</text>
                    </div>

                    <div style={{ width: isMobile ? '50%' : '25%', display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-around' }}>
                        <div style={{ display: 'flex', alignContent: 'center', alignItems: 'center', backgroundColor: '#28b8dc', height: 40, width: 40, borderRadius: 20, justifyContent: 'center' }}>
                            {instagram_icon}
                        </div>
                        <div style={{ display: 'flex', alignContent: 'center', alignItems: 'center', backgroundColor: '#28b8dc', height: 40, width: 40, borderRadius: 20, justifyContent: 'center' }}>
                            {twitter_icon}
                        </div>
                        <div style={{ display: 'flex', alignContent: 'center', alignItems: 'center', backgroundColor: '#28b8dc', height: 40, width: 40, borderRadius: 20, justifyContent: 'center' }}>
                            {facebook_icon}
                        </div>
                        <div style={{ display: 'flex', alignContent: 'center', alignItems: 'center', backgroundColor: '#28b8dc', height: 40, width: 40, borderRadius: 20, justifyContent: 'center' }}>
                            {instagram_icon}
                        </div>
                        <div style={{ display: 'flex', alignContent: 'center', alignItems: 'center', backgroundColor: '#28b8dc', height: 40, width: 40, borderRadius: 20, justifyContent: 'center' }}>
                            {instagram_icon}
                        </div>
                    </div>

                </div>
            </div>


            {loading && <div style={{ position: 'absolute', top: '35%', left: '48%', backgroundColor: 'white', padding: 10 }}><Levels size={35} /></div>}

        </div>
    );
}

export default Delete;
