export const MAKE_USER = 'MAKE_USER'

const initialState = { 'email': null }

const Reducer = (state = initialState, action) => {

    if (action.type == MAKE_USER) {
        return { ...state, email: action.payload }
    }

    return state;
}

export default Reducer;