import React, { useState, useEffect, useRef } from 'react';
import { address_big, back_icon, face_big, facebook_icon, favourite_icon, instagram_icon, mail_big, plus_icon, search_icon, tiktok_big, tube_big, twitter_icon, user_icon, whatsapp_big } from './icons';
import FlatList from 'flatlist-react/lib';
import back from './back.png'
import customer from './customer1.jpg'
import upload from './upload.png'
import '../App.css';
import { useNavigate, useLocation } from 'react-router-dom';
import { toast } from 'wc-toast';
import { db, storage } from '../config';
import { TimePicker } from 'react-ios-time-picker'
import { Levels } from 'react-activity';
import "react-activity/dist/library.css";
import ReactDropdown from 'react-dropdown';
import 'react-dropdown/style.css';
import moment from 'moment/moment';
//const newdb = db.ref('new')

const field_background = "#E4ECF7"

function id(mail) {
    let str = ''
    for (let i = 0; i < mail.length; i++) {
        if ((mail[i] >= 'a' && mail[i] <= 'z') || (mail[i] >= '0' && mail[i] <= '9')) str += mail[i]
    }
    return str;
}

const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sept', 'Oct', 'Nov', 'Dec']



function News() {

    const navigate = useNavigate();

    const { state } = useLocation();

    const { item } = state;

    console.log(item);

    const { ID, date, news, title, uri } = item

    // mine

    const [width, setWidth] = useState(window.innerWidth);
    const [height, setHeight] = useState(window.innerHeight);

    function handleWindowSizeChange() {
        setWidth(window.innerWidth);
        setHeight(window.innerHeight);
    }

    useEffect(() => {
        window.addEventListener('resize', handleWindowSizeChange);
        return () => {
            window.removeEventListener('resize', handleWindowSizeChange);
        }
    }, []);

    const isMobile = width <= 768;

    function timing(st) {
        let str = new Date(st)
        return moment(new Date(str)).format('hh:mm a').toString() + " , " + new Date(str).getDate() + " " + months[new Date(str).getMonth()]
    }

    return (
        <div style={{ display: 'flex', flexDirection: 'column', width: '100%', overflow: 'hidden', padding: 2 }}>

            <wc-toast></wc-toast>

            <div style={{ alignItems: 'center', width: '90%', display: 'flex', flexDirection: 'row', alignSelf: 'center', height: 80, justifyContent: 'space-between' }}>
                <div onClick={() => navigate('/')} style={{ cursor: 'pointer', width: '30%', display: 'flex', justifyContent: 'flex-start' }}>
                    <img src={require('../nwlb.jpg')} style={{ height: 60, width: 60, borderRadius: 30, objectFit: 'contain' }} />
                </div>
                <div style={{ width: '50%', display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-evenly' }}>
                    {isMobile && <ReactDropdown
                        arrowClosed={<text></text>}
                        arrowOpen={<text></text>}
                        options={['Favourite', 'Learn', 'Join', 'Contact us', 'Profile']}
                        onChange={(e) => {
                            let command = e.value;
                            if (command.includes('Favourite')) {
                                navigate('/Favourite')
                            }
                            else if (command.includes("Contact")) {
                                navigate('/Contact')
                            }
                            else if (command.includes("Profile")) {
                                navigate('/Login')
                            }
                            else if (command.includes("Add")) {
                                navigate('/AddListing')
                            }
                        }}
                        placeholder="     Menu     "
                    />}

                    {!isMobile && <text onClick={() => navigate('/Favourite')} style={{ cursor: 'pointer', fontSize: 16, color: 'black', fontWeight: '500' }}>Favourite</text>}
                    {!isMobile && <text style={{ fontSize: 16, color: 'black', fontWeight: '500' }}>Learn</text>}
                    {!isMobile && <text onClick={() => navigate('/Join')} style={{ fontSize: 16, color: 'black', fontWeight: '500' }}>Join</text>}
                    {!isMobile && <text onClick={() => navigate('/Contact')} style={{ cursor: 'pointer', fontSize: 16, color: 'black', fontWeight: '500' }}>Contact us</text>}
                    {!isMobile && <div onClick={() => navigate('/Login')} style={{ cursor: 'pointer', display: 'flex', alignContent: 'center', alignItems: 'center', backgroundColor: '#28b8dc', height: 35, width: 35, borderRadius: 4, justifyContent: 'center' }}>
                        {user_icon}
                    </div>}
                    {!isMobile && <div style={{ display: 'flex', alignContent: 'center', flexDirection: 'row', alignItems: 'center', backgroundColor: '#28b8dc', height: 35, width: 130, borderRadius: 4, justifyContent: 'center' }}>
                        {plus_icon}
                        <text style={{ marginLeft: 4, color: 'white', fontSize: 12 }}>Add Listing</text>
                    </div>}
                </div>
            </div>

            <div style={{ height: 120, backgroundColor: '#d4f1f8', display: 'flex', justifyContent: 'center', marginTop: 20 }}>
                <text style={{ fontWeight: '600', fontSize: isMobile ? 20 : 35, alignSelf: 'center', color: '#089ec4' }}>News and Entertainment Details</text>
            </div>

            <div style={{ cursor: 'pointer', alignSelf: 'center', display: 'flex', alignContent: 'center', alignItems: 'center', height: 45, borderRadius: 4, justifyContent: 'space-between', flexDirection: 'row', width: '80%', marginTop: 15 }}>
                <div onClick={() => { navigate(-1) }} style={{ cursor: 'pointer', backgroundColor: '#28b8dc', display: 'flex', height: 42, width: 40, borderRadius: 10, alignItems: 'center', justifyContent: 'center', alignContent: 'center' }}>
                    {back_icon}
                </div>
                <text></text>
            </div>

            <div style={{ display: 'flex', alignSelf: 'center', justifyContent: 'center', height: 350, width: '90%', marginTop: 20 }}>
                <img src={uri} style={{ cursor: 'pointer', alignSelf: 'center', height: 350, width: '90%', objectFit: 'contain' }} />
            </div>

            <text style={{ color: 'black', textAlign: 'center', marginTop: 20, color: 'blue', fontWeight: '800' }}>{title}</text>

            <div style={{ width: isMobile ? '95%' : '85%', alignSelf: 'center', backgroundColor: '#d4f1f8', borderRadius: 10, padding: 15, justifyContent: 'center', marginTop: 10 }}>
                <text style={{ color: '#28b8dc', fontWeight: '700', fontSize: 17 }}>{news}</text>
            </div>

            <div style={{ marginTop: 30, alignSelf: 'center', display: 'flex', width: width, backgroundColor: '#28b8dc', height: 200, alignItems: 'center', justifyContent: 'center' }}>
                <div style={{ width: '90%', alignSelf: 'center', display: 'flex', flexDirection: 'row', height: 180, alignItems: 'center', justifyContent: 'space-between' }}>
                    <div style={{ flexDirection: 'column', display: 'flex', alignItems: 'flex-start', width: '40%', justifyContent: 'center' }}>
                        <img src={require('../nwlb.jpg')} style={{ height: 60, width: 60, borderRadius: 30, objectFit: 'contain', marginBottom: 6 }} />
                        <text style={{ color: 'white', fontSize: 12, fontWeight: '300', textAlign: 'left' }}>
                            Download our app North West Local Business - Easily find the services and products you need while enjoying
                            exclusive features not available on our website.
                        </text>
                    </div>
                    <div style={{ flexDirection: 'column', display: 'flex', alignItems: 'flex-start', width: '14%' }}>
                        <text style={{ fontSize: 14, color: 'white', fontWeight: '800' }}>OUR LOCATION</text>
                        <text style={{ color: 'white', fontSize: 12, fontWeight: '300', marginTop: 6 }}>
                            Northwest, UK
                        </text>
                    </div>
                    <div style={{ flexDirection: 'column', display: 'flex', alignItems: 'flex-start', width: '14%' }}>
                        <text style={{ fontSize: 14, color: 'white', fontWeight: '800' }}>CONTACT US</text>
                        <text style={{ color: 'white', fontSize: 12, fontWeight: '300', marginTop: 6 }}>
                            Tel: 0800 9750341
                        </text>
                    </div>
                </div>
            </div>

            <div style={{ backgroundColor: '#089ec4', width: width, display: 'flex', alignItems: 'center', height: 60, alignSelf: 'center', justifyContent: 'center' }}>
                <div style={{ width: isMobile ? '95%' : '90%', alignSelf: 'center', height: 50, display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between' }}>

                    <div style={{ width: '40%', display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between' }}>
                        <text onClick={() => navigate('/Condition')} style={{ cursor: 'pointer', color: 'white', fontWeight: '400', fontSize: 13, textDecorationLine: 'underline', textDecorationColor: 'white' }}>Terms & Conditions</text>
                        <text style={{ color: 'white', fontWeight: '400', fontSize: 13, textDecorationLine: 'underline', textDecorationColor: 'white' }}>Cookie Policy</text>
                        <text onClick={() => navigate('/Term')} style={{ cursor: 'pointer', color: 'white', fontWeight: '400', fontSize: 13, textDecorationLine: 'underline', textDecorationColor: 'white' }}>Privacy Policy</text>
                    </div>

                    <div style={{ width: isMobile ? '50%' : '25%', display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-around' }}>
                        <div style={{ display: 'flex', alignContent: 'center', alignItems: 'center', backgroundColor: '#28b8dc', height: 40, width: 40, borderRadius: 20, justifyContent: 'center' }}>
                            {instagram_icon}
                        </div>
                        <div style={{ display: 'flex', alignContent: 'center', alignItems: 'center', backgroundColor: '#28b8dc', height: 40, width: 40, borderRadius: 20, justifyContent: 'center' }}>
                            {twitter_icon}
                        </div>
                        <div style={{ display: 'flex', alignContent: 'center', alignItems: 'center', backgroundColor: '#28b8dc', height: 40, width: 40, borderRadius: 20, justifyContent: 'center' }}>
                            {facebook_icon}
                        </div>
                        <div style={{ display: 'flex', alignContent: 'center', alignItems: 'center', backgroundColor: '#28b8dc', height: 40, width: 40, borderRadius: 20, justifyContent: 'center' }}>
                            {instagram_icon}
                        </div>
                        <div style={{ display: 'flex', alignContent: 'center', alignItems: 'center', backgroundColor: '#28b8dc', height: 40, width: 40, borderRadius: 20, justifyContent: 'center' }}>
                            {instagram_icon}
                        </div>
                    </div>

                </div>
            </div>


        </div>
    );
}

export default News;