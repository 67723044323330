import React, { useState, useEffect, useRef } from 'react';
import { AiOutlineInstagram, AiOutlineTwitter, AiOutlineUser,AiOutlineYoutube, AiOutlinePlus,AiOutlineCalendar, AiOutlineHeart,AiOutlineRight,AiOutlineLeft, AiFillHeart, AiOutlineMail } from 'react-icons/ai'
import { BsFacebook, BsWhatsapp, BsTiktok, BsYoutube,BsArrowRightShort } from 'react-icons/bs'
import { FaMapMarkerAlt, FaFacebookSquare } from 'react-icons/fa'
import { BiLogoLinkedin, BiLogoTiktok, BiSearch , BiArrowBack } from 'react-icons/bi'
import {VscThreeBars} from 'react-icons/vsc'


export const instagram_icon = <AiOutlineInstagram size='20' color='white' />
export const youtube_icon = <AiOutlineYoutube size='20' color='white' />
export const twitter_icon = <AiOutlineTwitter size='20' color='white' />
export const facebook_icon = <BsFacebook size='20' color='white' />
export const linked_icon = <BiLogoLinkedin size='20' color='white' />
export const tiktok_icon = <BiLogoTiktok size='20' color='white' />
export const search_icon = <BiSearch size='20' color='white' />


export const whatsapp_big = <BsWhatsapp size={35} color='#28b8dc' />
export const mail_big = <AiOutlineMail size={35} color='#28b8dc' />
export const address_big = <FaMapMarkerAlt size={35} color='#28b8dc' />
export const tiktok_big = <BsTiktok size={35} color='#28b8dc' />
export const tube_big = <BsYoutube size={35} color='#28b8dc' />
export const face_big = <FaFacebookSquare size={35} color='#28b8dc' />

export const favourite_icon = <AiFillHeart size='22' color='#28b8dc' />
export const non_favourite_icon = <AiOutlineHeart size='22' color='gray' />


export const user_icon = <AiOutlineUser size='17' color='white' />
export const plus_icon = <AiOutlinePlus size='16' color='white' />
export const right_icon = <BsArrowRightShort size='18' color='white' />

export const right_curve = <AiOutlineRight size='16' color='white' />
export const left_curve = <AiOutlineLeft size='18' color='white' />

export const calendar_icon = <AiOutlineCalendar size='16' color='#28b8dc' />

export const settings_icon = <VscThreeBars size='16' color='black' />
export const back_icon = <BiArrowBack size='17' color='white' />


