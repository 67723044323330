import React, { useState, useEffect, useRef } from 'react';
import { address_big, face_big, facebook_icon, favourite_icon, instagram_icon, mail_big, plus_icon, search_icon, tiktok_big, tube_big, twitter_icon, user_icon, whatsapp_big } from './icons';
import FlatList from 'flatlist-react/lib';
import back from './back.png'
import customer from './customer1.jpg'
import upload from './upload.png'
import '../App.css';
import { useNavigate, useLocation } from 'react-router-dom';
import { toast } from 'wc-toast';
import { db, storage } from '../config';
import { TimePicker } from 'react-ios-time-picker'
import { Levels } from 'react-activity';
import "react-activity/dist/library.css";
import ReactDropdown from 'react-dropdown';
import 'react-dropdown/style.css';


import { BsFacebook, BsTiktok, BsYoutube, BsTwitter, BsSnapchat, BsInstagram, BsWhatsapp } from 'react-icons/bs'
import { FaSoundcloud } from 'react-icons/fa'


const facebook_input = <BsFacebook size={18} color='#089ec4' />
const tiktok_input = <BsTiktok size={18} color='#089ec4' />
const youtube_input = <BsYoutube size={18} color='#089ec4' />
const twitter_input = <BsTwitter size={18} color='#089ec4' />

const snap_input = <BsSnapchat size={18} color='#089ec4' />
const instagam_input = <BsInstagram size={18} color='#089ec4' />
const whatsapp_input = <BsWhatsapp size={18} color='#089ec4' />
const sound_input = <FaSoundcloud size={18} color='#089ec4' />

//const newdb = db.ref('new')

const field_background = "#E4ECF7"

const waiting_db = db.ref('waiting_db');
const accepted_db = db.ref('accepted_db')
const admin_distance_db = db.ref('admin_distance_db');
const default_price_db = db.ref('default_price_db')

const advertiser = db.ref('advertiser');

function id(mail) {
    let str = ''
    for (let i = 0; i < mail.length; i++) {
        if ((mail[i] >= 'a' && mail[i] <= 'z') || (mail[i] >= '0' && mail[i] <= '9')) str += mail[i]
    }
    return str;
}

function AddListing() {

    const navigate = useNavigate();

    const { state } = useLocation();

    const ref = useRef()
    const dropdownRef = useRef()

    const handleClick = (e) => {
        ref.current.click()
    }

    const [token, setToken] = useState(null);

    function Distance(lat1, lon1, lat2, lon2) {
        if (lat1 == "" || lon1 == "") return 0
        var p = 0.017453292519943295;    // Math.PI / 180
        var c = Math.cos;
        var a = 0.5 - c((lat2 - lat1) * p) / 2 +
            c(lat1 * p) * c(lat2 * p) *
            (1 - c((lon2 - lon1) * p)) / 2;



        return 12742 * Math.asin(Math.sqrt(a)); // 2 * R; R = 6371 km
    }

    let added_by = 'user';

    let db = localStorage.getItem('login');
    if (db != null) {
        db = JSON.parse(db);
        added_by = db.mail;
    }

    // mine
    const [mail, setMail] = useState(null)
    const [username, setusername] = useState('User')

    useEffect(() => {
        if (token == null && mail != '' && mail != null) {
            let phn = id(mail)
            advertiser.child(String(phn)).child('token').once('value').then((snapshot) => {
                if (snapshot.val() == null) {
                    setToken(false)
                }
                else {
                    if (token == 'empty') setToken(false)
                    else {
                        setToken(snapshot.val())
                    }
                }
            })
        }
    }, [mail])

    const [business_name, setBusinessName] = useState('')
    const [address1, setAddress1] = useState('')
    const [address2, setAddress2] = useState('')
    const [country, setCountry] = useState('')
    const [post, setPost] = useState('')
    const [email, setEmail] = useState('')
    const [phone, setPhone] = useState('')
    const [website, setWebsite] = useState('')
    const [town, setTown] = useState('')
    const [picture, setPicture] = useState(null)
    const [imguri, setimguri] = useState(null)
    const [loading, setLoading] = useState(false)
    const [keyword, setkeyword] = useState('');
    const [description, setDescription] = useState('')


    const [facebook_link, setfacebook_link] = useState('')
    const [tiktok_link, settiktok_link] = useState('')
    const [youtube_link, setyoutubelink] = useState('')
    const [twitter_link, settwitter_link] = useState('')

    const [snap_link, setsnap_link] = useState('')
    const [instagram_link, setinstagram_link] = useState('')
    const [whatsapp_link, setwhatsapp_link] = useState('')
    const [sound_link, setsound_link] = useState('')



    const [curr_start_time, set_curr_start_time] = useState('08:00 AM')
    const [curr_end_time, set_curr_end_time] = useState('06:00 PM')

    const [showDist, setShowDist] = useState(null);
    const [defaultPrice, setDefaultPrice] = useState(null)
    const [dist, setDist] = useState(null)

    const [distance_index, setDistanceIndex] = useState(null)
    const [prices, setPrices] = useState([])
    const [bst, setBst] = useState(1);
    const [myplace, setMyplace] = useState(-1)

    // open or false
    const [fri, setFri] = useState(false)
    const [sat, setSat] = useState(false)
    const [sun, setSun] = useState(false)

    const [mon, setMon] = useState(false)
    const [tues, setTues] = useState(false)
    const [wed, setWed] = useState(false)

    const [thur, setThur] = useState(false)

    const [compete_token, setCompeteToken] = useState(null)


    // open time 
    const [fri_open, setFriOpen] = useState('')
    const [sat_open, setSatOpen] = useState('')
    const [sun_open, setSunOpen] = useState('')
    const [mon_open, setMonOpen] = useState('')

    const [tues_open, setTuesOpen] = useState('')
    const [wed_open, setWedOpen] = useState('')
    const [thur_open, setThurOpen] = useState('')

    // close time 
    const [fri_close, setFriClose] = useState('')
    const [sat_close, setSatClose] = useState('')
    const [sun_close, setSunClose] = useState('')
    const [mon_close, setMonClose] = useState('')

    const [tues_close, setTuesClose] = useState('')
    const [wed_close, setWedClose] = useState('')
    const [thur_close, setThurClose] = useState('')

    useEffect(() => {
        if (defaultPrice == null) {
            default_price_db.once('value').then((snapshot) => {
                if (snapshot.val() != null) {
                    setDefaultPrice(snapshot.val())
                }
            })
        }
    })

    useEffect(() => {
        if (dist == null) {
            admin_distance_db.once('value').then((snapshot) => {
                if (snapshot.val() != null) {
                    let arr = Object.values(snapshot.val())
                    arr.sort(function (a, b) { return b.distance - a.distance });
                    let temp = [];
                    let dst = [];
                    for (let i = 0; i < arr.length; i++) {
                        let st = String(arr[i].distance) + " KM ( £" + String(arr[i].price) + " per month)"
                        temp.push(st)
                        dst.push({ price: arr[i].price, distance: arr[i].distance })
                    }
                    setShowDist([...temp])
                    setDist([...dst])
                }
                else {
                    setDist([])
                    setShowDist([])
                }
            })
        }
    })

    useEffect(() => {
        if (compete_token != null) {
            let obj = { token: null, shop: null, keyword: null, curr_id: null, sub_id: null, price: 0 };
            let arr = compete_token;
            let temp = [obj, arr[1]]
            if (myplace == 2) setCompeteToken([...temp])
            else if (myplace == 3) setCompeteToken([obj, obj])
        }
    }, [myplace])

    const [disable, setDisable] = useState(false)

    useEffect(() => {
        if (distance_index != null && dist != null && keyword != '') {
            setMyplace(-1)
            if (dropdownRef.current != undefined) dropdownRef.current.reset()
            setLoading(true)
            let pst = String(post).toUpperCase().trim()
            let ky = pst[0] + pst[1] + "+" + String(dist[distance_index].price) + "+" + String(keyword).toLowerCase()

            accepted_db.orderByChild("search").equalTo(ky).once('value').then(async (snapshot) => {
                if (snapshot.val() != null) {
                    let arr = Object.values(snapshot.val());
                    arr.sort((a, b) => Number(b.place) - Number(a.place));

                    let fetchRes = await fetch(`https://api.mapbox.com/geocoding/v5/mapbox.places/${String(post).toUpperCase()}.json?access_token=pk.eyJ1IjoiYm9vbXNvZnR3YXJlIiwiYSI6ImNsZzg3NWpudjB1b2szZnA4aW5heTBvY2sifQ.uiP6gq8iyvMNPH9JaCR9MQ`);

                    let d;
                    d = await fetchRes.json()

                    const long = d.features[0].center[0]
                    const lat = d.features[0].center[1]

                    let mx_radius = 2 * dist[0].distance

                    let best = defaultPrice - 1;

                    let first_best = { token: null, shop: null, keyword: null, curr_id: null, sub_id: null, price: 0 };
                    let second_best = { token: null, shop: null, keyword: null, curr_id: null, sub_id: null, price: 0 };
                    let third_best = { token: null, shop: null, keyword: null, curr_id: null, sub_id: null, price: 0 };
                    // subId , id

                    // best one 
                    for (let i = 0; i < arr.length; i++) {

                        if (Distance(lat, long, arr[i].lat, arr[i].long) <= mx_radius) {

                            if (first_best.price <= arr[i].place) {
                                first_best = { token: arr[i].token, shop: arr[i].business_name, keyword: keyword, price: arr[i].place, curr_id: arr[i].id, sub_id: arr[i].subId }
                            }

                            best = Math.max(best, arr[i].place);
                        }
                    }

                    // second best one 
                    for (let i = 0; i < arr.length; i++) {
                        if (Distance(lat, long, arr[i].lat, arr[i].long) <= mx_radius) {
                            if (first_best.price > arr[i].place && second_best.price < arr[i].place) {
                                second_best = { token: arr[i].token, shop: arr[i].business_name, keyword: keyword, price: arr[i].place, curr_id: arr[i].id, sub_id: arr[i].subId }
                            }
                        }
                    }

                    // third best one 
                    for (let i = 0; i < arr.length; i++) {
                        if (Distance(lat, long, arr[i].lat, arr[i].long) <= mx_radius) {
                            if (second_best.price > arr[i].place && third_best.price < arr[i].place) {
                                third_best = { token: arr[i].token, shop: arr[i].business_name, keyword: keyword, price: arr[i].place, curr_id: arr[i].id, sub_id: arr[i].subId }
                            }
                        }
                    }


                    best++;
                    let prc = [];
                    let three = 0;
                    for (let i = best; i >= 1; i--) {
                        three++;
                        if (three > 3 || three > arr.length + 1) {
                            break;
                        }
                        else prc.push('£' + 2 * Number(i - 1));
                    }

                    console.log('First ', first_best)
                    console.log('Second ', second_best)

                    setBst(best)
                    setPrices([...prc])
                    setCompeteToken([first_best, second_best])
                    if (first_best.token != null && second_best.token != null && third_best.token != null) {
                        setDisable(true)
                        console.log('Making disable ')
                    }
                    else if (disable) { setDisable(false) }
                }
                else {
                    let arr = []
                    let best = defaultPrice - 1;
                    for (let i = 0; i < arr.length; i++) {
                        best = Math.max(best, arr[i].place);
                    }
                    best++;
                    let prc = [];
                    //let three = 0;
                    prc.push('£' + String(best));

                    setBst(best)
                    setPrices([...prc])
                    if (disable) setDisable(false)
                }
            })
        }
        else {
            if (keyword == '' && added_by != 'user') toast('You must fill up keyword')
        }
    }, [distance_index])

    useEffect(() => {
        if (prices.length > 0) {
            setLoading(false)
        }
    }, [prices])

    const onChangePicture = e => {
        if (e.target.files[0]) {
            // console.log("picture: ", e.target.files);
            let file = e.target.files[0]; // get the supplied file
            //setPicture(e.target.files[0]);
            const reader = new FileReader();
            if (file) {
                reader.addEventListener("load", () => {
                    // uploadToFirebase(file);
                    setPicture(reader.result);
                    setimguri(file)
                });
            }
            reader.readAsDataURL(e.target.files[0]);
        }
    };

    useEffect(() => {
        if (mail == null) {
            let db = localStorage.getItem('login');
            console.log(JSON.parse(db))
            if (db != null) {
                db = JSON.parse(db);
                setMail(db.mail)
                setusername(db.name)
            }
            else setMail('')
        }
    })

    const uploadImageFree = async () => {
        if (description != '' && business_name != '' && address1 != '' && address2 != '' && country != '' && post != '' && email != '' && phone != '' && website != '' && town != '' && imguri != null) {
            setLoading(true)
            toast('Please wait for confirmation message !');

            let low = String(business_name).toLowerCase()
            let word_arr = low.split(" ")

            const timestamp = String(new Date().getTime())
            const ID = id(mail) + "-" + String(timestamp)
            const uploadTask = storage.ref(`/images/${ID}`).put(imguri);
            uploadTask.on('state_changed', snapshot => {
                const progress = Math.round(snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                // if (progress == 100) (setLoading(false));
            }, (error) => {
                // Handle unsuccessful uploads
                alert('error uploading image')
            }, () => {
                storage.ref(`/images/${ID}`).getDownloadURL().then((downloadURL) => {
                    let obj = {
                        id: ID,
                        token: 'empty',
                        search1: ((word_arr[0] == undefined || word_arr[0] == '') ? null : word_arr[0].trim()),
                        search2: ((word_arr[1] == undefined || word_arr[1] == '') ? null : word_arr[1].trim()),
                        search3: ((word_arr[2] == undefined || word_arr[2] == '') ? null : word_arr[2].trim()),
                        search4: ((word_arr[3] == undefined || word_arr[3] == '') ? null : word_arr[3].trim()),
                        search5: ((word_arr[4] == undefined || word_arr[4] == '') ? null : word_arr[4].trim()),
                        search6: ((word_arr[5] == undefined || word_arr[5] == '') ? null : word_arr[5].trim()),

                        friday: fri ? { start: fri_open, end: fri_close } : 'Closed',
                        saturday: sat ? { start: sat_open, end: sat_close } : 'Closed',
                        sunday: sun ? { start: sun_open, end: sun_close } : 'Closed',
                        monday: mon ? { start: mon_open, end: mon_close } : 'Closed',
                        tuesday: tues ? { start: tues_open, end: tues_close } : 'Closed',
                        wednesday: fri ? { start: fri_open, end: fri_close } : 'Closed',
                        thursday: fri ? { start: fri_open, end: fri_close } : 'Closed',

                        description: description,
                        username: username,
                        added_by: added_by,
                        business_name: business_name,
                        search_business_name: String(business_name).toLowerCase(),
                        uri: downloadURL,
                        email: email,
                        website: website,
                        town: town,
                        country: country,
                        post: String(post).toUpperCase(),
                        address1: address1,
                        address2: address2,
                        phone: phone,
                        date: timestamp,
                        click: 0,
                        rating5: 0,
                        rating4: 0,
                        rating3: 0,
                        rating2: 0,
                        rating1: 0,
                        free: true,
                        facebook: facebook_link,
                        youtube: youtube_link,
                        tiktok: tiktok_link,
                        twitter: twitter_link,
                        snapchat: snap_link,
                        instagram: instagram_link,
                        whatsapp: whatsapp_link,
                        sound: sound_link,
                        wordpress: false,
                    }
                    let fetchRes = fetch(`https://api.mapbox.com/geocoding/v5/mapbox.places/${String(post).toUpperCase()}.json?access_token=pk.eyJ1IjoiYm9vbXNvZnR3YXJlIiwiYSI6ImNsZzg3NWpudjB1b2szZnA4aW5heTBvY2sifQ.uiP6gq8iyvMNPH9JaCR9MQ`);
                    fetchRes.then(res =>
                        res.json()).then(d => {
                            if (d.features != undefined && d.features[0].center != undefined) {
                                const long = d.features[0].center[0]
                                const lat = d.features[0].center[1]
                                obj.long = long
                                obj.lat = lat
                                waiting_db.child(ID).set(obj)
                                    .then(() => {
                                        toast('Advertise has been uploaded !');
                                        setLoading(false);
                                    })
                            }
                            else {
                                setLoading(false);
                                toast('Post code is not found !')
                            }
                        })
                });
            })
            try {
                await uploadTask;
            } catch (e) {
                console.error(e);
            }
        }
        else {
            toast('Empty fields are not accepted !')
        }
    };

    const uploadImage = async () => {
        if (!disable && ((fri_open != '' && fri) || (fri == false)) && ((sat_open != '' && sat) || (sat == false)) && ((sun_open != '' && sun) || (sun == false)) && ((mon_open != '' && mon) || (mon == false)) && ((tues_open != '' && tues) || (tues == false)) && ((wed_open != '' && wed) || (wed == false)) && ((thur_open != '' && thur) || (thur == false)) && dist != null && myplace != -1 && business_name != '' && town != null && country != null && distance_index != null && address1 != '' && address2 != '' && post != '' && email != '' && keyword != '' && phone != '' && imguri != null) {

            setLoading(true)
            toast('Please wait for confirmation message !');
            const timestamp = String(new Date().getTime())
            const ID = id(mail) + "-" + String(timestamp)
            const uploadTask = storage.ref(`/images/${ID}`).put(imguri);
            uploadTask.on('state_changed', snapshot => {
                const progress = Math.round(snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                // if (progress == 100) (setLoading(false));
            }, (error) => {
                // Handle unsuccessful uploads
                alert('error uploading image')
            }, () => {
                storage.ref(`/images/${ID}`).getDownloadURL().then((downloadURL) => {
                    let pst = String(post).toUpperCase().trim()
                    let ky = pst[0] + pst[1] + "+" + String(dist[distance_index].price) + "+" + String(keyword).toLowerCase()
                    let only_search = String(post).toUpperCase() + "+" + String(keyword).toLowerCase()
                    let obj = {
                        id: ID,
                        only_search: only_search,
                        token: token == false ? 'empty' : token,
                        username: username,
                        added_by: added_by,
                        business_name: business_name,
                        uri: downloadURL,
                        description: description,
                        email: email,
                        website: website,
                        town: town,
                        country: country,
                        post: String(post).toUpperCase(),
                        address1: address1,
                        address2: address2,
                        phone: phone,
                        keyword: String(keyword).toLowerCase(),
                        date: timestamp,
                        click: 0,
                        rating5: 0,
                        rating4: 0,
                        rating3: 0,
                        rating2: 0,
                        rating1: 0,
                        facebook: facebook_link,
                        youtube: youtube_link,
                        tiktok: tiktok_link,
                        twitter: twitter_link,
                        snapchat: snap_link,
                        instagram: instagram_link,
                        whatsapp: whatsapp_link,
                        sound: sound_link,
                        distance_reveneu: Number(dist[distance_index].price),
                        distance: Number(dist[distance_index].distance),
                        search: ky,
                        wordpress: false,
                        // place reveneu
                        place: (bst - myplace) + 1,

                        friday: fri ? { start: fri_open, end: fri_close } : 'Closed',
                        saturday: sat ? { start: sat_open, end: sat_close } : 'Closed',
                        sunday: sun ? { start: sun_open, end: sun_close } : 'Closed',
                        monday: mon ? { start: mon_open, end: mon_close } : 'Closed',
                        tuesday: tues ? { start: tues_open, end: tues_close } : 'Closed',
                        wednesday: fri ? { start: fri_open, end: fri_close } : 'Closed',
                        thursday: fri ? { start: fri_open, end: fri_close } : 'Closed',
                    }
                    let fetchRes = fetch(`https://api.mapbox.com/geocoding/v5/mapbox.places/${String(post).toUpperCase()}.json?access_token=pk.eyJ1IjoiYm9vbXNvZnR3YXJlIiwiYSI6ImNsZzg3NWpudjB1b2szZnA4aW5heTBvY2sifQ.uiP6gq8iyvMNPH9JaCR9MQ`);
                    fetchRes.then(res =>
                        res.json()).then(d => {
                            if (d.features != undefined && d.features[0].center != undefined) {
                                const long = d.features[0].center[0]
                                const lat = d.features[0].center[1]
                                obj.long = long
                                obj.lat = lat
                                waiting_db.child(ID).set(obj)
                                    .then(() => {

                                        toast('Advertise has been uploaded !');
                                        setLoading(false);

                                    })
                            }
                            else {
                                setLoading(false);
                                toast('Post code is not found !')
                            }
                        })
                });
            })
            try {
                await uploadTask;
            } catch (e) {
                console.error(e);
            }
        }
        else {
            toast('Empty fields are not accepted !')
        }
    };

    useEffect(() => {
        const keyDownHandler = event => {

            if (event.key === 'Enter') {
                event.preventDefault();
                decideUpload()
            }
        };

        document.addEventListener('keydown', keyDownHandler);

        return () => {
            document.removeEventListener('keydown', keyDownHandler);
        };
    }, [business_name, dist, myplace, distance_index, address1, address2, country, post, email, phone, website, town, imguri, description, facebook_link, youtube_link, tiktok_link, twitter_link, snap_link, instagram_link, whatsapp_link, sound_link]);


    function decideUpload() {
        if (mail != '' && keyword != '') uploadImage()
        else uploadImageFree()
    }

    const [width, setWidth] = useState(window.innerWidth);
    const [height, setHeight] = useState(window.innerHeight);

    function handleWindowSizeChange() {
        setWidth(window.innerWidth);
        setHeight(window.innerHeight);
    }

    useEffect(() => {
        window.addEventListener('resize', handleWindowSizeChange);
        return () => {
            window.removeEventListener('resize', handleWindowSizeChange);
        }
    }, []);

    const isMobile = width <= 768;

    return (
        <div style={{ display: 'flex', flexDirection: 'column', width: '100%', overflow: 'hidden', padding: 2 }}>

            <input id="actual" ref={ref} accept="image/*" onChange={onChangePicture} type="file" hidden={true} />

            <wc-toast></wc-toast>

            <div hidden={true} style={{}}>
                <TimePicker value={curr_start_time} id='start' use12Hours inputClassName='drop_time' onChange={(e) => { console.log(e); set_curr_start_time(e) }} placeHolder='Select' />
                <TimePicker value={curr_end_time} id="end" use12Hours inputClassName='drop_time' onChange={(e) => { console.log(e); set_curr_end_time(e) }} placeHolder='Select' />
            </div>

            <div style={{ alignItems: 'center', width: '90%', display: 'flex', flexDirection: 'row', alignSelf: 'center', height: 80, justifyContent: 'space-between' }}>
                <div onClick={() => navigate('/')} style={{ cursor: 'pointer', width: '30%', display: 'flex', justifyContent: 'flex-start' }}>
                    <img src={require('../nwlb.jpg')} style={{ height: 60, width: 60, borderRadius: 30, objectFit: 'contain' }} />

                </div>
                <div style={{ width: '50%', display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-evenly' }}>
                    {isMobile && <ReactDropdown
                        arrowClosed={<text></text>}
                        arrowOpen={<text></text>}
                        options={['Favourite', 'Learn', 'Join', 'Contact us', 'Profile']}
                        onChange={(e) => {
                            let command = e.value;
                            if (command.includes('Favourite')) {
                                navigate('/Favourite')
                            }
                            else if (command.includes("Contact")) {
                                navigate('/Contact')
                            }
                            else if (command.includes("Profile")) {
                                navigate('/Login')
                            }
                            else if (command.includes("Add")) {
                                navigate('/AddListing')
                            }
                        }}
                        placeholder="     Menu     "
                    />}

                    {!isMobile && <text onClick={() => navigate('/Favourite')} style={{ cursor: 'pointer', fontSize: 16, color: 'black', fontWeight: '500' }}>Favourite</text>}
                    {!isMobile && <text style={{ fontSize: 16, color: 'black', fontWeight: '500' }}>Learn</text>}
                    {!isMobile && <text onClick={() => navigate('/Join')} style={{ fontSize: 16, color: 'black', fontWeight: '500' }}>Join</text>}
                    {!isMobile && <text onClick={() => navigate('/Contact')} style={{ cursor: 'pointer', fontSize: 16, color: 'black', fontWeight: '500' }}>Contact us</text>}
                    {!isMobile && <div onClick={() => navigate('/Login')} style={{ cursor: 'pointer', display: 'flex', alignContent: 'center', alignItems: 'center', backgroundColor: '#28b8dc', height: 35, width: 35, borderRadius: 4, justifyContent: 'center' }}>
                        {user_icon}
                    </div>}
                    {!isMobile && <div style={{ display: 'flex', alignContent: 'center', flexDirection: 'row', alignItems: 'center', backgroundColor: '#28b8dc', height: 35, width: 130, borderRadius: 4, justifyContent: 'center' }}>
                        {plus_icon}
                        <text style={{ marginLeft: 4, color: 'white', fontSize: 12 }}>Add Listing</text>
                    </div>}
                </div>
            </div>

            <div style={{ height: 170, backgroundColor: '#d4f1f8', display: 'flex', justifyContent: 'center', marginTop: 20 }}>
                <text style={{ fontWeight: '600', fontSize: 45, alignSelf: 'center', color: '#089ec4' }}>Add Listing</text>
            </div>

            <div onClick={handleClick} style={{ display: 'flex', alignSelf: 'center', justifyContent: 'center', height: 130, width: 130, marginTop: 50 }}>
                {picture ? <img onClick={handleClick} src={picture} style={{ cursor: 'pointer', width: 120, height: 120, borderRadius: 16, objectFit: 'fill' }} /> :
                    <img src={upload} style={{ cursor: 'pointer', alignSelf: 'center', height: 120, width: 120, objectFit: 'contain' }} />
                }
            </div>

            <text style={{ textAlign: 'center', fontWeight: '600', fontSize: 13, marginTop: 10, letterSpacing: 1.03, marginBottom: 15 }}>Add Logo</text>

            {!isMobile && <div style={{ display: 'flex', alignItems: 'center', width: '80%', alignSelf: 'center', maxHeight: 1515, flexDirection: 'column', marginBottom: 30, marginTop: 15, justifyContent: 'center' }}>
                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginTop: 10, width: '90%', justifyContent: 'space-between' }}>
                    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', width: '40%' }}>
                        <text style={{ color: 'gray', fontWeight: '500', fontSize: 12, textAlign: 'left' }}>Business name :</text>
                        <input value={business_name} onChange={(e) => setBusinessName(e.currentTarget.value)} type='text' placeholder=' Your Business Name' style={{ backgroundColor: '#f8f8f8', marginTop: 4, height: 38, width: '96%', borderRadius: 10, outline: 'none', borderStyle: 'solid', borderWidth: 0.5, padding: 4, borderColor: (business_name != '' ? 'lightgray' : 'red') }} />
                    </div>

                    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', marginLeft: 40, width: '40%' }}>
                        <text style={{ color: 'gray', fontWeight: '500', fontSize: 12, textAlign: 'left' }}>Address1 : </text>
                        <input value={address1} onChange={(e) => setAddress1(e.currentTarget.value)} type='text' placeholder=' Your Address 1' style={{ backgroundColor: '#f8f8f8', marginTop: 4, height: 38, width: '96%', borderRadius: 10, outline: 'none', borderStyle: 'solid', borderWidth: 0.5, padding: 4, borderColor: (address1 != '' ? 'lightgray' : 'red') }} />
                    </div>
                </div>
                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginTop: 10, width: '90%', justifyContent: 'space-between' }}>
                    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', width: '40%' }}>
                        <text style={{ color: 'gray', fontWeight: '500', fontSize: 12, textAlign: 'left' }}>Address2 :</text>
                        <input value={address2} onChange={(e) => setAddress2(e.currentTarget.value)} type='text' placeholder=' Your Address 2' style={{ backgroundColor: '#f8f8f8', marginTop: 4, height: 38, width: '96%', borderRadius: 10, outline: 'none', borderStyle: 'solid', borderWidth: 0.5, padding: 4, borderColor: (address2 != '' ? 'lightgray' : 'red') }} />
                    </div>

                    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', marginLeft: 40, width: '40%' }}>
                        <text style={{ color: 'gray', fontWeight: '500', fontSize: 12, textAlign: 'left' }}>City : </text>
                        <input value={town} onChange={(e) => setTown(e.currentTarget.value)} type='text' placeholder=' Your City' style={{ backgroundColor: '#f8f8f8', marginTop: 4, height: 38, width: '96%', borderRadius: 10, outline: 'none', borderStyle: 'solid', borderWidth: 0.5, padding: 4, borderColor: (town != '' ? 'lightgray' : 'red') }} />
                    </div>
                </div>
                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginTop: 10, width: '90%', justifyContent: 'space-between' }}>
                    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', width: '40%' }}>
                        <text style={{ color: 'gray', fontWeight: '500', fontSize: 12, textAlign: 'left' }}>Post Code :</text>
                        <input value={post} onChange={(e) => setPost(e.currentTarget.value)} type='text' placeholder=' Your post code' style={{ backgroundColor: '#f8f8f8', marginTop: 4, height: 38, width: '96%', borderRadius: 10, outline: 'none', borderStyle: 'solid', borderWidth: 0.5, padding: 4, borderColor: (post != '' ? 'lightgray' : 'red') }} />
                    </div>

                    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', marginLeft: 40, width: '40%' }}>
                        <text style={{ color: 'gray', fontWeight: '500', fontSize: 12, textAlign: 'left' }}>Country : </text>
                        <input value={country} onChange={(e) => setCountry(e.currentTarget.value)} type='text' placeholder=' Your Country' style={{ backgroundColor: '#f8f8f8', marginTop: 4, height: 38, width: '96%', borderRadius: 10, outline: 'none', borderStyle: 'solid', borderWidth: 0.5, padding: 4, borderColor: (country != '' ? 'lightgray' : 'red') }} />
                    </div>
                </div>


                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginTop: 10, width: '90%', justifyContent: 'space-between' }}>
                    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', width: '40%' }}>
                        <text style={{ color: 'gray', fontWeight: '500', fontSize: 12, textAlign: 'left' }}>Email :</text>
                        <input value={email} onChange={(e) => setEmail(e.currentTarget.value)} type='text' placeholder=' Your email' style={{ backgroundColor: '#f8f8f8', marginTop: 4, height: 38, width: '96%', borderRadius: 10, outline: 'none', borderStyle: 'solid', borderWidth: 0.5, padding: 4, borderColor: (email != '' ? 'lightgray' : 'red') }} />
                    </div>

                    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', marginLeft: 40, width: '40%' }}>
                        <text style={{ color: 'gray', fontWeight: '500', fontSize: 12, textAlign: 'left' }}>Phone number : </text>
                        <input value={phone} onChange={(e) => setPhone(e.currentTarget.value)} type='text' placeholder=' Your Number' style={{ backgroundColor: '#f8f8f8', marginTop: 4, height: 38, width: '96%', borderRadius: 10, outline: 'none', borderStyle: 'solid', borderWidth: 0.5, padding: 4, borderColor: (phone != '' ? 'lightgray' : 'red') }} />
                    </div>
                </div>

                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginTop: 10, width: '90%', justifyContent: 'space-between' }}>
                    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', width: '99%' }}>
                        <text style={{ color: 'gray', fontWeight: '500', fontSize: 12, textAlign: 'left' }}>Description :</text>
                        <textarea value={description} onChange={(e) => setDescription(e.currentTarget.value)} type='text' placeholder='Description' style={{ backgroundColor: '#f8f8f8', marginTop: 4, height: 88, width: '99%', borderRadius: 10, outline: 'none', borderStyle: 'solid', borderWidth: 0.5, padding: 4, borderColor: (description != '' ? 'lightgray' : 'red') }} />
                    </div>
                </div>

                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginTop: 10, width: '90%', justifyContent: 'space-between' }}>
                    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', width: '40%' }}>
                        <text style={{ color: 'gray', fontWeight: '500', fontSize: 12, textAlign: 'left' }}>Website Url :</text>
                        <input value={website} onChange={(e) => setWebsite(e.currentTarget.value)} type='text' placeholder=' Your Link' style={{ backgroundColor: '#f8f8f8', marginTop: 4, height: 38, width: '96%', borderRadius: 10, outline: 'none', borderStyle: 'solid', borderWidth: 0.5, padding: 4, borderColor: (website != '' ? 'lightgray' : 'red') }} />
                    </div>
                </div>

                <div style={{ display: 'flex', flexDirection: 'row', marginTop: 14, width: '90%', justifyContent: 'space-between' }}>

                    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', width: '40%' }}>
                        <text style={{ color: 'gray', fontWeight: '500', fontSize: 12, textAlign: 'left' }}>Social media links : </text>
                        <div style={{ padding: 4, flexDirection: 'row', display: 'flex', alignItems: 'center', width: '96%', backgroundColor: '#f8f8f8', marginTop: 12, height: 42, width: '96%', borderRadius: 10, borderStyle: 'solid', borderWidth: 0.5, borderColor: 'lightgray' }}>
                            {facebook_input}
                            <input value={facebook_link} onChange={(e) => setfacebook_link(e.currentTarget.value)} type='text' placeholder='Facebook Link' style={{ outline: 'none', borderWidth: 0, borderStyle: 'solid', padding: 4, width: '85%' }} />
                        </div>
                        <div style={{ padding: 4, flexDirection: 'row', display: 'flex', alignItems: 'center', width: '96%', backgroundColor: '#f8f8f8', marginTop: 12, height: 42, width: '96%', borderRadius: 10, borderStyle: 'solid', borderWidth: 0.5, borderColor: 'lightgray' }}>
                            {youtube_input}
                            <input value={youtube_link} onChange={(e) => setyoutubelink(e.currentTarget.value)} type='text' placeholder='Youtube Link' style={{ outline: 'none', borderWidth: 0, borderStyle: 'solid', padding: 4, width: '85%' }} />
                        </div>

                        <div style={{ padding: 4, flexDirection: 'row', display: 'flex', alignItems: 'center', width: '96%', backgroundColor: '#f8f8f8', marginTop: 12, height: 42, width: '96%', borderRadius: 10, borderStyle: 'solid', borderWidth: 0.5, borderColor: 'lightgray' }}>
                            {tiktok_input}
                            <input value={tiktok_link} onChange={(e) => settiktok_link(e.currentTarget.value)} type='text' placeholder='Tiktok Link' style={{ outline: 'none', borderWidth: 0, borderStyle: 'solid', padding: 4, width: '85%' }} />
                        </div>
                        <div style={{ padding: 4, flexDirection: 'row', display: 'flex', alignItems: 'center', width: '96%', backgroundColor: '#f8f8f8', marginTop: 12, height: 42, width: '96%', borderRadius: 10, borderStyle: 'solid', borderWidth: 0.5, borderColor: 'lightgray' }}>
                            {twitter_input}
                            <input value={twitter_link} onChange={(e) => settwitter_link(e.currentTarget.value)} type='text' placeholder='Twitter Link' style={{ outline: 'none', borderWidth: 0, borderStyle: 'solid', padding: 4, width: '85%' }} />
                        </div>

                        <div style={{ padding: 4, flexDirection: 'row', display: 'flex', alignItems: 'center', width: '96%', backgroundColor: '#f8f8f8', marginTop: 12, height: 42, width: '96%', borderRadius: 10, borderStyle: 'solid', borderWidth: 0.5, borderColor: 'lightgray' }}>
                            {snap_input}
                            <input value={snap_link} onChange={(e) => setsnap_link(e.currentTarget.value)} type='text' placeholder='Snapchat Link' style={{ outline: 'none', borderWidth: 0, borderStyle: 'solid', padding: 4, width: '85%' }} />
                        </div>
                        <div style={{ padding: 4, flexDirection: 'row', display: 'flex', alignItems: 'center', width: '96%', backgroundColor: '#f8f8f8', marginTop: 12, height: 42, width: '96%', borderRadius: 10, borderStyle: 'solid', borderWidth: 0.5, borderColor: 'lightgray' }}>
                            {instagam_input}
                            <input value={instagram_link} onChange={(e) => setinstagram_link(e.currentTarget.value)} type='text' placeholder='Instagram Link' style={{ outline: 'none', borderWidth: 0, borderStyle: 'solid', padding: 4, width: '85%' }} />
                        </div>

                        <div style={{ padding: 4, flexDirection: 'row', display: 'flex', alignItems: 'center', width: '96%', backgroundColor: '#f8f8f8', marginTop: 12, height: 42, width: '96%', borderRadius: 10, borderStyle: 'solid', borderWidth: 0.5, borderColor: 'lightgray' }}>
                            {whatsapp_input}
                            <input value={whatsapp_link} onChange={(e) => setwhatsapp_link(e.currentTarget.value)} type='text' placeholder='Whatsapp number' style={{ outline: 'none', borderWidth: 0, borderStyle: 'solid', padding: 4, width: '85%' }} />
                        </div>
                        <div style={{ padding: 4, flexDirection: 'row', display: 'flex', alignItems: 'center', width: '96%', backgroundColor: '#f8f8f8', marginTop: 12, height: 42, width: '96%', borderRadius: 10, borderStyle: 'solid', borderWidth: 0.5, borderColor: 'lightgray' }}>
                            {sound_input}
                            <input value={sound_link} onChange={(e) => setsound_link(e.currentTarget.value)} type='text' placeholder='Sound cloud link' style={{ outline: 'none', borderWidth: 0, borderStyle: 'solid', padding: 4, width: '85%' }} />
                        </div>
                    </div>

                    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', width: '40%' }}>

                        <text style={{ color: 'gray', fontWeight: '500', fontSize: 12, textAlign: 'left' }}>Opening & closing : </text>


                        <text style={{ color: 'gray', fontWeight: '600', fontSize: 11, textAlign: 'left', marginTop: 10 }}>Monday</text>
                        <div style={{ padding: 4, flexDirection: 'row', display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '96%', backgroundColor: '#f8f8f8', marginTop: 6, height: 42, width: '96%', borderRadius: 10, borderStyle: 'solid', borderWidth: 0.5, borderColor: 'lightgray' }}>
                            <input checked={mon} type="checkbox" onChange={() => { if (mon) setMon(false); else setMon(true) }} />
                            {mon && <text style={{ fontSize: 11 }}>{mon_open != '' ? 'Open ' : ''}</text>}
                            <input value={mon ? mon_open : 'Closed'} onChange={(e) => setMonOpen(e.currentTarget.value)} type='text' placeholder={mon ? 'Open, xx:xx am' : 'Closed'} style={{ outline: 'none', borderWidth: 0, borderStyle: 'solid', padding: 4, width: mon ? '45%' : '95%' }} />
                            {mon && <text style={{ fontSize: 11 }}>{mon_open != '' ? 'Close ' : ''}</text>}
                            <input value={mon_close} onChange={(e) => setMonClose(e.currentTarget.value)} type='text' placeholder={mon_open ? 'xx : xx am' : 'Close, xx:xx pm'} style={{ outline: 'none', borderWidth: 0, borderStyle: 'solid', padding: 4, width: mon ? '45%' : '0%' }} />
                        </div>

                        <text style={{ color: 'gray', fontWeight: '600', fontSize: 11, textAlign: 'left', marginTop: 10 }}>Tuesday</text>
                        <div style={{ padding: 4, flexDirection: 'row', display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '96%', backgroundColor: '#f8f8f8', marginTop: 6, height: 42, width: '96%', borderRadius: 10, borderStyle: 'solid', borderWidth: 0.5, borderColor: 'lightgray' }}>
                            <input checked={tues} type="checkbox" onChange={() => { if (tues) setTues(false); else setTues(true) }} />
                            {tues && <text style={{ fontSize: 11 }}>{tues_open != '' ? 'Open ' : ''}</text>}
                            <input value={tues ? tues_open : 'Closed'} onChange={(e) => setTuesOpen(e.currentTarget.value)} type='text' placeholder={tues ? 'Open, xx:xx am' : 'Closed'} style={{ outline: 'none', borderWidth: 0, borderStyle: 'solid', padding: 4, width: tues ? '45%' : '95%' }} />
                            {tues && <text style={{ fontSize: 11 }}>{tues_open != '' ? 'Close ' : ''}</text>}
                            <input value={tues_close} onChange={(e) => setTuesClose(e.currentTarget.value)} type='text' placeholder={tues_open ? 'xx : xx am' : 'Close, xx:xx pm'} style={{ outline: 'none', borderWidth: 0, borderStyle: 'solid', padding: 4, width: tues ? '45%' : '0%' }} />
                        </div>

                        <text style={{ color: 'gray', fontWeight: '600', fontSize: 11, textAlign: 'left', marginTop: 10 }}>Wednesday</text>
                        <div style={{ padding: 4, flexDirection: 'row', display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '96%', backgroundColor: '#f8f8f8', marginTop: 6, height: 42, width: '96%', borderRadius: 10, borderStyle: 'solid', borderWidth: 0.5, borderColor: 'lightgray' }}>
                            <input checked={wed} type="checkbox" onChange={() => { if (wed) setWed(false); else setWed(true) }} />
                            {wed && <text style={{ fontSize: 11 }}>{wed_open != '' ? 'Open ' : ''}</text>}
                            <input value={wed ? wed_open : 'Closed'} onChange={(e) => setWedOpen(e.currentTarget.value)} type='text' placeholder={wed ? 'Open, xx:xx am' : 'Closed'} style={{ outline: 'none', borderWidth: 0, borderStyle: 'solid', padding: 4, width: wed ? '45%' : '95%' }} />
                            {wed && <text style={{ fontSize: 11 }}>{wed_open != '' ? 'Close ' : ''}</text>}
                            <input value={wed_close} onChange={(e) => setWedClose(e.currentTarget.value)} type='text' placeholder={wed_open ? 'xx : xx am' : 'Close, xx:xx pm'} style={{ outline: 'none', borderWidth: 0, borderStyle: 'solid', padding: 4, width: wed ? '45%' : '0%' }} />
                        </div>

                        <text style={{ color: 'gray', fontWeight: '600', fontSize: 11, textAlign: 'left', marginTop: 10 }}>Thursday</text>
                        <div style={{ padding: 4, flexDirection: 'row', display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '96%', backgroundColor: '#f8f8f8', marginTop: 6, height: 42, width: '96%', borderRadius: 10, borderStyle: 'solid', borderWidth: 0.5, borderColor: 'lightgray' }}>
                            <input checked={thur} type="checkbox" onChange={() => { if (thur) setThur(false); else setThur(true) }} />
                            {thur && <text style={{ fontSize: 11 }}>{thur_open != '' ? 'Open ' : ''}</text>}
                            <input value={thur ? thur_open : 'Closed'} onChange={(e) => setThurOpen(e.currentTarget.value)} type='text' placeholder={thur ? 'Open, xx:xx am' : 'Closed'} style={{ outline: 'none', borderWidth: 0, borderStyle: 'solid', padding: 4, width: thur ? '45%' : '95%' }} />
                            {thur && <text style={{ fontSize: 11 }}>{thur_open != '' ? 'Close ' : ''}</text>}
                            <input value={thur_close} onChange={(e) => setThurClose(e.currentTarget.value)} type='text' placeholder={thur_open ? 'xx : xx am' : 'Close, xx:xx pm'} style={{ outline: 'none', borderWidth: 0, borderStyle: 'solid', padding: 4, width: thur ? '45%' : '0%' }} />
                        </div>

                        <text style={{ color: 'gray', fontWeight: '600', fontSize: 11, textAlign: 'left', marginTop: 10 }}>Friday</text>
                        <div style={{ padding: 4, flexDirection: 'row', display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '96%', backgroundColor: '#f8f8f8', marginTop: 6, height: 42, width: '96%', borderRadius: 10, borderStyle: 'solid', borderWidth: 0.5, borderColor: 'lightgray' }}>
                            <input checked={fri} type="checkbox" onChange={() => { if (fri) setFri(false); else setFri(true) }} />
                            {fri && <text style={{ fontSize: 11 }}>{fri_open != '' ? 'Open ' : ''}</text>}
                            <input value={fri ? fri_open : 'Closed'} onChange={(e) => setFriOpen(e.currentTarget.value)} type='text' placeholder={fri ? 'Open, xx:xx am' : 'Closed'} style={{ outline: 'none', borderWidth: 0, borderStyle: 'solid', padding: 4, width: fri ? '45%' : '95%' }} />
                            {fri && <text style={{ fontSize: 11 }}>{fri_open != '' ? 'Close ' : ''}</text>}
                            <input value={fri_close} onChange={(e) => setFriClose(e.currentTarget.value)} type='text' placeholder={fri_open ? 'xx : xx am' : 'Close, xx:xx pm'} style={{ outline: 'none', borderWidth: 0, borderStyle: 'solid', padding: 4, width: fri ? '45%' : '0%' }} />
                        </div>

                        <text style={{ color: 'gray', fontWeight: '600', fontSize: 11, textAlign: 'left', marginTop: 10 }}>Saturday</text>
                        <div style={{ padding: 4, flexDirection: 'row', display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '96%', backgroundColor: '#f8f8f8', marginTop: 6, height: 42, width: '96%', borderRadius: 10, borderStyle: 'solid', borderWidth: 0.5, borderColor: 'lightgray' }}>
                            <input checked={sat} type="checkbox" onChange={() => { if (sat) setSat(false); else setSat(true) }} />
                            {sat && <text style={{ fontSize: 11 }}>{sat_open != '' ? 'Open ' : ''}</text>}
                            <input value={sat ? sat_open : 'Closed'} onChange={(e) => setSatOpen(e.currentTarget.value)} type='text' placeholder={sat ? 'Open, xx:xx am' : 'Closed'} style={{ outline: 'none', borderWidth: 0, borderStyle: 'solid', padding: 4, width: sat ? '45%' : '95%' }} />
                            {sat && <text style={{ fontSize: 11 }}>{sat_open != '' ? 'Close ' : ''}</text>}
                            <input value={sat_close} onChange={(e) => setSatClose(e.currentTarget.value)} type='text' placeholder={sat_open ? 'xx : xx am' : 'Close, xx:xx pm'} style={{ outline: 'none', borderWidth: 0, borderStyle: 'solid', padding: 4, width: sat ? '45%' : '0%' }} />
                        </div>

                        <text style={{ color: 'gray', fontWeight: '600', fontSize: 11, textAlign: 'left', marginTop: 10 }}>Sunday</text>
                        <div style={{ padding: 4, flexDirection: 'row', display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '96%', backgroundColor: '#f8f8f8', marginTop: 6, height: 42, width: '96%', borderRadius: 10, borderStyle: 'solid', borderWidth: 0.5, borderColor: 'lightgray' }}>
                            <input checked={sun} type="checkbox" onChange={() => { if (sun) setSun(false); else setSun(true) }} />
                            {sun && <text style={{ fontSize: 11 }}>{sun_open != '' ? 'Open ' : ''}</text>}
                            <input value={sun ? sun_open : 'Closed'} onChange={(e) => setSunOpen(e.currentTarget.value)} type='text' placeholder={sun ? 'Open, xx:xx am' : 'Closed'} style={{ outline: 'none', borderWidth: 0, borderStyle: 'solid', padding: 4, width: sun ? '45%' : '95%' }} />
                            {sun && <text style={{ fontSize: 11 }}>{sun_open != '' ? 'Close ' : ''}</text>}
                            <input value={sun_close} onChange={(e) => setSunClose(e.currentTarget.value)} type='text' placeholder={sun_open ? 'xx : xx am' : 'Close, xx:xx pm'} style={{ outline: 'none', borderWidth: 0, borderStyle: 'solid', padding: 4, width: sun ? '45%' : '0%' }} />
                        </div>

                    </div>
                </div>

                {(mail != null && mail == '') && <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', width: '90%', marginTop: 15 }}>
                    <text style={{ color: 'gray', fontWeight: '500', fontSize: 12, textAlign: 'left' }}>key words :</text>
                    <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', width: '99%', borderRadius: 2, borderWidth: 0.5, padding: 4, borderColor: 'lightgray', height: 40, marginTop: 6, backgroundColor: '#e9f8fb', }}>
                        <text style={{ color: 'black', fontSize: 13, fontWeight: '450' }}>These options are available after <text onClick={() => navigate('/Login')} style={{ cursor: 'pointer', color: '#52c6e3', cursor: 'pointer' }}>login</text>. {mail}</text>
                    </div>
                </div>}

                {mail != '' && mail != null && post != '' && defaultPrice != null && <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginTop: 10, width: '90%', justifyContent: 'space-between' }}>
                    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', width: '40%' }}>
                        <text style={{ color: 'gray', fontWeight: '500', fontSize: 12, textAlign: 'left' }}>Keywords : {disable ? <text style={{ fontSize: 12, fontWeight: '500', color: 'red' }}>Keyword is unavailable for this area.</text> : null}</text>
                        <input value={keyword} onChange={(e) => setkeyword(e.currentTarget.value)} type='text' placeholder=' Your keyword' style={{ backgroundColor: '#f8f8f8', marginTop: 4, height: 38, width: '96%', borderRadius: 10, outline: 'none', borderStyle: 'solid', borderWidth: 0.5, padding: 4, borderColor: (keyword != '' ? 'lightgray' : 'red') }} />
                    </div>

                    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', marginLeft: 40, width: '40%' }}>
                        <text style={{ color: 'gray', fontWeight: '500', fontSize: 12, textAlign: 'left', marginBottom: 4 }}>Advertisement Distance : </text>
                        <ReactDropdown
                            arrowClosed={<text></text>}
                            arrowOpen={<text></text>}
                            options={showDist}
                            onChange={(e) => {
                                let indx = 0;
                                for (let i = 0; i < showDist.length; i++) {
                                    if (showDist[i] == e.value) {
                                        indx = i;
                                        break;
                                    }
                                }
                                setDistanceIndex(indx)
                                // console.log(e.value)
                            }}
                            placeholder="Select advertisement distance"
                        />
                    </div>
                </div>}

                {(mail != null && mail != '' && post != '' && defaultPrice != null) && <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginTop: 10, width: '90%', justifyContent: 'space-between' }}>
                    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', width: '40%' }}>
                        <text style={{ color: 'gray', fontWeight: '500', fontSize: 12, textAlign: 'left', marginBottom: 4 }}>Price : </text>
                        <ReactDropdown
                            arrowClosed={<text></text>}
                            arrowOpen={<text></text>}
                            options={prices}
                            onChange={(e) => {
                                let nmb = (Number(String(e.value).substring(1, e.value.length)) / 2) + 1


                                const plac = Number(bst - Number(nmb) + 1);

                                setMyplace(plac);
                            }}
                            placeholder="Select price"
                        />
                    </div>

                    <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'flex-start', width: '40%', marginLeft: 40, }}>
                        {myplace != -1 && <text>{myplace == 1 ? '🥇' : myplace == 2 ? '🥈' : myplace == 3 ? '🥉' : '🥇'}</text>}
                        {myplace != -1 && <div style={{ width: '90%', borderRadius: 10, padding: 4, backgroundColor: field_background }}><text style={{ color: 'black' }}>{myplace} {myplace == 1 ? 'st' : myplace == 2 ? 'nd' : myplace == 3 ? 'rd' : 'th'} Place</text></div>}
                    </div>

                </div>}

                {loading && <div style={{ backgroundColor: 'white', padding: 10, alignSelf: 'center' }}><Levels size={35} /></div>}

                <div onClick={() => decideUpload()} style={{ cursor: 'pointer', width: 130, height: 30, padding: 10, marginBottom: 10, display: 'flex', justifyContent: 'center', backgroundColor: '#28b8dc', borderRadius: 4, alignSelf: 'center', marginTop: 6 }}>
                    <text style={{ color: 'white', fontSize: 12, fontWeight: '500' }}>Save new listing</text>
                </div>
            </div>}

            {isMobile && <div style={{ display: 'flex', overflowY: "hidden", alignItems: 'center', width: '90%', alignSelf: 'center', flexDirection: 'column', marginBottom: 30, marginTop: 15, justifyContent: 'center' }}>
                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginTop: 10, width: '95%', justifyContent: 'space-between', height: 130 }}>
                    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', width: '95%' }}>
                        <text style={{ color: 'gray', fontWeight: '500', fontSize: 12, textAlign: 'left' }}>Business name :</text>
                        <input value={business_name} onChange={(e) => setBusinessName(e.currentTarget.value)} type='text' placeholder=' Your Business Name' style={{ backgroundColor: '#f8f8f8', marginTop: 4, height: 38, width: '96%', borderRadius: 10, outline: 'none', borderStyle: 'solid', borderWidth: 0.5, padding: 4, borderColor: (business_name != '' ? 'lightgray' : 'red') }} />
                    </div>

                    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', width: '95%' }}>
                        <text style={{ color: 'gray', fontWeight: '500', fontSize: 12, textAlign: 'left' }}>Address1 : </text>
                        <input value={address1} onChange={(e) => setAddress1(e.currentTarget.value)} type='text' placeholder=' Your Address 1' style={{ backgroundColor: '#f8f8f8', marginTop: 4, height: 38, width: '96%', borderRadius: 10, outline: 'none', borderStyle: 'solid', borderWidth: 0.5, padding: 4, borderColor: (address1 != '' ? 'lightgray' : 'red') }} />
                    </div>
                </div>
                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginTop: 10, width: '95%', justifyContent: 'space-between', height: 130 }}>
                    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', width: '95%' }}>
                        <text style={{ color: 'gray', fontWeight: '500', fontSize: 12, textAlign: 'left' }}>Address2 :</text>
                        <input value={address2} onChange={(e) => setAddress2(e.currentTarget.value)} type='text' placeholder=' Your Address 2' style={{ backgroundColor: '#f8f8f8', marginTop: 4, height: 38, width: '96%', borderRadius: 10, outline: 'none', borderStyle: 'solid', borderWidth: 0.5, padding: 4, borderColor: (address2 != '' ? 'lightgray' : 'red') }} />
                    </div>

                    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', width: '95%' }}>
                        <text style={{ color: 'gray', fontWeight: '500', fontSize: 12, textAlign: 'left' }}>City : </text>
                        <input value={town} onChange={(e) => setTown(e.currentTarget.value)} type='text' placeholder=' Your City' style={{ backgroundColor: '#f8f8f8', marginTop: 4, height: 38, width: '96%', borderRadius: 10, outline: 'none', borderStyle: 'solid', borderWidth: 0.5, padding: 4, borderColor: (town != '' ? 'lightgray' : 'red') }} />
                    </div>
                </div>

                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginTop: 10, width: '95%', justifyContent: 'space-between', height: 130 }}>
                    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', width: '95%' }}>
                        <text style={{ color: 'gray', fontWeight: '500', fontSize: 12, textAlign: 'left' }}>Post Code :</text>
                        <input value={post} onChange={(e) => setPost(e.currentTarget.value)} type='text' placeholder=' Your post code' style={{ backgroundColor: '#f8f8f8', marginTop: 4, height: 38, width: '96%', borderRadius: 10, outline: 'none', borderStyle: 'solid', borderWidth: 0.5, padding: 4, borderColor: (post != '' ? 'lightgray' : 'red') }} />
                    </div>

                    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', width: '95%' }}>
                        <text style={{ color: 'gray', fontWeight: '500', fontSize: 12, textAlign: 'left' }}>Country : </text>
                        <input value={country} onChange={(e) => setCountry(e.currentTarget.value)} type='text' placeholder=' Your Country' style={{ backgroundColor: '#f8f8f8', marginTop: 4, height: 38, width: '96%', borderRadius: 10, outline: 'none', borderStyle: 'solid', borderWidth: 0.5, padding: 4, borderColor: (country != '' ? 'lightgray' : 'red') }} />
                    </div>
                </div>



                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginTop: 10, width: '95%', justifyContent: 'space-between', height: 60 }}>
                    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', width: '95%' }}>
                        <text style={{ color: 'gray', fontWeight: '500', fontSize: 12, textAlign: 'left' }}>Website Url :</text>
                        <input value={website} onChange={(e) => setWebsite(e.currentTarget.value)} type='text' placeholder=' Your Link' style={{ backgroundColor: '#f8f8f8', marginTop: 4, height: 38, width: '96%', borderRadius: 10, outline: 'none', borderStyle: 'solid', borderWidth: 0.5, padding: 4, borderColor: (website != '' ? 'lightgray' : 'red') }} />
                    </div>
                </div>

                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginTop: 10, width: '95%', justifyContent: 'space-between', height: 130 }}>
                    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', width: '95%' }}>
                        <text style={{ color: 'gray', fontWeight: '500', fontSize: 12, textAlign: 'left' }}>Email :</text>
                        <input value={email} onChange={(e) => setEmail(e.currentTarget.value)} type='text' placeholder=' Your email' style={{ backgroundColor: '#f8f8f8', marginTop: 4, height: 38, width: '96%', borderRadius: 10, outline: 'none', borderStyle: 'solid', borderWidth: 0.5, padding: 4, borderColor: (email != '' ? 'lightgray' : 'red') }} />
                    </div>

                    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', width: '95%' }}>
                        <text style={{ color: 'gray', fontWeight: '500', fontSize: 12, textAlign: 'left' }}>Phone number : </text>
                        <input value={phone} onChange={(e) => setPhone(e.currentTarget.value)} type='text' placeholder=' Your Number' style={{ backgroundColor: '#f8f8f8', marginTop: 4, height: 38, width: '96%', borderRadius: 10, outline: 'none', borderStyle: 'solid', borderWidth: 0.5, padding: 4, borderColor: (phone != '' ? 'lightgray' : 'red') }} />
                    </div>
                </div>

                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginTop: 10, width: '95%', justifyContent: 'space-between' }}>
                    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', width: '95%' }}>
                        <text style={{ color: 'gray', fontWeight: '500', fontSize: 12, textAlign: 'left' }}>Description :</text>
                        <textarea value={description} onChange={(e) => setDescription(e.currentTarget.value)} type='text' placeholder='Description' style={{ backgroundColor: '#f8f8f8', marginTop: 4, height: 158, width: '96%', borderRadius: 10, outline: 'none', borderStyle: 'solid', borderWidth: 0.5, padding: 4, borderColor: (description != '' ? 'lightgray' : 'red') }} />
                    </div>
                </div>

                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginTop: 10, width: '95%', justifyContent: 'space-between' }}>

                    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', width: '95%' }}>
                        <text style={{ color: 'gray', fontWeight: '500', fontSize: 12, textAlign: 'left' }}>Social media links : </text>
                        <div style={{ padding: 4, flexDirection: 'row', display: 'flex', alignItems: 'center', width: '96%', backgroundColor: '#f8f8f8', marginTop: 4, height: 42, width: '96%', borderRadius: 10, borderStyle: 'solid', borderWidth: 0.5, borderColor: 'lightgray' }}>
                            {facebook_input}
                            <input value={facebook_link} onChange={(e) => setfacebook_link(e.currentTarget.value)} type='text' placeholder='Facebook Link' style={{ outline: 'none', borderWidth: 0, borderStyle: 'solid', padding: 4, width: '85%' }} />
                        </div>
                        <div style={{ padding: 4, flexDirection: 'row', display: 'flex', alignItems: 'center', width: '96%', backgroundColor: '#f8f8f8', marginTop: 4, height: 42, width: '96%', borderRadius: 10, borderStyle: 'solid', borderWidth: 0.5, borderColor: 'lightgray' }}>
                            {youtube_input}
                            <input value={youtube_link} onChange={(e) => setyoutubelink(e.currentTarget.value)} type='text' placeholder='Youtube Link' style={{ outline: 'none', borderWidth: 0, borderStyle: 'solid', padding: 4, width: '85%' }} />
                        </div>

                        <div style={{ padding: 4, flexDirection: 'row', display: 'flex', alignItems: 'center', width: '96%', backgroundColor: '#f8f8f8', marginTop: 4, height: 42, width: '96%', borderRadius: 10, borderStyle: 'solid', borderWidth: 0.5, borderColor: 'lightgray' }}>
                            {tiktok_input}
                            <input value={tiktok_link} onChange={(e) => settiktok_link(e.currentTarget.value)} type='text' placeholder='Tiktok Link' style={{ outline: 'none', borderWidth: 0, borderStyle: 'solid', padding: 4, width: '85%' }} />
                        </div>
                        <div style={{ padding: 4, flexDirection: 'row', display: 'flex', alignItems: 'center', width: '96%', backgroundColor: '#f8f8f8', marginTop: 4, height: 42, width: '96%', borderRadius: 10, borderStyle: 'solid', borderWidth: 0.5, borderColor: 'lightgray' }}>
                            {twitter_input}
                            <input value={twitter_link} onChange={(e) => settwitter_link(e.currentTarget.value)} type='text' placeholder='Twitter Link' style={{ outline: 'none', borderWidth: 0, borderStyle: 'solid', padding: 4, width: '85%' }} />
                        </div>

                        <div style={{ padding: 4, flexDirection: 'row', display: 'flex', alignItems: 'center', width: '96%', backgroundColor: '#f8f8f8', marginTop: 4, height: 42, width: '96%', borderRadius: 10, borderStyle: 'solid', borderWidth: 0.5, borderColor: 'lightgray' }}>
                            {snap_input}
                            <input value={snap_link} onChange={(e) => setsnap_link(e.currentTarget.value)} type='text' placeholder='Snapchat Link' style={{ outline: 'none', borderWidth: 0, borderStyle: 'solid', padding: 4, width: '85%' }} />
                        </div>
                        <div style={{ padding: 4, flexDirection: 'row', display: 'flex', alignItems: 'center', width: '96%', backgroundColor: '#f8f8f8', marginTop: 4, height: 42, width: '96%', borderRadius: 10, borderStyle: 'solid', borderWidth: 0.5, borderColor: 'lightgray' }}>
                            {instagam_input}
                            <input value={instagram_link} onChange={(e) => setinstagram_link(e.currentTarget.value)} type='text' placeholder='Instagram Link' style={{ outline: 'none', borderWidth: 0, borderStyle: 'solid', padding: 4, width: '85%' }} />
                        </div>

                        <div style={{ padding: 4, flexDirection: 'row', display: 'flex', alignItems: 'center', width: '96%', backgroundColor: '#f8f8f8', marginTop: 4, height: 42, width: '96%', borderRadius: 10, borderStyle: 'solid', borderWidth: 0.5, borderColor: 'lightgray' }}>
                            {whatsapp_input}
                            <input value={whatsapp_link} onChange={(e) => setwhatsapp_link(e.currentTarget.value)} type='text' placeholder='Whatsapp number' style={{ outline: 'none', borderWidth: 0, borderStyle: 'solid', padding: 4, width: '85%' }} />
                        </div>
                        <div style={{ padding: 4, flexDirection: 'row', display: 'flex', alignItems: 'center', width: '96%', backgroundColor: '#f8f8f8', marginTop: 4, height: 42, width: '96%', borderRadius: 10, borderStyle: 'solid', borderWidth: 0.5, borderColor: 'lightgray' }}>
                            {sound_input}
                            <input value={sound_link} onChange={(e) => setsound_link(e.currentTarget.value)} type='text' placeholder='Sound cloud link' style={{ outline: 'none', borderWidth: 0, borderStyle: 'solid', padding: 4, width: '85%' }} />
                        </div>
                    </div>


                </div>

                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginTop: 10, width: '95%', justifyContent: 'space-between' }}>

                    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', width: '95%' }}>
                        <text style={{ color: 'gray', fontWeight: '500', fontSize: 12, textAlign: 'left' }}>Opening & closing : </text>


                        <text style={{ color: 'gray', fontWeight: '600', fontSize: 11, textAlign: 'left', marginTop: 10 }}>Monday</text>
                        <div style={{ padding: 4, flexDirection: 'row', display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '96%', backgroundColor: '#f8f8f8', marginTop: 6, height: 42, width: '96%', borderRadius: 10, borderStyle: 'solid', borderWidth: 0.5, borderColor: 'lightgray' }}>
                            <input checked={mon} type="checkbox" onChange={() => { if (mon) setMon(false); else setMon(true) }} />
                            {mon && <text style={{ fontSize: 11 }}>{mon_open != '' ? 'Open ' : ''}</text>}
                            <input value={mon ? mon_open : 'Closed'} onChange={(e) => setMonOpen(e.currentTarget.value)} type='text' placeholder={mon ? 'Open, xx:xx am' : 'Closed'} style={{ outline: 'none', borderWidth: 0, borderStyle: 'solid', padding: 4, width: mon ? '45%' : '95%' }} />
                            {mon && <text style={{ fontSize: 11 }}>{mon_open != '' ? 'Close ' : ''}</text>}
                            <input value={mon_close} onChange={(e) => setMonClose(e.currentTarget.value)} type='text' placeholder={mon_open ? 'xx : xx am' : 'Close, xx:xx pm'} style={{ outline: 'none', borderWidth: 0, borderStyle: 'solid', padding: 4, width: mon ? '45%' : '0%' }} />
                        </div>

                        <text style={{ color: 'gray', fontWeight: '600', fontSize: 11, textAlign: 'left', marginTop: 10 }}>Tuesday</text>
                        <div style={{ padding: 4, flexDirection: 'row', display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '96%', backgroundColor: '#f8f8f8', marginTop: 6, height: 42, width: '96%', borderRadius: 10, borderStyle: 'solid', borderWidth: 0.5, borderColor: 'lightgray' }}>
                            <input checked={tues} type="checkbox" onChange={() => { if (tues) setTues(false); else setTues(true) }} />
                            {tues && <text style={{ fontSize: 11 }}>{tues_open != '' ? 'Open ' : ''}</text>}
                            <input value={tues ? tues_open : 'Closed'} onChange={(e) => setTuesOpen(e.currentTarget.value)} type='text' placeholder={tues ? 'Open, xx:xx am' : 'Closed'} style={{ outline: 'none', borderWidth: 0, borderStyle: 'solid', padding: 4, width: tues ? '45%' : '95%' }} />
                            {tues && <text style={{ fontSize: 11 }}>{tues_open != '' ? 'Close ' : ''}</text>}
                            <input value={tues_close} onChange={(e) => setTuesClose(e.currentTarget.value)} type='text' placeholder={tues_open ? 'xx : xx am' : 'Close, xx:xx pm'} style={{ outline: 'none', borderWidth: 0, borderStyle: 'solid', padding: 4, width: tues ? '45%' : '0%' }} />
                        </div>

                        <text style={{ color: 'gray', fontWeight: '600', fontSize: 11, textAlign: 'left', marginTop: 10 }}>Wednesday</text>
                        <div style={{ padding: 4, flexDirection: 'row', display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '96%', backgroundColor: '#f8f8f8', marginTop: 6, height: 42, width: '96%', borderRadius: 10, borderStyle: 'solid', borderWidth: 0.5, borderColor: 'lightgray' }}>
                            <input checked={wed} type="checkbox" onChange={() => { if (wed) setWed(false); else setWed(true) }} />
                            {wed && <text style={{ fontSize: 11 }}>{wed_open != '' ? 'Open ' : ''}</text>}
                            <input value={wed ? wed_open : 'Closed'} onChange={(e) => setWedOpen(e.currentTarget.value)} type='text' placeholder={wed ? 'Open, xx:xx am' : 'Closed'} style={{ outline: 'none', borderWidth: 0, borderStyle: 'solid', padding: 4, width: wed ? '45%' : '95%' }} />
                            {wed && <text style={{ fontSize: 11 }}>{wed_open != '' ? 'Close ' : ''}</text>}
                            <input value={wed_close} onChange={(e) => setWedClose(e.currentTarget.value)} type='text' placeholder={wed_open ? 'xx : xx am' : 'Close, xx:xx pm'} style={{ outline: 'none', borderWidth: 0, borderStyle: 'solid', padding: 4, width: wed ? '45%' : '0%' }} />
                        </div>

                        <text style={{ color: 'gray', fontWeight: '600', fontSize: 11, textAlign: 'left', marginTop: 10 }}>Thursday</text>
                        <div style={{ padding: 4, flexDirection: 'row', display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '96%', backgroundColor: '#f8f8f8', marginTop: 6, height: 42, width: '96%', borderRadius: 10, borderStyle: 'solid', borderWidth: 0.5, borderColor: 'lightgray' }}>
                            <input checked={thur} type="checkbox" onChange={() => { if (thur) setThur(false); else setThur(true) }} />
                            {thur && <text style={{ fontSize: 11 }}>{thur_open != '' ? 'Open ' : ''}</text>}
                            <input value={thur ? thur_open : 'Closed'} onChange={(e) => setThurOpen(e.currentTarget.value)} type='text' placeholder={thur ? 'Open, xx:xx am' : 'Closed'} style={{ outline: 'none', borderWidth: 0, borderStyle: 'solid', padding: 4, width: thur ? '45%' : '95%' }} />
                            {thur && <text style={{ fontSize: 11 }}>{thur_open != '' ? 'Close ' : ''}</text>}
                            <input value={thur_close} onChange={(e) => setThurClose(e.currentTarget.value)} type='text' placeholder={thur_open ? 'xx : xx am' : 'Close, xx:xx pm'} style={{ outline: 'none', borderWidth: 0, borderStyle: 'solid', padding: 4, width: thur ? '45%' : '0%' }} />
                        </div>

                        <text style={{ color: 'gray', fontWeight: '600', fontSize: 11, textAlign: 'left', marginTop: 10 }}>Friday</text>
                        <div style={{ padding: 4, flexDirection: 'row', display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '96%', backgroundColor: '#f8f8f8', marginTop: 6, height: 42, width: '96%', borderRadius: 10, borderStyle: 'solid', borderWidth: 0.5, borderColor: 'lightgray' }}>
                            <input checked={fri} type="checkbox" onChange={() => { if (fri) setFri(false); else setFri(true) }} />
                            {fri && <text style={{ fontSize: 11 }}>{fri_open != '' ? 'Open ' : ''}</text>}
                            <input value={fri ? fri_open : 'Closed'} onChange={(e) => setFriOpen(e.currentTarget.value)} type='text' placeholder={fri ? 'Open, xx:xx am' : 'Closed'} style={{ outline: 'none', borderWidth: 0, borderStyle: 'solid', padding: 4, width: fri ? '45%' : '95%' }} />
                            {fri && <text style={{ fontSize: 11 }}>{fri_open != '' ? 'Close ' : ''}</text>}
                            <input value={fri_close} onChange={(e) => setFriClose(e.currentTarget.value)} type='text' placeholder={fri_open ? 'xx : xx am' : 'Close, xx:xx pm'} style={{ outline: 'none', borderWidth: 0, borderStyle: 'solid', padding: 4, width: fri ? '45%' : '0%' }} />
                        </div>

                        <text style={{ color: 'gray', fontWeight: '600', fontSize: 11, textAlign: 'left', marginTop: 10 }}>Saturday</text>
                        <div style={{ padding: 4, flexDirection: 'row', display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '96%', backgroundColor: '#f8f8f8', marginTop: 6, height: 42, width: '96%', borderRadius: 10, borderStyle: 'solid', borderWidth: 0.5, borderColor: 'lightgray' }}>
                            <input checked={sat} type="checkbox" onChange={() => { if (sat) setSat(false); else setSat(true) }} />
                            {sat && <text style={{ fontSize: 11 }}>{sat_open != '' ? 'Open ' : ''}</text>}
                            <input value={sat ? sat_open : 'Closed'} onChange={(e) => setSatOpen(e.currentTarget.value)} type='text' placeholder={sat ? 'Open, xx:xx am' : 'Closed'} style={{ outline: 'none', borderWidth: 0, borderStyle: 'solid', padding: 4, width: sat ? '45%' : '95%' }} />
                            {sat && <text style={{ fontSize: 11 }}>{sat_open != '' ? 'Close ' : ''}</text>}
                            <input value={sat_close} onChange={(e) => setSatClose(e.currentTarget.value)} type='text' placeholder={sat_open ? 'xx : xx am' : 'Close, xx:xx pm'} style={{ outline: 'none', borderWidth: 0, borderStyle: 'solid', padding: 4, width: sat ? '45%' : '0%' }} />
                        </div>

                        <text style={{ color: 'gray', fontWeight: '600', fontSize: 11, textAlign: 'left', marginTop: 10 }}>Sunday</text>
                        <div style={{ padding: 4, flexDirection: 'row', display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '96%', backgroundColor: '#f8f8f8', marginTop: 6, height: 42, width: '96%', borderRadius: 10, borderStyle: 'solid', borderWidth: 0.5, borderColor: 'lightgray' }}>
                            <input checked={sun} type="checkbox" onChange={() => { if (sun) setSun(false); else setSun(true) }} />
                            {sun && <text style={{ fontSize: 11 }}>{sun_open != '' ? 'Open ' : ''}</text>}
                            <input value={sun ? sun_open : 'Closed'} onChange={(e) => setSunOpen(e.currentTarget.value)} type='text' placeholder={sun ? 'Open, xx:xx am' : 'Closed'} style={{ outline: 'none', borderWidth: 0, borderStyle: 'solid', padding: 4, width: sun ? '45%' : '95%' }} />
                            {sun && <text style={{ fontSize: 11 }}>{sun_open != '' ? 'Close ' : ''}</text>}
                            <input value={sun_close} onChange={(e) => setSunClose(e.currentTarget.value)} type='text' placeholder={sun_open ? 'xx : xx am' : 'Close, xx:xx pm'} style={{ outline: 'none', borderWidth: 0, borderStyle: 'solid', padding: 4, width: sun ? '45%' : '0%' }} />
                        </div>

                    </div>


                </div>

                {(mail != null && mail == '') && <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', width: '95%', marginTop: 15, height: 130 }}>
                    <text style={{ color: 'gray', fontWeight: '500', fontSize: 12, textAlign: 'left' }}>key words :</text>
                    <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', width: '99%', borderRadius: 2, borderWidth: 0.5, padding: 4, borderColor: 'lightgray', height: 40, marginTop: 6, backgroundColor: '#e9f8fb', }}>
                        <text style={{ color: 'black', fontSize: 13, fontWeight: '450' }}>These options are available after <text onClick={() => navigate('/Login')} style={{ color: '#52c6e3', cursor: 'pointer' }}>login</text>. {mail}</text>
                    </div>
                </div>}

                {mail != '' && mail != null && post != '' && defaultPrice != null && <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginTop: 10, width: '95%', justifyContent: 'space-between', height: 130 }}>
                    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', width: '95%' }}>
                        <text style={{ color: 'gray', fontWeight: '500', fontSize: 12, textAlign: 'left' }}>Keywords : {disable ? <text style={{ fontSize: 12, fontWeight: '500', color: 'red' }}>Keyword is unavailable for this area.</text> : null}</text>
                        <input value={keyword} onChange={(e) => setkeyword(e.currentTarget.value)} type='text' placeholder=' Your keyword' style={{ backgroundColor: '#f8f8f8', marginTop: 4, height: 38, width: '96%', borderRadius: 10, outline: 'none', borderStyle: 'solid', borderWidth: 0.5, padding: 4, borderColor: (keyword != '' ? 'lightgray' : 'red') }} />
                    </div>

                    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', width: '95%' }}>
                        <text style={{ color: 'gray', fontWeight: '500', fontSize: 12, textAlign: 'left', marginBottom: 4 }}>Advertisement Distance : </text>
                        <ReactDropdown
                            arrowClosed={<text></text>}
                            arrowOpen={<text></text>}
                            options={showDist}
                            onChange={(e) => {
                                let indx = 0;
                                for (let i = 0; i < showDist.length; i++) {
                                    if (showDist[i] == e.value) {
                                        indx = i;
                                        break;
                                    }
                                }
                                setDistanceIndex(indx)
                                // console.log(e.value)
                            }}
                            placeholder="Select advertisement distance"
                        />
                    </div>
                </div>}

                {(mail != null && mail != '' && post != '' && defaultPrice != null) && <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginTop: 10, width: '95%', justifyContent: 'space-between', height: 130 }}>
                    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', width: '95%' }}>
                        <text style={{ color: 'gray', fontWeight: '500', fontSize: 12, textAlign: 'left', marginBottom: 4 }}>Price : </text>
                        <ReactDropdown
                            arrowClosed={<text></text>}
                            arrowOpen={<text></text>}
                            options={prices}
                            onChange={(e) => {
                                let nmb = (Number(String(e.value).substring(1, e.value.length)) / 2) + 1

                                const plac = Number(bst - Number(nmb) + 1);

                                setMyplace(plac);
                            }}
                            placeholder="  Select price   "
                        />
                    </div>
                    <div hidden={myplace == -1} style={{ display: 'flex', flexDirection: 'row', alignItems: 'flex-start', width: '95%' }}>
                        {myplace != -1 && <text>{myplace == 1 ? '🥇' : myplace == 2 ? '🥈' : myplace == 3 ? '🥉' : '🥇'}</text>}
                        {myplace != -1 && <div style={{ width: '90%', borderRadius: 10, padding: 4, backgroundColor: field_background }}><text style={{ color: 'black' }}>{myplace} {myplace == 1 ? 'st' : myplace == 2 ? 'nd' : myplace == 3 ? 'rd' : 'th'} Place</text></div>}
                    </div>

                </div>}

                {loading && <div style={{ backgroundColor: 'white', padding: 10, alignSelf: 'center' }}><Levels size={35} /></div>}

                <div onClick={() => decideUpload()} style={{ cursor: 'pointer', width: 150, height: 50, alignItems: 'center', padding: 10, marginBottom: 10, display: 'flex', justifyContent: 'center', backgroundColor: '#28b8dc', borderRadius: 4, alignSelf: 'center', marginTop: 10 }}>
                    <text style={{ color: 'white', fontSize: 12, fontWeight: '500' }}>Save new listing</text>
                </div>
            </div>}

            <div style={{ marginTop: 30, alignSelf: 'center', display: 'flex', width: width, backgroundColor: '#28b8dc', height: 200, alignItems: 'center', justifyContent: 'center' }}>
                <div style={{ width: '90%', alignSelf: 'center', display: 'flex', flexDirection: 'row', height: 180, alignItems: 'center', justifyContent: 'space-between' }}>
                    <div style={{ flexDirection: 'column', display: 'flex', alignItems: 'flex-start', width: '40%', justifyContent: 'center' }}>
                        <img src={require('../nwlb.jpg')} style={{ height: 60, width: 60, borderRadius: 30, objectFit: 'contain', marginBottom: 6 }} />
                        <text style={{ color: 'white', fontSize: 12, fontWeight: '300', textAlign: 'left' }}>
                            Download our app North West Local Business - Easily find the services and products you need while enjoying
                            exclusive features not available on our website.
                        </text>
                    </div>
                    <div style={{ flexDirection: 'column', display: 'flex', alignItems: 'flex-start', width: '14%' }}>
                        <text style={{ fontSize: 14, color: 'white', fontWeight: '800' }}>OUR LOCATION</text>
                        <text style={{ color: 'white', fontSize: 12, fontWeight: '300', marginTop: 6 }}>
                            Northwest, UK
                        </text>
                    </div>
                    <div style={{ flexDirection: 'column', display: 'flex', alignItems: 'flex-start', width: '14%' }}>
                        <text style={{ fontSize: 14, color: 'white', fontWeight: '800' }}>CONTACT US</text>
                        <text style={{ color: 'white', fontSize: 12, fontWeight: '300', marginTop: 6 }}>
                            Tel: 0800 9750341
                        </text>
                    </div>
                </div>
            </div>

            <div style={{ backgroundColor: '#089ec4', width: width, display: 'flex', alignItems: 'center', height: 60, alignSelf: 'center', justifyContent: 'center' }}>
                <div style={{ width: isMobile ? '95%' : '90%', alignSelf: 'center', height: 50, display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between' }}>

                    <div style={{ width: '40%', display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between' }}>
                        <text onClick={() => navigate('/Condition')} style={{ cursor: 'pointer', color: 'white', fontWeight: '400', fontSize: 13, textDecorationLine: 'underline', textDecorationColor: 'white' }}>Terms & Conditions</text>
                        <text style={{ color: 'white', fontWeight: '400', fontSize: 13, textDecorationLine: 'underline', textDecorationColor: 'white' }}>Cookie Policy</text>
                        <text onClick={() => navigate('/Term')} style={{ cursor: 'pointer', color: 'white', fontWeight: '400', fontSize: 13, textDecorationLine: 'underline', textDecorationColor: 'white' }}>Privacy Policy</text>
                    </div>

                    <div style={{ width: isMobile ? '50%' : '25%', display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-around' }}>
                        <div style={{ display: 'flex', alignContent: 'center', alignItems: 'center', backgroundColor: '#28b8dc', height: 40, width: 40, borderRadius: 20, justifyContent: 'center' }}>
                            {instagram_icon}
                        </div>
                        <div style={{ display: 'flex', alignContent: 'center', alignItems: 'center', backgroundColor: '#28b8dc', height: 40, width: 40, borderRadius: 20, justifyContent: 'center' }}>
                            {twitter_icon}
                        </div>
                        <div style={{ display: 'flex', alignContent: 'center', alignItems: 'center', backgroundColor: '#28b8dc', height: 40, width: 40, borderRadius: 20, justifyContent: 'center' }}>
                            {facebook_icon}
                        </div>
                        <div style={{ display: 'flex', alignContent: 'center', alignItems: 'center', backgroundColor: '#28b8dc', height: 40, width: 40, borderRadius: 20, justifyContent: 'center' }}>
                            {instagram_icon}
                        </div>
                        <div style={{ display: 'flex', alignContent: 'center', alignItems: 'center', backgroundColor: '#28b8dc', height: 40, width: 40, borderRadius: 20, justifyContent: 'center' }}>
                            {instagram_icon}
                        </div>
                    </div>

                </div>
            </div>


        </div>
    );
}

export default AddListing;