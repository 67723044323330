import React, { useState, useEffect, useRef } from 'react';
import { calendar_icon, facebook_icon, instagram_icon, left_curve, plus_icon, right_curve, right_icon, search_icon, tiktok_icon, twitter_icon, user_icon, youtube_icon } from './icons';
import mobile from './assets/left_image.png'
import available from './assets/available.png'
import join from './assets/join.png'
import join_picture from './assets/join_picture.png'
import one from './assets/one.png'
import two from './assets/two.png'
import three from './assets/three.png'
import { db } from '../config';
import { useNavigate, useLocation } from 'react-router-dom';
import { toast } from 'wc-toast';
import ReactDropdown from 'react-dropdown';
import 'react-dropdown/style.css';

const news_db = db.ref('news_db')

const months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December']

function Dashboard() {

    const navigate = useNavigate();

    let path = window.location.href;

    const { state } = useLocation();

    if (path.includes('?')) {
        if (state && state.single != undefined) { }
        else navigate('/SingleProduct')
    }

    const [width, setWidth] = useState(window.innerWidth);
    const [height, setHeight] = useState(window.innerHeight);

    // , { state: { phone: myphn } }

    const [search, setSearch] = useState('')
    const [post, setPost] = useState('')

    const [news, setNews] = useState(null)
    const [start, setStart] = useState(0)

    const [isopen, setIsOpen] = useState(false)

    useEffect(() => {
        const keyDownHandler = event => {
            console.log('User pressed: ', event.key);

            if (event.key === 'Enter') {
                // event.preventDefault();
                go_to_search()
            }
        };

        document.addEventListener('keydown', keyDownHandler);

        return () => {
            document.removeEventListener('keydown', keyDownHandler);
        };
    }, [post, search, isopen]);

    function gettingNews() {
        //console.log('News Getting ', 'starting')
        news_db.limitToLast(50).once('value').then((snapshot) => {
            if (snapshot.val() != null) {
                let arr = Object.values(snapshot.val())
                for (let i = 0; i < arr.length; i++) {
                    arr[i].date = new Date(arr[i].date).getTime()
                }
                arr.sort(function (a, b) { return b.date - a.date });
                setNews([...arr])
            }
        })
    }

    useEffect(() => {
        if (news == null) {
            gettingNews()
        }
    })

    const isMobile = width <= 768;

    function handleWindowSizeChange() {
        setWidth(window.innerWidth);
        setHeight(window.innerHeight);
    }

    useEffect(() => {
        window.addEventListener('resize', handleWindowSizeChange);
        return () => {
            window.removeEventListener('resize', handleWindowSizeChange);
        }
    }, []);

    function make_left() {
        if (start > 0) setStart(start - 1)
    }

    function make_right() {
        if (start < news.length - 3) setStart(start + 1)
    }

    function make_right_mobile() {
        if (start < news.length - 1) setStart(start + 1)
    }

    function short_brief(txt) {
        let str = '';
        for (let i = 0; i < txt.length && i < 50; i++) {
            str += txt[i]
        }
        return str;
    }

    function times(dat) {
        let time = new Date(dat)
        let mnth = months[time.getMonth()]
        let day = time.getDate()
        let year = time.getFullYear()
        return mnth + " " + day + " , " + year
    }

    function go_to_search() {
        console.log("Searching ", post, search)
        if (post != '' && search != '') navigate('/SearchList', { state: { post_code: post, search_word: search, isAble: isopen } })
        else toast('Fill up search and post code .')
    }

    // ☰ Menu 

    const openInNewTab = (url) => {
        window.open(url, "_blank", "noreferrer");
    };


    return (
        <div style={{ display: 'flex', flexDirection: 'column', width: width }}>

            <wc-toast></wc-toast>


            <div style={{ alignItems: 'center', width: '90%', display: 'flex', flexDirection: 'row', alignSelf: 'center', height: 80, justifyContent: 'space-between' }}>
                <div style={{ width: '25%', display: 'flex', justifyContent: 'flex-start' }}>
                    <img src={require('../nwlb.jpg')} style={{ height: 60, width: 60, borderRadius: 30, objectFit: 'contain' }} />
                </div>
                <div style={{ width: isMobile ? '40%' : '50%', display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-evenly' }}>
                    {isMobile && <ReactDropdown
                        arrowClosed={<text></text>}
                        arrowOpen={<text></text>}
                        options={['Favourite', 'Learn', 'Join', 'Contact us', 'Profile', 'Add Listing']}
                        onChange={(e) => {
                            let command = e.value;
                            if (command.includes('Favourite')) {
                                navigate('/Favourite')
                            }
                            else if (command.includes("Contact")) {
                                navigate('/Contact')
                            }
                            else if (command.includes("Profile")) {
                                navigate('/Login')
                            }
                            else if (command.includes("Add")) {
                                navigate('/AddListing')
                            }
                            else if (command.includes("Join")) {
                                navigate('/Join')
                            }
                        }}
                        placeholder="     Menu     "
                    />}
                    {!isMobile && <text onClick={() => navigate('/Favourite')} style={{ cursor: 'pointer', fontSize: 16, color: 'black', fontWeight: '500' }}>Favourite</text>}
                    {!isMobile && <text style={{ fontSize: 16, color: 'black', fontWeight: '500' }}>Learn</text>}
                    {!isMobile && <text onClick={() => navigate('/Join')} style={{ fontSize: 16, color: 'black', fontWeight: '500' }}>Join</text>}
                    {!isMobile && <text onClick={() => navigate('/Contact')} style={{ cursor: 'pointer', fontSize: 16, color: 'black', fontWeight: '500' }}>Contact us</text>}
                    {!isMobile && <div onClick={() => navigate('/Login')} style={{ cursor: 'pointer', display: 'flex', alignContent: 'center', alignItems: 'center', backgroundColor: '#28b8dc', height: 35, width: 35, borderRadius: 4, justifyContent: 'center' }}>
                        {user_icon}
                    </div>}
                    {!isMobile && <div onClick={() => navigate('/AddListing')} style={{ cursor: 'pointer', display: 'flex', alignContent: 'center', flexDirection: 'row', alignItems: 'center', backgroundColor: '#28b8dc', height: 35, width: 130, borderRadius: 4, justifyContent: 'center' }}>
                        {plus_icon}
                        <text style={{ marginLeft: 4, color: 'white', fontSize: 12 }}>Add Listing</text>
                    </div>}
                </div>
            </div>

            <div style={{ display: 'flex', flexDirection: 'column', marginTop: 40 }}>
                <text style={{ color: '#28b8dc', textAlign: 'center', fontSize: 39, fontWeight: '700' }}>North West Local Business</text>
                <text style={{ color: 'black', textAlign: 'center', fontSize: 15, marginTop: 7 }}>Easily find local business based in the North West !</text>
                {!isMobile && <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', alignSelf: 'center', width: 600, justifyContent: isMobile ? 'space-around' : 'space-between', marginTop: 14, marginBottom: 10 }}>
                    <input type='text' onChange={(e) => setSearch(e.currentTarget.value)} placeholder='    Search NWLB' style={{ height: 40, width: 270, borderRadius: 10, outline: 'none', borderStyle: 'solid', borderWidth: 0.5, padding: 4, borderColor: 'lightgray' }} />
                    <input type='text' onChange={(e) => setPost(e.currentTarget.value)} placeholder='  Postcode' style={{ height: 40, width: 120, borderRadius: 10, outline: 'none', borderStyle: 'solid', borderWidth: 0.5, padding: 4, borderColor: 'lightgray' }} />
                    <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                        <text style={{ marginRight: 4, color: '#28b8dc' }}> Open ? </text>
                        <input value={isopen} type="checkbox" onChange={(e) => setIsOpen(e.target.checked)} />
                    </div>
                    <div onClick={() => go_to_search()} style={{ cursor: 'pointer', display: 'flex', alignContent: 'center', alignItems: 'center', backgroundColor: '#28b8dc', height: 40, width: 40, borderRadius: 4, justifyContent: 'center' }}>
                        {search_icon}
                    </div>

                </div>}
                {isMobile && <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', alignSelf: 'center', width: '96%', justifyContent: isMobile ? 'space-around' : 'space-between', marginTop: 14, marginBottom: 10 }}>
                    <input type='text' onChange={(e) => setSearch(e.currentTarget.value)} placeholder='    Search NWLB' style={{ height: 40, width: '37%', borderRadius: 10, outline: 'none', borderStyle: 'solid', borderWidth: 0.5, padding: 4, borderColor: 'lightgray' }} />
                    <input type='text' onChange={(e) => setPost(e.currentTarget.value)} placeholder='  Postcode' style={{ height: 40, width: '30%', borderRadius: 10, outline: 'none', borderStyle: 'solid', borderWidth: 0.5, padding: 4, borderColor: 'lightgray' }} />
                    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                        <text style={{ color: '#28b8dc' }}> Open ? </text>
                        <input value={isopen} type="checkbox" onChange={(e) => setIsOpen(e.target.checked)} />
                    </div>
                    <div onClick={() => go_to_search()} style={{ cursor: 'pointer', display: 'flex', alignContent: 'center', alignItems: 'center', backgroundColor: '#28b8dc', height: 40, width: 40, borderRadius: 4, justifyContent: 'center' }}>
                        {search_icon}
                    </div>

                </div>}
                <text style={{ color: 'black', textAlign: 'center', fontSize: 13, marginTop: 7, fontStyle: 'inherit', fontWeight: '600' }}>
                    WIN MONTHLY CASH PRIZES COMING SOON....
                </text>
            </div>

            <div style={{ alignItems: 'center', width: '95%', display: 'flex', flexDirection: 'row', alignSelf: 'center', height: 500, marginTop: 40 }}>
                <img src={mobile} style={{ height: 480, width: '40%', objectFit: 'contain' }} />
                <img src={available} style={{ height: 480, width: '58%', objectFit: 'contain' }} />
            </div>

            <text style={{ color: 'black', fontSize: 30, textAlign: 'center', fontWeight: 'bold' }}>How it <text style={{ color: '#089ec4', fontSize: 30 }}>works</text></text>
            <text style={{ marginTop: 8, color: 'black', textAlign: 'center', fontSize: 16, fontWeight: '300' }}>See why North West Local Business is one of your best friends for exploring the city</text>

            <div style={{ alignItems: 'center', width: '95%', display: 'flex', flexDirection: 'row', alignSelf: 'center', height: 330, marginTop: 40, justifyContent: 'space-evenly' }}>
                <img src={one} style={{ height: 260, width: '30%', objectFit: 'contain' }} />
                <img src={two} style={{ height: 260, width: '30%', objectFit: 'contain' }} />
                <img src={three} style={{ height: 260, width: '30%', objectFit: 'contain' }} />
            </div>

            <div style={{ backgroundColor: '#e9f8fb', alignItems: 'center', width: '99%', display: 'flex', flexDirection: 'row', alignSelf: 'center', height: 380, marginTop: 40 }}>
                <img src={join_picture} style={{ height: 310, width: isMobile ? '45%' : '40%', objectFit: 'contain' }} />
                <div style={{ width: '50%', display: 'flex', alignItems: 'center', flexDirection: 'column' }}>
                    <text style={{ fontSize: 25, fontWeight: '700', }}>Come Join Us & Get</text>
                    <text style={{ fontSize: 25, fontWeight: '900', color: '#089ec4' }}>Business Exposure</text>
                    <text style={{ marginTop: 8, color: 'black', textAlign: 'center', fontSize: 16, fontWeight: '300' }}>We have several plans available to suit your business needs</text>
                    <div onClick={() => navigate('/AddListing')} style={{ cursor: 'pointer', borderRadius: 8, padding: 10, alignItems: 'center', backgroundColor: '#089ec4', display: 'flex', justifyContent: 'center', marginTop: 20, flexDirection: 'row' }}>
                        <text style={{ fontWeight: '300', fontSize: 13, alignSelf: 'center', color: 'white' }}>CHOOSE YOUR PLAN </text>
                        {right_icon}
                    </div>
                </div>
            </div>

            <div style={{ backgroundColor: '#e9f8fb', borderRadius: 6, alignItems: 'center', width: isMobile ? '96%' : '80%', display: 'flex', flexDirection: 'row', alignSelf: 'center', height: 180, marginTop: 40, marginBottom: 20 }}>
                <img src={join} style={{ height: 180, width: '60%', objectFit: 'fill' }} />
                <div style={{ width: '50%', display: 'flex', alignItems: 'center', flexDirection: 'column' }}>
                    <div onClick={() => navigate('/AddListing')} style={{ cursor: 'pointer', borderRadius: 8, padding: 10, alignItems: 'center', backgroundColor: '#089ec4', display: 'flex', justifyContent: 'center', flexDirection: 'row' }}>
                        <text style={{ fontWeight: '300', fontSize: 13, alignSelf: 'center', color: 'white' }}>VIEW PLANS AND GET STARTED </text>
                        {right_icon}
                    </div>
                </div>
            </div>

            <text style={{ color: 'black', fontSize: 32, textAlign: 'center', fontWeight: 'bold' }}>News & <text style={{ color: '#089ec4', fontSize: 32 }}>Entertainment</text></text>
            <text style={{ marginTop: 8, color: 'black', textAlign: 'center', fontSize: 16, fontWeight: '300' }}>See why North West Local Business is one of your best friends for exploring the city</text>

            {news != null && !isMobile && <div style={{ alignItems: 'center', width: isMobile ? '98%' : '85%', display: 'flex', flexDirection: 'row', alignSelf: 'center', height: 380, marginTop: 40, justifyContent: 'space-evenly' }}>

                <div onClick={() => make_left()} style={{ cursor: 'pointer', width: 44, boxShadow: `1px 1px 1px gray`, height: 44, borderRadius: 22, backgroundColor: '#089ec4', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    {left_curve}
                </div>

                <div onClick={() => navigate('/News', { state: { item: news[start] } })} style={{ cursor: 'pointer', display: 'flex', flexDirection: 'column', width: 240, boxShadow: `1px 1px 1px gray`, padding: 6, borderWidth: 0.3, borderStyle: 'solid', borderColor: 'lightgray', height: 365, borderRadius: 8, backgroundColor: 'white' }}>
                    <div style={{ borderRadius: 6, height: 290, width: '99%', opacity: 0.8, backgroundPosition: 'center', backgroundSize: 'fill', backgroundRepeat: 'no-repeat', backgroundImage: `url(${news[start].uri})`, alignSelf: 'center' }}>
                        <div style={{ width: '100%', height: '100%', display: 'flex', flexDirection: 'column' }}>
                            <div style={{ height: '60%', width: '100%' }} />
                            <div style={{ opacity: 0.7, backgroundColor: '#366ab6', width: '100%', display: 'flex', flexDirection: 'column', height: '40%', borderBottomLeftRadius: 6, borderBottomRightRadius: 6, justifyContent: 'center' }}>
                                <text style={{ color: 'white', fontSize: 18, opacity: 1, fontWeight: '800', textAlign: 'center' }}>{news[start].title}</text>
                            </div>
                        </div>
                    </div>
                    <text style={{ color: 'black', fontWeight: '500', marginTop: 6 }}>{short_brief(news[start].news)} ..</text>
                    <div style={{ display: 'flex', alignItems: 'center', flexDirection: 'row', marginTop: 4 }}>
                        {calendar_icon}
                        <text style={{ color: 'black', fontWeight: '400', fontSize: 12, marginLeft: 10 }}>{times(news[start].date)}</text>
                    </div>
                </div>

                <div onClick={() => navigate('/News', { state: { item: news[start + 1] } })} style={{ cursor: 'pointer', display: 'flex', flexDirection: 'column', width: 240, boxShadow: `1px 1px 1px gray`, padding: 6, borderWidth: 0.3, borderStyle: 'solid', borderColor: 'lightgray', height: 365, borderRadius: 8, backgroundColor: 'white' }}>
                    <div style={{ borderRadius: 6, height: 290, width: '99%', opacity: 0.8, backgroundPosition: 'center', backgroundSize: 'fill', backgroundRepeat: 'no-repeat', backgroundImage: `url(${news[start + 1].uri})`, alignSelf: 'center' }}>
                        <div style={{ width: '100%', height: '100%', display: 'flex', flexDirection: 'column' }}>
                            <div style={{ height: '60%', width: '100%' }} />
                            <div style={{ opacity: 0.7, backgroundColor: '#366ab6', width: '100%', display: 'flex', flexDirection: 'column', height: '40%', borderBottomLeftRadius: 6, borderBottomRightRadius: 6, justifyContent: 'center' }}>
                                <text style={{ color: 'white', fontSize: 18, opacity: 1, fontWeight: '800', textAlign: 'center' }}>{news[start + 1].title}</text>
                            </div>
                        </div>
                    </div>
                    <text style={{ color: 'black', fontWeight: '500', marginTop: 6 }}>{short_brief(news[start + 1].news)} ..</text>
                    <div style={{ display: 'flex', alignItems: 'center', flexDirection: 'row', marginTop: 4 }}>
                        {calendar_icon}
                        <text style={{ color: 'black', fontWeight: '400', fontSize: 12, marginLeft: 10 }}>{times(news[start + 1].date)}</text>
                    </div>
                </div>

                <div onClick={() => navigate('/News', { state: { item: news[start + 2] } })} style={{ cursor: 'pointer', display: 'flex', flexDirection: 'column', width: 240, boxShadow: `1px 1px 1px gray`, padding: 6, borderWidth: 0.3, borderStyle: 'solid', borderColor: 'lightgray', height: 365, borderRadius: 8, backgroundColor: 'white' }}>
                    <div style={{ borderRadius: 6, height: 290, width: '99%', backgroundPosition: 'center', opacity: 0.8, backgroundSize: 'fill', backgroundRepeat: 'no-repeat', backgroundImage: `url(${news[start + 2].uri})`, alignSelf: 'center' }}>
                        <div style={{ width: '100%', height: '100%', display: 'flex', flexDirection: 'column' }}>
                            <div style={{ height: '60%', width: '100%' }} />
                            <div style={{ opacity: 0.7, backgroundColor: '#366ab6', width: '100%', display: 'flex', flexDirection: 'column', height: '40%', borderBottomLeftRadius: 6, borderBottomRightRadius: 6, justifyContent: 'center' }}>
                                <text style={{ color: 'white', fontSize: 18, opacity: 1, fontWeight: '800', textAlign: 'center' }}>{news[start + 2].title}</text>
                            </div>
                        </div>
                    </div>
                    <text style={{ color: 'black', fontWeight: '500', marginTop: 6 }}>{short_brief(news[start + 2].news)} ..</text>
                    <div style={{ display: 'flex', alignItems: 'center', flexDirection: 'row', marginTop: 4 }}>
                        {calendar_icon}
                        <text style={{ color: 'black', fontWeight: '400', fontSize: 12, marginLeft: 10 }}>{times(news[start + 2].date)}</text>
                    </div>
                </div>

                <div onClick={() => make_right()} style={{ cursor: 'pointer', width: 44, boxShadow: `1px 1px 1px gray`, height: 44, borderRadius: 22, backgroundColor: '#089ec4', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    {right_curve}
                </div>

            </div>}

            {news != null && isMobile && <div style={{ alignItems: 'center', width: '97%', display: 'flex', flexDirection: 'row', alignSelf: 'center', height: 380, marginTop: 40, justifyContent: 'space-evenly' }}>

                <div onClick={() => make_left()} style={{ cursor: 'pointer', width: 40, boxShadow: `1px 1px 1px gray`, height: 44, borderRadius: 22, backgroundColor: '#089ec4', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    {left_curve}
                </div>

                <div onClick={() => navigate('/News', { state: { item: news[start] } })} style={{ cursor: 'pointer', display: 'flex', flexDirection: 'column', width: '80%', boxShadow: `1px 1px 1px gray`, padding: 6, borderWidth: 0.3, borderStyle: 'solid', borderColor: 'lightgray', height: 365, borderRadius: 8, backgroundColor: 'white' }}>
                    <div style={{ borderRadius: 6, height: 290, width: '98%', opacity: 0.8, backgroundPosition: 'center', backgroundSize: 'fill', backgroundRepeat: 'no-repeat', backgroundImage: `url(${news[start].uri})`, alignSelf: 'center' }}>
                        <div style={{ width: '99%', height: '100%', display: 'flex', flexDirection: 'column' }}>
                            <div style={{ height: '60%', width: '100%' }} />
                            <div style={{ opacity: 0.7, backgroundColor: '#366ab6', width: '100%', display: 'flex', flexDirection: 'column', height: '40%', borderBottomLeftRadius: 6, borderBottomRightRadius: 6, justifyContent: 'center' }}>
                                <text style={{ color: 'white', fontSize: 18, opacity: 1, fontWeight: '800', textAlign: 'center' }}>{news[start].title}</text>
                            </div>
                        </div>
                    </div>
                    <text style={{ color: 'black', fontWeight: '500', marginTop: 6 }}>{short_brief(news[start].news)} ..</text>
                    <div style={{ display: 'flex', alignItems: 'center', flexDirection: 'row', marginTop: 4 }}>
                        {calendar_icon}
                        <text style={{ color: 'black', fontWeight: '400', fontSize: 12, marginLeft: 10 }}>{times(news[start].date)}</text>
                    </div>
                </div>

                <div onClick={() => make_right_mobile()} style={{ cursor: 'pointer', width: 40, boxShadow: `1px 1px 1px gray`, height: 44, borderRadius: 22, backgroundColor: '#089ec4', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    {right_curve}
                </div>

            </div>}

            <div style={{ display: 'flex', width: width, alignSelf: 'center', backgroundColor: '#28b8dc', height: 200, alignItems: 'center', justifyContent: 'center', marginTop: 28 }}>
                <div style={{ width: '90%', alignSelf: 'center', display: 'flex', flexDirection: 'row', height: 180, alignItems: 'center', justifyContent: 'space-between' }}>
                    <div style={{ flexDirection: 'column', display: 'flex', alignItems: 'flex-start', width: '40%', justifyContent: 'center' }}>

                        <img src={require('../nwlb.jpg')} style={{ height: 60, width: 60, borderRadius: 30, objectFit: 'contain', marginBottom: 6 }} />
                        <text style={{ color: 'white', fontSize: 12, fontWeight: '300', textAlign: 'left' }}>
                            Download our app North West Local Business - Easily find the services and products you need while enjoying
                            exclusive features not available on our website.
                        </text>
                    </div>
                    <div style={{ flexDirection: 'column', display: 'flex', alignItems: 'flex-start', width: '14%' }}>
                        <text style={{ fontSize: 14, color: 'white', fontWeight: '800' }}>OUR LOCATION</text>
                        <text style={{ color: 'white', fontSize: 12, fontWeight: '300', marginTop: 6 }}>
                            Northwest, UK
                        </text>
                    </div>
                    <div style={{ flexDirection: 'column', display: 'flex', alignItems: 'flex-start', width: '14%' }}>
                        <text style={{ fontSize: 14, color: 'white', fontWeight: '800' }}>CONTACT US</text>
                        <text style={{ color: 'white', fontSize: 12, fontWeight: '300', marginTop: 6 }}>
                            Tel: 0800 9750341
                        </text>
                    </div>
                </div>
            </div>

            <div style={{ backgroundColor: '#089ec4', width: width, display: 'flex', alignItems: 'center', height: 60, alignSelf: 'center', justifyContent: 'center' }}>
                <div style={{ width: isMobile ? '95%' : '90%', alignSelf: 'center', height: 50, display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between' }}>

                    <div style={{ width: '40%', display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between' }}>
                        <text onClick={() => navigate('/Condition')} style={{ cursor: 'pointer', color: 'white', fontWeight: '400', fontSize: 13, textDecorationLine: 'underline', textDecorationColor: 'white' }}>Terms & Conditions</text>
                        <text style={{ color: 'white', fontWeight: '400', fontSize: 13, textDecorationLine: 'underline', textDecorationColor: 'white' }}>Cookie Policy</text>
                        <text onClick={() => navigate('/Term')} style={{ cursor: 'pointer', color: 'white', fontWeight: '400', fontSize: 13, textDecorationLine: 'underline', textDecorationColor: 'white' }}>Privacy Policy</text>
                    </div>

                    <div style={{ width: isMobile ? '50%' : '25%', display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-around' }}>
                        <div onClick={() => openInNewTab("https://plainenglish.io")} style={{ cursor: 'pointer', display: 'flex', alignContent: 'center', alignItems: 'center', backgroundColor: '#28b8dc', height: 40, width: 40, borderRadius: 20, justifyContent: 'center' }}>
                            {instagram_icon}
                        </div>
                        <div onClick={() => openInNewTab("https://plainenglish.io")} style={{ cursor: 'pointer', display: 'flex', alignContent: 'center', alignItems: 'center', backgroundColor: '#28b8dc', height: 40, width: 40, borderRadius: 20, justifyContent: 'center' }}>
                            {twitter_icon}
                        </div>
                        <div onClick={() => openInNewTab("https://www.facebook.com/liverpoollocalbusiness")} style={{ cursor: 'pointer', display: 'flex', alignContent: 'center', alignItems: 'center', backgroundColor: '#28b8dc', height: 40, width: 40, borderRadius: 20, justifyContent: 'center' }}>
                            {facebook_icon}
                        </div>
                        <div onClick={() => openInNewTab("https://youtube.com/@Northwestlocalbusiness")} style={{ cursor: 'pointer', display: 'flex', alignContent: 'center', alignItems: 'center', backgroundColor: '#28b8dc', height: 40, width: 40, borderRadius: 20, justifyContent: 'center' }}>
                            {youtube_icon}
                        </div>
                        <div onClick={() => openInNewTab("https://www.tiktok.com/@northwestlocalbusiness")} style={{ cursor: 'pointer', display: 'flex', alignContent: 'center', alignItems: 'center', backgroundColor: '#28b8dc', height: 40, width: 40, borderRadius: 20, justifyContent: 'center' }}>
                            {tiktok_icon}
                        </div>
                    </div>

                </div>
            </div>

        </div>
    );
}

export default Dashboard;
