import React, { useState, useEffect, useRef } from 'react';
import { address_big, face_big, facebook_icon, favourite_icon, instagram_icon, mail_big, plus_icon, search_icon, tiktok_big, tube_big, twitter_icon, user_icon, whatsapp_big } from './icons';
import FlatList from 'flatlist-react/lib';
import back from './back.png'
import customer from './customer1.jpg'
import { useNavigate, useLocation } from 'react-router-dom';
import ReactDropdown from 'react-dropdown';
import 'react-dropdown/style.css';
//import './App.css';
//import { db } from './config';

//const newdb = db.ref('new')

function Contact() {

    const navigate = useNavigate();

    const { state } = useLocation();

    const [width, setWidth] = useState(window.innerWidth);
    const [height, setHeight] = useState(window.innerHeight);

    function handleWindowSizeChange() {
        setWidth(window.innerWidth);
        setHeight(window.innerHeight);
    }

    const isMobile = width <= 768;

    useEffect(() => {
        window.addEventListener('resize', handleWindowSizeChange);
        return () => {
            window.removeEventListener('resize', handleWindowSizeChange);
        }
    }, []);


    // https://api.whatsapp.com/send?phone=1234567890

    // window.open('mailto:email@example.com?subject=Subject&body=Body%20goes%20here')

    const openInNewTab = (url) => {
        if (url.includes('http')) window.open(url, "_blank", "noreferrer");
        else window.open("https://" + String(url).toLowerCase(), "_blank", "noreferrer");
    };

    return (
        <div style={{ display: 'flex', flexDirection: 'column', width: '100%', overflow: 'hidden', padding: 2 }}>

            <div style={{ alignItems: 'center', width: '90%', display: 'flex', flexDirection: 'row', alignSelf: 'center', height: 80, justifyContent: 'space-between' }}>
                <div onClick={() => navigate('/')} style={{ cursor: 'pointer', width: '30%', display: 'flex', justifyContent: 'flex-start' }}>
                    <img src={require('../nwlb.jpg')} style={{ height: 60, width: 60, borderRadius: 30, objectFit: 'contain' }} />

                </div>
                <div style={{ width: isMobile ? '40%' : '50%', display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-evenly' }}>
                    {isMobile && <ReactDropdown
                        arrowClosed={<text></text>}
                        arrowOpen={<text></text>}
                        options={['Favourite', 'Learn', 'Join', 'Contact us', 'Profile', 'Add Listing']}
                        onChange={(e) => {
                            let command = e.value;
                            if (command.includes('Favourite')) {
                                navigate('/Favourite')
                            }
                            else if (command.includes("Contact")) {
                                navigate('/Contact')
                            }
                            else if (command.includes("Profile")) {
                                navigate('/Login')
                            }
                            else if (command.includes("Add")) {
                                navigate('/AddListing')
                            }
                            else if (command.includes("Join")) {
                                navigate('/Join')
                            }
                        }}
                        placeholder="     Menu     "
                    />}
                    {!isMobile && <text onClick={() => navigate('/Favourite')} style={{ cursor: 'pointer', fontSize: 16, color: 'black', fontWeight: '500' }}>Favourite</text>}
                    {!isMobile && <text style={{ fontSize: 16, color: 'black', fontWeight: '500' }}>Learn</text>}
                    {!isMobile && <text onClick={() => navigate('/Join')} style={{cursor: 'pointer', fontSize: 16, color: 'black', fontWeight: '500' }}>Join</text>}
                    {!isMobile && <text style={{ fontSize: 16, color: 'black', fontWeight: '500' }}>Contact us</text>}
                    {!isMobile && <div onClick={() => navigate('/Login')} style={{ cursor: 'pointer', display: 'flex', alignContent: 'center', alignItems: 'center', backgroundColor: '#28b8dc', height: 35, width: 35, borderRadius: 4, justifyContent: 'center' }}>
                        {user_icon}
                    </div>}
                    {!isMobile && <div onClick={() => navigate('/AddListing')} style={{ cursor: 'pointer', display: 'flex', alignContent: 'center', flexDirection: 'row', alignItems: 'center', backgroundColor: '#28b8dc', height: 35, width: 130, borderRadius: 4, justifyContent: 'center' }}>
                        {plus_icon}
                        <text style={{ marginLeft: 4, color: 'white', fontSize: 12 }}>Add Listing</text>
                    </div>}
                </div>
            </div>

            <img src={back} style={{ width: '100%', height: 190, objectFit: 'fill', marginBottom: 40, marginTop: 20 }} />

            <div style={{ display: 'flex', width: isMobile ? '94%' : '80%', alignSelf: 'center', height: 180, flexDirection: 'row', marginBottom: 10, justifyContent: 'space-between' }}>

                <div onClick={() => { if(isMobile)window.open(`https://api.whatsapp.com/send?phone=${`+4407801254028`}`) ;else window.open(`https://api.whatsapp.com/send?phone=${`07801254028`}`) }} style={{ cursor: 'pointer', height: 170, width: '27%', backgroundColor: '#e9f8fb', padding: 6, borderRadius: 8, display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                    <div style={{ display: 'flex', alignContent: 'center', alignItems: 'center', backgroundColor: '#d4f1f8', height: 70, width: 70, borderRadius: 15, justifyContent: 'center' }}>
                        {whatsapp_big}
                    </div>
                    <text style={{ marginTop: 6, fontWeight: '700', alignItems: 'center', fontSize: 18 }}>Whatsapp</text>

                </div>

                <div onClick={() => window.open(`mailto:${`info@northwestlocalbusiness.Co.uk`}?subject=Subject&body=Body%20goes%20here`)} style={{ cursor: 'pointer', height: 170, width: '27%', backgroundColor: '#e9f8fb', padding: 6, borderRadius: 8, display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                    <div style={{ display: 'flex', alignContent: 'center', alignItems: 'center', backgroundColor: '#d4f1f8', height: 70, width: 70, borderRadius: 15, justifyContent: 'center' }}>
                        {mail_big}
                    </div>
                    <text style={{ marginTop: 6, fontWeight: '700', alignItems: 'center', fontSize: 18 }}>Email Address</text>

                </div>

                <div onClick={() => {if(isMobile)window.open(`https://api.whatsapp.com/send?phone=${`+4407801254028`}`) ;else window.open(`https://api.whatsapp.com/send?phone=${`07801254028`}`) }} style={{ cursor: 'pointer', height: 170, width: '27%', backgroundColor: '#e9f8fb', padding: 6, borderRadius: 8, display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                    <div style={{ display: 'flex', alignContent: 'center', alignItems: 'center', backgroundColor: '#d4f1f8', height: 70, width: 70, borderRadius: 15, justifyContent: 'center' }}>
                        {whatsapp_big}
                    </div>
                    <text style={{ marginTop: 6, fontWeight: '700', alignItems: 'center', fontSize: 18 }}>Whatsapp</text>

                </div>
            </div>

            <div style={{ display: 'flex', width: isMobile ? '94%' : '80%', alignSelf: 'center', height: 180, flexDirection: 'row', marginBottom: 50, justifyContent: 'space-between' }}>
                <div onClick={() => openInNewTab('www.tiktok.com/@northwestlocalbusiness')} style={{ cursor: 'pointer', height: 170, width: '27%', backgroundColor: '#e9f8fb', padding: 6, borderRadius: 8, display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                    <div style={{ display: 'flex', alignContent: 'center', alignItems: 'center', backgroundColor: '#d4f1f8', height: 70, width: 70, borderRadius: 15, justifyContent: 'center' }}>
                        {tiktok_big}
                    </div>
                    <text style={{ marginTop: 6, fontWeight: '700', alignItems: 'center', fontSize: 18 }}>Tiktok</text>

                </div>
                <div onClick={() => openInNewTab('https://youtube.com/@Northwestlocalbusiness')} style={{ cursor: 'pointer', height: 170, width: '27%', backgroundColor: '#e9f8fb', padding: 6, borderRadius: 8, display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                    <div style={{ display: 'flex', alignContent: 'center', alignItems: 'center', backgroundColor: '#d4f1f8', height: 70, width: 70, borderRadius: 15, justifyContent: 'center' }}>
                        {tube_big}
                    </div>
                    <text style={{ marginTop: 6, fontWeight: '700', alignItems: 'center', fontSize: 18 }}>Youtube</text>

                </div>
                <div onClick={() => openInNewTab('https://www.facebook.com/liverpoollocalbusiness')} style={{ cursor: 'pointer', height: 170, width: '27%', backgroundColor: '#e9f8fb', padding: 6, borderRadius: 8, display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                    <div style={{ display: 'flex', alignContent: 'center', alignItems: 'center', backgroundColor: '#d4f1f8', height: 70, width: 70, borderRadius: 15, justifyContent: 'center' }}>
                        {face_big}
                    </div>
                    <text style={{ marginTop: 6, fontWeight: '700', alignItems: 'center', fontSize: 18 }}>Facebook</text>

                </div>
            </div>

            <div style={{ display: 'flex', alignItems: 'center', width: '80%', alignSelf: 'center', height: 410, backgroundColor: '#28b8dc', flexDirection: 'row', marginBottom: 30, justifyContent: 'space-between' }}>
                <img src={customer} style={{ height: 410, width: '35%', objectFit: 'fill' }} />
                <div style={{ width: '60%', display: 'flex', justifyItems: 'start', flexDirection: 'column', alignItems: 'flex-start', marginLeft: 10, alignSelf: 'flex-start', padding: 20, height: 410 }}>
                    <text style={{ color: 'white', fontWeight: '500', fontSize: 14, textAlign: 'left' }}>Get In Touch . Drop Us a Line !</text>
                    <text style={{ color: 'white', fontWeight: '700', fontSize: 26, marginTop: 6, textAlign: 'left' }}>Contact Us</text>
                    <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginTop: 8, width: '90%', justifyContent: 'space-between' }}>
                        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
                            <text style={{ color: 'white', fontWeight: '500', fontSize: 12, textAlign: 'left' }}>Your Name - required</text>
                            <input type='text' placeholder=' Your Name' style={{ marginTop: 4, height: 30, width: 230, borderRadius: 10, outline: 'none', borderStyle: 'solid', borderWidth: 0.5, padding: 4, borderColor: 'lightgray' }} />
                        </div>

                        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', marginLeft: 40 }}>
                            <text style={{ color: 'white', fontWeight: '500', fontSize: 12, textAlign: 'left' }}>Your Email - required</text>
                            <input type='text' placeholder=' Your Email' style={{ marginTop: 4, height: 30, width: 230, borderRadius: 10, outline: 'none', borderStyle: 'solid', borderWidth: 0.5, padding: 4, borderColor: 'lightgray' }} />
                        </div>
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', width: '90%', marginTop: 10 }}>
                        <text style={{ color: 'white', fontWeight: '500', fontSize: 12, textAlign: 'left' }}>Subject</text>
                        <input type='text' placeholder=' Your Subject' style={{ marginTop: 4, height: 30, width: '99%', borderRadius: 10, outline: 'none', borderStyle: 'solid', borderWidth: 0.5, padding: 4, borderColor: 'lightgray' }} />
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', width: '90%', marginTop: 10 }}>
                        <text style={{ color: 'white', fontWeight: '500', fontSize: 12, textAlign: 'left' }}>Subject</text>
                        <textarea type='text' placeholder=' Your message' style={{ marginTop: 4, height: 100, width: '99%', borderRadius: 10, outline: 'none', borderStyle: 'solid', borderWidth: 0.5, padding: 4, borderColor: 'lightgray' }} />
                    </div>
                    <div style={{ width: 90, height: 30, padding: 10, display: 'flex', justifyContent: 'center', backgroundColor: 'white', borderRadius: 4, alignSelf: 'center', marginTop: 6 }}>
                        <text style={{ color: '#28b8dc', fontSize: 12, fontWeight: '500' }}>Send</text>
                    </div>
                </div>
            </div>

            <div style={{ alignSelf: 'center', display: 'flex', width: width, backgroundColor: '#28b8dc', height: 200, alignItems: 'center', justifyContent: 'center' }}>
                <div style={{ width: '90%', alignSelf: 'center', display: 'flex', flexDirection: 'row', height: 180, alignItems: 'center', justifyContent: 'space-between' }}>
                    <div style={{ flexDirection: 'column', display: 'flex', alignItems: 'flex-start', width: '40%', justifyContent: 'center' }}>
                        <img src={require('../nwlb.jpg')} style={{ height: 60, width: 60, borderRadius: 30, objectFit: 'contain' }} />
                        <text style={{ color: 'white', fontSize: 12, fontWeight: '300' }}>
                            Download our app North West Local Business - Easily find the services and products you need while enjoying
                            exclusive features not available on our website.
                        </text>
                    </div>
                    <div style={{ flexDirection: 'column', display: 'flex', alignItems: 'flex-start', width: '14%' }}>
                        <text style={{ fontSize: 14, color: 'white', fontWeight: '800' }}>OUR LOCATION</text>
                        <text style={{ color: 'white', fontSize: 12, fontWeight: '300', marginTop: 6 }}>
                            Northwest, UK
                        </text>
                    </div>
                    <div style={{ flexDirection: 'column', display: 'flex', alignItems: 'flex-start', width: '14%' }}>
                        <text style={{ fontSize: 14, color: 'white', fontWeight: '800' }}>CONTACT US</text>
                        <text style={{ color: 'white', fontSize: 12, fontWeight: '300', marginTop: 6 }}>
                            Tel: 0800 9750341
                        </text>
                    </div>
                </div>
            </div>

            <div style={{ backgroundColor: '#089ec4', width: '100%', display: 'flex', alignItems: 'center', height: 60, alignSelf: 'center', justifyContent: 'center' }}>
                <div style={{ width: '90%', alignSelf: 'center', height: 50, display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between' }}>

                    <div style={{ width: '40%', display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between' }}>
                        <text style={{ color: 'white', fontWeight: '400', fontSize: 13, textDecorationLine: 'underline', textDecorationColor: 'white' }}>Terms & Conditions</text>
                        <text style={{ color: 'white', fontWeight: '400', fontSize: 13, textDecorationLine: 'underline', textDecorationColor: 'white' }}>Cookie Policy</text>
                        <text style={{ color: 'white', fontWeight: '400', fontSize: 13, textDecorationLine: 'underline', textDecorationColor: 'white' }}>Privacy Policy</text>
                    </div>

                    <div style={{ width: '25%', display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-around' }}>
                        <div style={{ display: 'flex', alignContent: 'center', alignItems: 'center', backgroundColor: '#28b8dc', height: 40, width: 40, borderRadius: 20, justifyContent: 'center' }}>
                            {instagram_icon}
                        </div>
                        <div style={{ display: 'flex', alignContent: 'center', alignItems: 'center', backgroundColor: '#28b8dc', height: 40, width: 40, borderRadius: 20, justifyContent: 'center' }}>
                            {twitter_icon}
                        </div>
                        <div style={{ display: 'flex', alignContent: 'center', alignItems: 'center', backgroundColor: '#28b8dc', height: 40, width: 40, borderRadius: 20, justifyContent: 'center' }}>
                            {facebook_icon}
                        </div>
                        <div style={{ display: 'flex', alignContent: 'center', alignItems: 'center', backgroundColor: '#28b8dc', height: 40, width: 40, borderRadius: 20, justifyContent: 'center' }}>
                            {instagram_icon}
                        </div>
                        <div style={{ display: 'flex', alignContent: 'center', alignItems: 'center', backgroundColor: '#28b8dc', height: 40, width: 40, borderRadius: 20, justifyContent: 'center' }}>
                            {instagram_icon}
                        </div>
                    </div>

                </div>
            </div>

        </div>
    );
}

export default Contact;