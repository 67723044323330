import React, { useState, useEffect, useRef } from 'react';
import { address_big, back_icon, face_big, facebook_icon, favourite_icon, instagram_icon, mail_big, plus_icon, search_icon, tiktok_big, tube_big, twitter_icon, user_icon, whatsapp_big } from './icons';
import FlatList from 'flatlist-react/lib';
import back from './back.png'
import customer from './customer1.jpg'
import upload from './upload.png'
import '../App.css';
import { useNavigate, useLocation } from 'react-router-dom';
import { toast } from 'wc-toast';
import { db, storage } from '../config';
import { TimePicker } from 'react-ios-time-picker'
import { Levels } from 'react-activity';
import "react-activity/dist/library.css";
import ReactDropdown from 'react-dropdown';
import 'react-dropdown/style.css';
import Rating from 'react-rating';
import ReactModal from 'react-modal';
import { AiOutlineStar, AiTwotoneStar } from 'react-icons/ai'
import { FaUser } from 'react-icons/fa'
import error from './assets/error.png'
import { Img } from 'react-image'
import { BiComment } from 'react-icons/bi'
import { LiaAngleUpSolid, LiaAngleDownSolid } from 'react-icons/lia'


const up = <LiaAngleUpSolid size={20} color='#089ec4' />
const down = <LiaAngleDownSolid size={20} color='#089ec4' />

const comment_input = <BiComment size={20} color='#089ec4' />
//const newdb = db.ref('new')

const user_profile = <FaUser size={35} color='gray' />

const empty = <AiOutlineStar size={30} color='black' />
const fill = <AiTwotoneStar size={30} color='#FDCC0D' />

const field_background = "#E4ECF7"

const waiting_db = db.ref('waiting_db');
const accepted_db = db.ref('accepted_db')
const admin_distance_db = db.ref('admin_distance_db');
const default_price_db = db.ref('default_price_db')

const wordpress_db = db.ref('wordpress')

const review_db = db.ref('review_db')

function id(mail) {
    let str = ''
    for (let i = 0; i < mail.length; i++) {
        if ((mail[i] >= 'a' && mail[i] <= 'z') || (mail[i] >= '0' && mail[i] <= '9')) str += mail[i]
    }
    return str;
}

function SingleListingW() {

    const navigate = useNavigate();

    const { state } = useLocation();

    const { item } = state;

    console.log(item);

    const { search_word, post, isAble } = state

    const business_name = item['Title']
    const uri = item['Image URL']
    const address = item['_job_location']
    const phone = item['_company_phone']
    const email = item['_company_email']
    const website = item['_company_website']
    const tagline = item['_company_tagline']
    const timings = item['_job_hours']
    const town = item['geolocation_city']
    const lat = item['geolocation_lat']
    const long = item['geolocation_long']
    const post_code = item['geolocation_postcode']
    const country = item['geolocation_country_long']
    const social = item['_gt3_social_link']
    const description = item['Content']

    console.log(business_name, email, uri);
    const [loading, setLoading] = useState(false)

    const [reviews, setReviews] = useState(null)
    // mine

    const [width, setWidth] = useState(window.innerWidth);
    const [height, setHeight] = useState(window.innerHeight);

    const [rating_open, setRatingOpen] = useState(false)

    const [comment_open, setCommentOpen] = useState(false)

    const [name, setName] = useState('')

    const [curr_rev_id, setCurrRev] = useState(null)

    const [rat, setRat] = useState(0)
    const [rating_email, setRatingEmail] = useState('')



    const [rating_otp, setRatingOtp] = useState(null)

    const [average_rating, setAverageRating] = useState(0);
    const [all_rating, setAllRating] = useState({ one: 0, two: 0, three: 0, four: 0, five: 0 })

    const [my_otp, setMyOtp] = useState('')
    const [rating_feedback, setRatingFeedback] = useState('')

    function handleWindowSizeChange() {
        setWidth(window.innerWidth);
        setHeight(window.innerHeight);
    }

    useEffect(() => {
        window.addEventListener('resize', handleWindowSizeChange);
        return () => {
            window.removeEventListener('resize', handleWindowSizeChange);
        }
    }, []);

    function decide() {
        if (rating_otp) {
            if (my_otp == rating_otp) {
                setLoading(true)
                const tim = String(new Date().getTime())
                let review_obj = {
                    added_by: rating_email,
                    rating: rat,
                    time: tim,
                    listing_id: String(item.id),
                    feedback: rating_feedback,
                    name: name
                }
                let update_obj = {
                    rating1: item.rating1 == undefined ? 0 : item.rating1 + (rat == 1),
                    rating2: item.rating2 == undefined ? 0 : item.rating2 + (rat == 2),
                    rating3: item.rating3 == undefined ? 0 : item.rating3 + (rat == 3),
                    rating4: item.rating4 == undefined ? 0 : item.rating4 + (rat == 4),
                    rating5: item.rating5 == undefined ? 0 : item.rating5 + (rat == 5),
                }
                review_db.child(id(rating_email) + tim).set(review_obj).then(() => {
                    wordpress_db.child(String(item.id)).update(update_obj).then(() => {
                        toast('Your rating has been submitted');
                        setRatingOpen(false);
                        setRat(0)
                        setRatingEmail('')
                        setRatingFeedback('');
                        setMyOtp('')
                        setRatingOtp(null);
                        setLoading(false)
                    })
                })
            }
            else {
                toast('Verification code is not correct !')
            }
        }
        else {
            setLoading(true)
            fetch(`https://us-central1-nwlb-fb8ad.cloudfunctions.net/app/mail_verification?email=${rating_email}`).then((val) => val.json().then((val) => {
                setRatingOtp(val.otp);
                setLoading(false)
                toast('A verification code has been sent to your Email . Fill your verification code to enter');
            })).catch(e => console.log(e))
        }
    }

    const isMobile = width <= 768;

    function getting_reviews() {
        review_db.orderByChild('listing_id').equalTo(String(item.id)).on('value', (snapshot) => {
            console.log()
            if (snapshot.val() == null) {
                setReviews([])
            }
            else {
                let arr = Object.values(snapshot.val());
                arr.sort(function (a, b) { return b.time - a.time });
                let five = 0;
                let four = 0;
                let three = 0;
                let two = 0;
                let one = 0;
                for (let i = 0; i < arr.length; i++) {
                    if (arr[i].rating == 5) {
                        five++;
                    }
                    else if (arr[i].rating == 4) {
                        four++;
                    }
                    else if (arr[i].rating == 3) {
                        three++;
                    }
                    else if (arr[i].rating == 2) {
                        two++;
                    }
                    else if (arr[i].rating == 1) {
                        one++;
                    }
                }
                let all = five + four + three + two + one;
                let avg = Math.floor((five * 5 + four * 4 + three * 3 + two * 2 + one * 1) / (all))
                if (all != 0) {
                    setAllRating({ ...all_rating, five: (five / all).toFixed(2), four: (four / all).toFixed(2), three: (three / all).toFixed(2), two: (two / all).toFixed(2), one: (one / all).toFixed(2) })
                }
                setAverageRating(avg)
                setReviews([...arr]);
            }
        })
    }

    function make_arr(comm) {
        let arr = Object.values(comm);
        return arr;
    }

    useEffect(() => {
        if (reviews == null) {
            console.log("String ", item.id)
            getting_reviews()
        }
    })

    const openInNewTab = (url) => {
        if (url.includes('http')) window.open(url, "_blank", "noreferrer");
        else if (url != '') window.open("https://" + String(url).toLowerCase(), "_blank", "noreferrer");
    };

    function make_up_down(id) {
        setReviews((prevValue) => {
            return prevValue.map((item, itemIndex) => {
                if (item.time === id) {
                    return { ...item, show: !item.show };
                } else {
                    return item;
                }
            });
        });
    }

    const renderStandingsReview = (item) => {
        return (<div style={{ width: '99%', padding: 4, display: 'flex', marginTop: 4, alignSelf: 'center', flexDirection: 'column', maxHeight: 550 }}>
            <div style={{ display: 'flex', width: '96%', alignSelf: 'center', borderRadius: 6, borderWidth: 0.5, borderColor: '#28b8dc', borderStyle: 'solid', padding: 6, maxHeight: 250, flexDirection: 'row' }}>
                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', width: '80%' }}>
                    {user_profile}
                    <div style={{ display: 'flex', justifyContent: 'left', marginLeft: 8, flexDirection: 'column' }}>
                        <text style={{ color: 'black', textAlign: 'left' }}><text style={{ color: 'black', fontWeight: '600' }}>{item.name == undefined ? 'User' : item.name}</text>  ⭐ {item.rating}</text>
                        <text style={{ color: 'gray', marginTop: 3, fontSize: 14, textAlign: 'left' }}>{item.feedback}</text>
                    </div>
                </div>
                <div onClick={() => {
                    setCurrRev(item);
                    setCommentOpen(true)
                }} style={{ alignSelf: 'flex-end', cursor: 'pointer', marginLeft: 50 }}>
                    {comment_input}
                </div>
                <div onClick={() => {
                    make_up_down(item.time);
                }} style={{ alignSelf: 'flex-end', cursor: 'pointer', marginLeft: 15 }}>
                    {item.show ? down : up}
                </div>
            </div>
            {item.show && item.comments != undefined && <div style={{ height: 10, width: 1, backgroundColor: 'black', marginLeft: 16 }} />}
            {item.show && item.comments != undefined && <div style={{ height: 1, width: 15, backgroundColor: 'black', marginLeft: 16 }} />}
            {item.show && item.comments != undefined && make_arr(item.comments).map(item => <div style={{ marginLeft: 2, width: '90%', display: 'flex', alignSelf: 'center', borderRadius: 6, borderWidth: 0.5, borderColor: '#28b8dc', borderStyle: 'solid', padding: 3, flexDirection: 'row', marginBottom: 2 }}>
                <div style={{ display: 'flex', justifyContent: 'left', marginLeft: 8, flexDirection: 'column' }}>
                    <text style={{ color: 'black', textAlign: 'left' }}><text style={{ color: 'black', fontWeight: '500', fontSize: 13 }}>{item.name == undefined ? 'User' : item.name}</text></text>
                    <text style={{ color: 'gray', marginTop: 3, fontSize: 11, textAlign: 'left' }}>{item.feedback}</text>
                </div>
            </div>)}
        </div>)
    }

    function comments() {
        if (rating_otp) {
            if (my_otp == rating_otp) {
                setLoading(true)
                const ids = String(new Date().getTime())
                let review_obj = {
                    added_by: rating_email,
                    time: String(new Date().getTime()),
                    id: ids,
                    feedback: rating_feedback,
                    name: name
                }
                review_db.child(id(curr_rev_id.added_by) + String(curr_rev_id.time)).child('comments').child(ids).set(review_obj).then(() => {
                    toast('Your comments has been submitted');
                    setCommentOpen(false);
                    setRatingEmail('')
                    setRatingFeedback('');
                    setMyOtp('')
                    setRatingOtp(null);
                    setLoading(false);
                    setName('')
                })
            }
            else {
                toast('Verification code is not correct !')
            }
        }
        else {
            setLoading(true)
            fetch(`https://us-central1-nwlb-fb8ad.cloudfunctions.net/app/mail_verification?email=${rating_email}`).then((val) => val.json().then((val) => {
                setRatingOtp(val.otp);
                setLoading(false)
                toast('A verification code has been sent to your Email . Fill your verification code to enter');
            })).catch(e => console.log(e))
        }
    }

    function format(str) {

        let start = String(str).substring(0, String(str).indexOf('m') + 2)
        let end = String(str).substring(String(str).indexOf('m') + 2, String(str).length)

        return start + ' -> ' + end
    }

    function my_description(str) {
        let ret = String(str);
        ret = ret.replaceAll('<span>', '')
        ret = ret.replaceAll('<span', '')
        ret = ret.replaceAll('<img', '')
        ret = ret.replaceAll('class=', '')
        ret = ret.replaceAll('gt3_dl', '')
        ret = ret.replaceAll('</span>', '')
        ret = ret.replaceAll('</div>', '')
        ret = ret.replaceAll('<div>', '')
        ret = ret.replaceAll('<div', '')
        ret = ret.replaceAll('</dt>', '')
        ret = ret.replaceAll('<dt>', '')
        ret = ret.replaceAll('<dt', '')
        ret = ret.replaceAll('<dl>', '')
        ret = ret.replaceAll('<dl', '')
        ret = ret.replaceAll('</dl>', '')
        ret = ret.replaceAll('<dd>', '')
        ret = ret.replaceAll('<dd', '')
        ret = ret.replaceAll('</dd>', '')
        ret = ret.replaceAll('header', '')
        ret = ret.replaceAll('&amp', '')
        ret = ret.replaceAll('""', '')

        ret = ret.replaceAll('>', '')
        ret = ret.replaceAll('<', '')

        ret = ret.replaceAll('_aac', '')
        return ret;
    }

    return (
        <div style={{ display: 'flex', flexDirection: 'column', width: '100%', overflow: 'hidden', padding: 2 }}>

            <wc-toast></wc-toast>

            <div style={{ alignItems: 'center', width: '90%', display: 'flex', flexDirection: 'row', alignSelf: 'center', height: 80, justifyContent: 'space-between' }}>
                <div onClick={() => navigate('/')} style={{ cursor: 'pointer', width: '30%', display: 'flex', justifyContent: 'flex-start' }}>
                    <img src={require('../nwlb.jpg')} style={{ height: 60, width: 60, borderRadius: 30, objectFit: 'contain' }} />
                </div>
                <div style={{ width: '50%', display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-evenly' }}>
                    {isMobile && <ReactDropdown
                        arrowClosed={<text></text>}
                        arrowOpen={<text></text>}
                        options={['Favourite', 'Learn', 'Join', 'Contact us', 'Profile']}
                        onChange={(e) => {
                            let command = e.value;
                            if (command.includes('Favourite')) {
                                navigate('/Favourite')
                            }
                            else if (command.includes("Contact")) {
                                navigate('/Contact')
                            }
                            else if (command.includes("Profile")) {
                                navigate('/Login')
                            }
                            else if (command.includes("Add")) {
                                navigate('/AddListing')
                            }
                        }}
                        placeholder="     Menu     "
                    />}

                    {!isMobile && <text onClick={() => navigate('/Favourite')} style={{ cursor: 'pointer', fontSize: 16, color: 'black', fontWeight: '500' }}>Favourite</text>}
                    {!isMobile && <text style={{ fontSize: 16, color: 'black', fontWeight: '500' }}>Learn</text>}
                    {!isMobile && <text onClick={() => navigate('/Join')} style={{ fontSize: 16, color: 'black', fontWeight: '500' }}>Join</text>}
                    {!isMobile && <text onClick={() => navigate('/Contact')} style={{ cursor: 'pointer', fontSize: 16, color: 'black', fontWeight: '500' }}>Contact us</text>}
                    {!isMobile && <div onClick={() => navigate('/Login')} style={{ cursor: 'pointer', display: 'flex', alignContent: 'center', alignItems: 'center', backgroundColor: '#28b8dc', height: 35, width: 35, borderRadius: 4, justifyContent: 'center' }}>
                        {user_icon}
                    </div>}
                    {!isMobile && <div style={{ display: 'flex', alignContent: 'center', flexDirection: 'row', alignItems: 'center', backgroundColor: '#28b8dc', height: 35, width: 130, borderRadius: 4, justifyContent: 'center' }}>
                        {plus_icon}
                        <text style={{ marginLeft: 4, color: 'white', fontSize: 12 }}>Add Listing</text>
                    </div>}
                </div>
            </div>

            <div style={{ height: 120, backgroundColor: '#d4f1f8', display: 'flex', justifyContent: 'center', marginTop: 20 }}>
                <text style={{ fontWeight: '600', fontSize: 35, alignSelf: 'center', color: '#089ec4' }}>Listing Details</text>
            </div>

            <div style={{ cursor: 'pointer', alignSelf: 'center', display: 'flex', alignContent: 'center', alignItems: 'center', height: 45, borderRadius: 4, justifyContent: 'space-between', flexDirection: 'row', width: '80%', marginTop: 15 }}>
                <div onClick={() => { if (post != undefined) { navigate('/SearchList', { state: { post_code: post, search_word: search_word, isAble: isAble } }) } else navigate(-1) }} style={{ cursor: 'pointer', backgroundColor: '#28b8dc', display: 'flex', height: 42, width: 40, borderRadius: 10, alignItems: 'center', justifyContent: 'center', alignContent: 'center' }}>
                    {back_icon}
                </div>
                <text></text>
            </div>

            <div style={{ display: 'flex', alignSelf: 'center', justifyContent: 'center', height: 350, width: '90%', marginTop: 7 }}>
                <Img
                    style={{ cursor: 'pointer', alignSelf: 'center', height: 350, width: '96%', objectFit: 'contain' }}
                    src={uri}
                    loader={<img src={error} alt="img" style={{ width: 140, height: 330, borderRadius: 2, objectFit: 'fill' }} />}
                    unloader={<img src={error} alt="img" style={{ width: 140, height: 330, borderRadius: 2, objectFit: 'fill' }} />}
                />
            </div>

            {!isMobile && <div style={{ display: 'flex', alignItems: 'center', width: '92%', alignSelf: 'center', maxHeight: 715, flexDirection: 'column', marginBottom: 30, marginTop: 5, justifyContent: 'center' }}>
                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginTop: 10, width: '90%', justifyContent: 'space-between' }}>
                    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', width: '40%' }}>
                        <text style={{ color: 'gray', fontWeight: '500', fontSize: 12, textAlign: 'left' }}>Business name :</text>
                        <input value={business_name} type='text' disabled={true} style={{ backgroundColor: '#f8f8f8', marginTop: 4, height: 38, width: '96%', borderRadius: 10, outline: 'none', borderStyle: 'solid', borderWidth: 0.5, padding: 4 }} />
                    </div>

                    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', marginLeft: 40, width: '40%' }}>
                        <text style={{ color: 'gray', fontWeight: '500', fontSize: 12, textAlign: 'left' }}>Address : </text>
                        <textarea value={address == '' ? 'No address' : address} type='text' disabled={true} style={{ backgroundColor: '#f8f8f8', marginTop: 4, height: 46, width: '96%', borderRadius: 10, outline: 'none', borderStyle: 'solid', borderWidth: 0.5, padding: 4 }} />
                    </div>
                </div>
                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginTop: 10, width: '90%', justifyContent: 'space-between' }}>
                    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', width: '40%' }}>
                        <text style={{ color: 'gray', fontWeight: '500', fontSize: 12, textAlign: 'left' }}>Tagline :</text>
                        <input value={tagline == '' ? 'No tagline' : tagline} type='text' disabled={true} style={{ backgroundColor: '#f8f8f8', marginTop: 4, height: 38, width: '96%', borderRadius: 10, outline: 'none', borderStyle: 'solid', borderWidth: 0.5, padding: 4 }} />
                    </div>

                    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', marginLeft: 40, width: '40%' }}>
                        <text style={{ color: 'gray', fontWeight: '500', fontSize: 12, textAlign: 'left' }}>City : </text>
                        <input value={town == '' ? 'No town' : town} type='text' disabled={true} style={{ backgroundColor: '#f8f8f8', marginTop: 4, height: 38, width: '96%', borderRadius: 10, outline: 'none', borderStyle: 'solid', borderWidth: 0.5, padding: 4, }} />
                    </div>
                </div>
                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginTop: 10, width: '90%', justifyContent: 'space-between' }}>
                    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', width: '40%' }}>
                        <text style={{ color: 'gray', fontWeight: '500', fontSize: 12, textAlign: 'left' }}>Post Code :</text>
                        <input value={post_code == '' ? 'No post code' : post_code} type='text' disabled={true} style={{ backgroundColor: '#f8f8f8', marginTop: 4, height: 38, width: '96%', borderRadius: 10, outline: 'none', borderStyle: 'solid', borderWidth: 0.5, padding: 4 }} />
                    </div>

                    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', marginLeft: 40, width: '40%' }}>
                        <text style={{ color: 'gray', fontWeight: '500', fontSize: 12, textAlign: 'left' }}>Country : </text>
                        <input value={country == '' ? 'Not found' : country} type='text' disabled={true} style={{ backgroundColor: '#f8f8f8', marginTop: 4, height: 38, width: '96%', borderRadius: 10, outline: 'none', borderStyle: 'solid', borderWidth: 0.5, padding: 4, }} />
                    </div>
                </div>

                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginTop: 10, width: '90%', justifyContent: 'space-between' }}>
                    <div onClick={() => openInNewTab(website)} style={{ cursor: 'pointer', display: 'flex', flexDirection: 'column', alignItems: 'flex-start', width: '40%' }}>
                        <text style={{ color: 'gray', fontWeight: '500', fontSize: 12, textAlign: 'left' }}>Website Url :</text>
                        <input value={website == '' ? 'No website' : website} type='text' disabled={true} style={{ backgroundColor: '#f8f8f8', marginTop: 4, height: 38, width: '96%', borderRadius: 10, outline: 'none', borderStyle: 'solid', borderWidth: 0.5, padding: 4 }} />
                    </div>

                    <div onClick={() => openInNewTab(website)} style={{ cursor: 'pointer', display: 'flex', flexDirection: 'column', alignItems: 'flex-start', marginLeft: 40, width: '40%' }}>
                        <text style={{ color: 'gray', fontWeight: '500', fontSize: 12, textAlign: 'left' }}>Social media links : </text>
                        <input value={social == '' ? 'No link' : social} type='text' disabled={true} style={{ backgroundColor: '#f8f8f8', marginTop: 4, height: 38, width: '96%', borderRadius: 10, outline: 'none', borderStyle: 'solid', borderWidth: 0.5, padding: 4 }} />
                    </div>
                </div>

                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginTop: 10, width: '90%', justifyContent: 'space-between' }}>
                    <div onClick={() => window.open(`mailto:${email}?subject=Subject&body=Body%20goes%20here`)} style={{ cursor: 'pointer', display: 'flex', flexDirection: 'column', alignItems: 'flex-start', width: '40%' }}>
                        <text style={{ color: 'gray', fontWeight: '500', fontSize: 12, textAlign: 'left' }}>Email :</text>
                        <input value={email == '' ? 'No email' : email} type='text' disabled={true} style={{ backgroundColor: '#f8f8f8', marginTop: 4, height: 38, width: '96%', borderRadius: 10, outline: 'none', borderStyle: 'solid', borderWidth: 0.5, padding: 4 }} />
                    </div>

                    <div onClick={() => window.open(`tel:${phone}`)} style={{ cursor: 'pointer', display: 'flex', flexDirection: 'column', alignItems: 'flex-start', marginLeft: 40, width: '40%' }}>
                        <text style={{ color: 'gray', fontWeight: '500', fontSize: 12, textAlign: 'left' }}>Phone number : </text>
                        <input value={phone == '' ? 'No phone' : phone} type='text' disabled={true} style={{ backgroundColor: '#f8f8f8', marginTop: 4, height: 38, width: '96%', borderRadius: 10, outline: 'none', borderStyle: 'solid', borderWidth: 0.5, padding: 4 }} />
                    </div>
                </div>

                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginTop: 10, width: '90%', justifyContent: 'space-between' }}>

                    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', width: '99%' }}>
                        <text style={{ color: 'gray', fontWeight: '500', fontSize: 12, textAlign: 'left' }}>Description : </text>
                        <text style={{ fontSize: 14, backgroundColor: '#f8f8f8', marginTop: 4, maxHeight: 350, width: '96%', borderRadius: 10, outline: 'none', borderStyle: 'solid', borderWidth: 0.5, padding: 4 }} > {my_description(description)} </text>
                    </div>
                    <text></text>
                </div>

                <div style={{ alignSelf: 'center', display: 'flex', flexDirection: 'column', alignItems: 'flex-start', marginTop: 10, width: '90%', justifyContent: 'left' }}>
                    {timings != '' && timings.length > 0 && <text style={{ textAlign: 'left', marginBottom: 15, color: 'black', fontSize: 16 }}>Opening and closing</text>}
                    {timings != '' && timings.length > 0 && timings.map((id) => <div style={{ display: 'flex', flexDirection: 'row', marginBottom: 6, width: '99%' }}>
                        <text style={{ textAlign: 'left', color: 'black' }}>{id.days} : </text>
                        <text style={{ color: 'gray' }}>{format(id.hours)}</text>
                    </div>)}
                </div>

            </div>}

            {isMobile && <div style={{ display: 'flex', overflowY: "hidden", alignItems: 'center', width: '98%', alignSelf: 'center', flexDirection: 'column', marginBottom: 30, marginTop: 5, justifyContent: 'center' }}>
                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginTop: 10, width: '95%', justifyContent: 'space-between', height: 130 }}>
                    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', width: '98%' }}>
                        <text style={{ color: 'gray', fontWeight: '500', fontSize: 12, textAlign: 'left' }}>Business name :</text>
                        <input value={business_name} disabled={true} type='text' placeholder=' Your Business Name' style={{ backgroundColor: '#f8f8f8', marginTop: 4, height: 38, width: '96%', borderRadius: 10, outline: 'none', borderStyle: 'solid', borderWidth: 0.5, padding: 4, }} />
                    </div>

                    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', width: '98%' }}>
                        <text style={{ color: 'gray', fontWeight: '500', fontSize: 12, textAlign: 'left' }}>Address : </text>
                        <textarea value={address == '' ? 'Not found' : address} disabled={true} type='text' style={{ backgroundColor: '#f8f8f8', marginTop: 4, height: 45, width: '96%', borderRadius: 10, outline: 'none', borderStyle: 'solid', borderWidth: 0.5, padding: 4, }} />
                    </div>
                </div>
                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginTop: 10, width: '95%', justifyContent: 'space-between', height: 130 }}>
                    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', width: '98%' }}>
                        <text style={{ color: 'gray', fontWeight: '500', fontSize: 12, textAlign: 'left' }}>Tagline :</text>
                        <input value={tagline == '' ? 'No tagline' : tagline} disabled={true} type='text' style={{ backgroundColor: '#f8f8f8', marginTop: 4, height: 38, width: '96%', borderRadius: 10, outline: 'none', borderStyle: 'solid', borderWidth: 0.5, padding: 4, }} />
                    </div>

                    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', width: '98%' }}>
                        <text style={{ color: 'gray', fontWeight: '500', fontSize: 12, textAlign: 'left' }}>City : </text>
                        <input value={town == '' ? 'Not found' : town} disabled={true} type='text' style={{ backgroundColor: '#f8f8f8', marginTop: 4, height: 38, width: '96%', borderRadius: 10, outline: 'none', borderStyle: 'solid', borderWidth: 0.5, padding: 4 }} />
                    </div>
                </div>

                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginTop: 10, width: '95%', justifyContent: 'space-between', height: 130 }}>
                    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', width: '98%' }}>
                        <text style={{ color: 'gray', fontWeight: '500', fontSize: 12, textAlign: 'left' }}>Post Code :</text>
                        <input value={post_code == '' ? 'Not found' : post_code} disabled={true} type='text' style={{ backgroundColor: '#f8f8f8', marginTop: 4, height: 38, width: '96%', borderRadius: 10, outline: 'none', borderStyle: 'solid', borderWidth: 0.5, padding: 4 }} />
                    </div>

                    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', width: '98%' }}>
                        <text style={{ color: 'gray', fontWeight: '500', fontSize: 12, textAlign: 'left' }}>Country : </text>
                        <input value={country == '' ? 'Not found' : country} disabled={true} type='text' style={{ backgroundColor: '#f8f8f8', marginTop: 4, height: 38, width: '96%', borderRadius: 10, outline: 'none', borderStyle: 'solid', borderWidth: 0.5, padding: 4 }} />
                    </div>
                </div>

                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginTop: 10, width: '95%', justifyContent: 'space-between', height: 130 }}>
                    <div onClick={() => openInNewTab(website)} style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', width: '98%' }}>
                        <text style={{ color: 'gray', fontWeight: '500', fontSize: 12, textAlign: 'left' }}>Website Url :</text>
                        <input value={website == '' ? 'Not found' : website} type='text' disabled={true} style={{ backgroundColor: '#f8f8f8', marginTop: 4, height: 38, width: '96%', borderRadius: 10, outline: 'none', borderStyle: 'solid', borderWidth: 0.5, padding: 4, }} />
                    </div>

                    <div onClick={() => openInNewTab(social)} style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', width: '98%' }}>
                        <text style={{ color: 'gray', fontWeight: '500', fontSize: 12, textAlign: 'left' }}>Social media links : </text>
                        <input value={social == '' ? 'Not found' : social} type='text' disabled={true} style={{ backgroundColor: '#f8f8f8', marginTop: 4, height: 38, width: '96%', borderRadius: 10, outline: 'none', borderStyle: 'solid', borderWidth: 0.5, padding: 4, }} />
                    </div>
                </div>

                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginTop: 10, width: '95%', justifyContent: 'space-between', height: 130 }}>
                    <div onClick={() => window.open(`mailto:${email}?subject=Subject&body=Body%20goes%20here`)} style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', width: '98%' }}>
                        <text style={{ color: 'gray', fontWeight: '500', fontSize: 12, textAlign: 'left' }}>Email :</text>
                        <div style={{ display: 'flex', justifyContent: 'left', backgroundColor: '#f8f8f8', marginTop: 4, height: 38, width: '96%', borderRadius: 10, outline: 'none', borderStyle: 'solid', borderWidth: 1, padding: 4, borderColor: (email != '' ? 'lightgray' : 'red') }}>
                            <text style={{ alignSelf: 'flex-start', textAlign: 'left', fontSize: 12 }}>{email == '' ? 'No phone' : email}</text>
                        </div>
                    </div>

                    <div onClick={() => window.open(`tel:${phone}`)} style={{ cursor: 'pointer', display: 'flex', flexDirection: 'column', alignItems: 'flex-start', width: '98%' }}>
                        <text style={{ color: 'gray', fontWeight: '500', fontSize: 12, textAlign: 'left' }}>Phone number : </text>
                        <div style={{ display: 'flex', justifyContent: 'left', backgroundColor: '#f8f8f8', marginTop: 4, height: 38, width: '96%', borderRadius: 10, outline: 'none', borderStyle: 'solid', borderWidth: 1, padding: 4, borderColor: (phone != '' ? 'lightgray' : 'red') }}>
                            <text style={{ alignSelf: 'flex-start', textAlign: 'left', fontSize: 12 }}>{phone == '' ? 'No phone' : phone}</text>
                        </div>
                    </div>
                </div>

                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginTop: 10, width: '95%', justifyContent: 'space-between' }}>

                    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', width: '98%' }}>
                        <text style={{ color: 'gray', fontWeight: '500', fontSize: 12, textAlign: 'left' }}>Description : </text>
                        <text style={{ fontSize: 14, backgroundColor: '#f8f8f8', marginTop: 4, maxHeight: 490, width: '96%', borderRadius: 10, outline: 'none', borderStyle: 'solid', borderWidth: 0.5, padding: 4 }} > {my_description(description)} </text>

                    </div>
                    <text></text>
                </div>

                <div style={{ alignSelf: 'center', display: 'flex', flexDirection: 'column', alignItems: 'flex-start', marginTop: 10, width: '90%', justifyContent: 'left' }}>
                    {timings != '' && timings.length > 0 && <text style={{ textAlign: 'left', marginBottom: 15, color: 'black', fontSize: 16 }}>Opening and closing</text>}
                    {timings != '' && timings.length > 0 && timings.map((id) => <div style={{ display: 'flex', flexDirection: 'row', marginBottom: 6, width: '99%' }}>
                        <text style={{ textAlign: 'left', color: 'black' }}>{id.days} : </text>
                        <text style={{ color: 'gray' }}>{format(id.hours)}</text>
                    </div>)}
                </div>

            </div>}

            <div style={{ display: 'flex', flexDirection: 'column', width: isMobile ? '94%' : '50%', alignSelf: 'center', marginTop: 5, alignItems: 'center' }}>
                <div style={{ flexDirection: 'row', alignItems: 'center', alignSelf: 'center', marginBottom: 6 }}>
                    <text style={{ color: 'forestgreen' }}>Total Reviews {reviews == null ? 0 : reviews.length} <text style={{ color: 'black', fontWeight: '700' }}>.</text> <text onClick={() => setRatingOpen(true)} style={{ cursor: 'pointer', color: 'blue', textDecorationLine: 'underline' }}> Write a review</text></text>
                </div>
                <Rating
                    emptySymbol={empty}
                    fullSymbol={fill}
                    fractions={1}
                    initialRating={average_rating}
                    readonly={true}
                />
            </div>

            <div style={{ width: '90%', alignSelf: 'center', alignItems: 'center', display: 'flex', flexDirection: 'column', marginTop: 20 }}>
                <div style={{ width: isMobile ? '94%' : '50%', alignItems: 'center', display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                    <Rating
                        emptySymbol={empty}
                        fullSymbol={fill}
                        fractions={1}
                        initialRating={5}
                        readonly={true}
                    />
                    <text style={{ color: 'black', fontWeight: '500', fontSize: 15 }}>{all_rating.five * 100} %</text>
                </div>
                <div style={{ marginTop: 4, width: isMobile ? '94%' : '50%', height: 15, borderRadius: 4, alignSelf: 'center', backgroundColor: 'lightgrey' }}>
                    <div style={{ width: String(all_rating.five * 100) + '%', backgroundColor: '#28b8dc', alignSelf: 'flex-start', height: 15 }} />
                </div>
            </div>

            <div style={{ width: '90%', alignSelf: 'center', alignItems: 'center', display: 'flex', flexDirection: 'column', marginTop: 6 }}>
                <div style={{ width: isMobile ? '94%' : '50%', alignItems: 'center', display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                    <Rating
                        emptySymbol={empty}
                        fullSymbol={fill}
                        fractions={1}
                        initialRating={4}
                        stop={4}
                        readonly={true}
                    />
                    <text style={{ color: 'black', fontWeight: '500', fontSize: 15 }}>{all_rating.four * 100} %</text>
                </div>
                <div style={{ marginTop: 4, width: isMobile ? '94%' : '50%', height: 15, borderRadius: 4, alignSelf: 'center', backgroundColor: 'lightgrey' }}>
                    <div style={{ width: String(all_rating.four * 100) + '%', backgroundColor: '#28b8dc', alignSelf: 'flex-start', height: 15 }} />
                </div>
            </div>

            <div style={{ width: '90%', alignSelf: 'center', alignItems: 'center', display: 'flex', flexDirection: 'column', marginTop: 6 }}>
                <div style={{ width: isMobile ? '94%' : '50%', alignItems: 'center', display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                    <Rating
                        emptySymbol={empty}
                        fullSymbol={fill}
                        fractions={1}
                        initialRating={3}
                        readonly={true}
                        stop={3}
                    />
                    <text style={{ color: 'black', fontWeight: '500', fontSize: 15 }}>{all_rating.three * 100} %</text>
                </div>
                <div style={{ marginTop: 4, width: isMobile ? '94%' : '50%', height: 15, borderRadius: 4, alignSelf: 'center', backgroundColor: 'lightgrey' }}>
                    <div style={{ width: String(all_rating.three * 100) + '%', backgroundColor: '#28b8dc', alignSelf: 'flex-start', height: 15 }} />
                </div>
            </div>

            <div style={{ width: '90%', alignSelf: 'center', alignItems: 'center', display: 'flex', flexDirection: 'column', marginTop: 6 }}>
                <div style={{ width: isMobile ? '94%' : '50%', alignItems: 'center', display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                    <Rating
                        emptySymbol={empty}
                        fullSymbol={fill}
                        fractions={1}
                        initialRating={2}
                        readonly={true}
                        stop={2}
                    />
                    <text style={{ color: 'black', fontWeight: '500', fontSize: 15 }}>{all_rating.two * 100} %</text>
                </div>
                <div style={{ marginTop: 4, width: isMobile ? '94%' : '50%', height: 15, borderRadius: 4, alignSelf: 'center', backgroundColor: 'lightgrey' }}>
                    <div style={{ width: String(all_rating.two * 100) + '%', backgroundColor: '#28b8dc', alignSelf: 'flex-start', height: 15 }} />
                </div>
            </div>

            <div style={{ width: '90%', alignSelf: 'center', alignItems: 'center', display: 'flex', flexDirection: 'column', marginTop: 6 }}>
                <div style={{ width: isMobile ? '94%' : '50%', alignItems: 'center', display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                    <Rating
                        emptySymbol={empty}
                        fullSymbol={fill}
                        fractions={1}
                        initialRating={1}
                        readonly={true}
                        stop={1}
                    />
                    <text style={{ color: 'black', fontWeight: '500', fontSize: 15 }}>{all_rating.one * 100} %</text>
                </div>
                <div style={{ marginTop: 4, width: isMobile ? '94%' : '50%', height: 15, borderRadius: 4, alignSelf: 'center', backgroundColor: 'lightgrey' }}>
                    <div style={{ width: String(all_rating.one * 100) + '%', backgroundColor: '#28b8dc', alignSelf: 'flex-start', height: 15 }} />
                </div>
            </div>

            <div style={{ display: 'flex', width: isMobile ? '93%' : '49%', alignSelf: 'center', alignItems: 'center', maxHeight: 900, flexDirection: 'column', marginTop: 10, marginLeft: 4 }}>
                {reviews && reviews.map(item => renderStandingsReview(item))}
            </div>

            <div style={{ marginTop: 30, alignSelf: 'center', display: 'flex', width: width, backgroundColor: '#28b8dc', height: 200, alignItems: 'center', justifyContent: 'center' }}>
                <div style={{ width: '90%', alignSelf: 'center', display: 'flex', flexDirection: 'row', height: 180, alignItems: 'center', justifyContent: 'space-between' }}>
                    <div style={{ flexDirection: 'column', display: 'flex', alignItems: 'flex-start', width: '40%', justifyContent: 'center' }}>
                        <img src={require('../nwlb.jpg')} style={{ height: 60, width: 60, borderRadius: 30, objectFit: 'contain', marginBottom: 6 }} />
                        <text style={{ color: 'white', fontSize: 12, fontWeight: '300', textAlign: 'left' }}>
                            Download our app North West Local Business - Easily find the services and products you need while enjoying
                            exclusive features not available on our website.
                        </text>
                    </div>
                    <div style={{ flexDirection: 'column', display: 'flex', alignItems: 'flex-start', width: '14%' }}>
                        <text style={{ fontSize: 14, color: 'white', fontWeight: '800' }}>OUR LOCATION</text>
                        <text style={{ color: 'white', fontSize: 12, fontWeight: '300', marginTop: 6 }}>
                            Northwest, UK
                        </text>
                    </div>
                    <div style={{ flexDirection: 'column', display: 'flex', alignItems: 'flex-start', width: '14%' }}>
                        <text style={{ fontSize: 14, color: 'white', fontWeight: '800' }}>CONTACT US</text>
                        <text style={{ color: 'white', fontSize: 12, fontWeight: '300', marginTop: 6 }}>
                            Tel: 0800 9750341
                        </text>
                    </div>
                </div>
            </div>

            <div style={{ backgroundColor: '#089ec4', width: width, display: 'flex', alignItems: 'center', height: 60, alignSelf: 'center', justifyContent: 'center' }}>
                <div style={{ width: isMobile ? '95%' : '90%', alignSelf: 'center', height: 50, display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between' }}>

                    <div style={{ width: '40%', display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between' }}>
                        <text onClick={() => navigate('/Condition')} style={{ cursor: 'pointer', color: 'white', fontWeight: '400', fontSize: 13, textDecorationLine: 'underline', textDecorationColor: 'white' }}>Terms & Conditions</text>
                        <text style={{ color: 'white', fontWeight: '400', fontSize: 13, textDecorationLine: 'underline', textDecorationColor: 'white' }}>Cookie Policy</text>
                        <text onClick={() => navigate('/Term')} style={{ cursor: 'pointer', color: 'white', fontWeight: '400', fontSize: 13, textDecorationLine: 'underline', textDecorationColor: 'white' }}>Privacy Policy</text>
                    </div>

                    <div style={{ width: isMobile ? '50%' : '25%', display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-around' }}>
                        <div style={{ display: 'flex', alignContent: 'center', alignItems: 'center', backgroundColor: '#28b8dc', height: 40, width: 40, borderRadius: 20, justifyContent: 'center' }}>
                            {instagram_icon}
                        </div>
                        <div style={{ display: 'flex', alignContent: 'center', alignItems: 'center', backgroundColor: '#28b8dc', height: 40, width: 40, borderRadius: 20, justifyContent: 'center' }}>
                            {twitter_icon}
                        </div>
                        <div style={{ display: 'flex', alignContent: 'center', alignItems: 'center', backgroundColor: '#28b8dc', height: 40, width: 40, borderRadius: 20, justifyContent: 'center' }}>
                            {facebook_icon}
                        </div>
                        <div style={{ display: 'flex', alignContent: 'center', alignItems: 'center', backgroundColor: '#28b8dc', height: 40, width: 40, borderRadius: 20, justifyContent: 'center' }}>
                            {instagram_icon}
                        </div>
                        <div style={{ display: 'flex', alignContent: 'center', alignItems: 'center', backgroundColor: '#28b8dc', height: 40, width: 40, borderRadius: 20, justifyContent: 'center' }}>
                            {instagram_icon}
                        </div>
                    </div>

                </div>
            </div>

            <ReactModal
                style={{ overlay: { display: 'flex', alignSelf: 'center', justifyContent: 'center', alignContent: 'center', alignItems: 'center' }, content: { position: 'absolute', top: '10%', left: isMobile ? '5%' : '25%', display: 'flex', backgroundColor: 'white', width: isMobile ? '90%' : '50%', alignSelf: 'center', alignItems: 'center', flexDirection: 'column', borderRadius: 8, height: 400 } }}
                isOpen={rating_open}
                onRequestClose={() => {
                    setRatingOpen(false)
                }}
                closeTimeoutMS={100}
                shouldFocusAfterRender={true}
                shouldCloseOnOverlayClick={true}
                shouldCloseOnEsc={true}
                shouldReturnFocusAfterClose={true}
                preventScroll={false}
            >

                <div style={{ display: 'flex', backgroundColor: 'forestgreen', justifyContent: 'center', width: isMobile ? '95%' : '85%', alignSelf: 'center', padding: 10, borderRadius: 6 }}>
                    <text style={{ color: 'white', fontWeight: '600', fontSize: 14 }}>Rating</text>
                </div>

                <div style={{ display: 'flex', alignself: 'center', width: isMobile ? '95%' : '85%', justifyContent: 'left', flexDirection: 'column', marginTop: 10 }}>
                    <text style={{ marginBottom: 10, marginTop: 15 }}>Your Rating</text>
                    <Rating
                        emptySymbol={empty}
                        fullSymbol={fill}
                        fractions={1}
                        onChange={(e) => setRat(e)}
                        initialRating={rat}
                    />
                    {loading && <div style={{ alignSelf: 'center', backgroundColor: 'white', padding: 10 }}><Levels size={35} /></div>}
                    <input value={name} onChange={(e) => setName(e.target.value)} type='text' placeholder='Enter your name' style={{ marginBottom: 10, backgroundColor: '#f8f8f8', marginTop: 10, height: 38, width: '96%', borderRadius: 10, outline: 'none', borderStyle: 'solid', borderColor: '#28b8dc', borderWidth: 0.5, padding: 8 }} />
                    <input value={rating_email} onChange={(e) => setRatingEmail(e.target.value)} type='text' placeholder='Enter your email' style={{ marginBottom: 10, backgroundColor: '#f8f8f8', marginTop: 10, height: 38, width: '96%', borderRadius: 10, outline: 'none', borderStyle: 'solid', borderColor: '#28b8dc', borderWidth: 0.5, padding: 8 }} />
                    {rating_otp && <input value={my_otp} onChange={(e) => setMyOtp(e.target.value)} type='text' placeholder='Enter your verification code' style={{ marginBottom: 10, backgroundColor: '#f8f8f8', marginTop: 10, height: 38, width: '96%', borderRadius: 10, outline: 'none', borderColor: '#28b8dc', borderStyle: 'solid', borderWidth: 0.5, padding: 4 }} />}
                    <textarea value={rating_feedback} onChange={(e) => setRatingFeedback(e.target.value)} type='text' placeholder='Enter your feedback' style={{ borderColor: '#28b8dc', backgroundColor: '#f8f8f8', marginTop: 4, height: 68, width: '96%', borderRadius: 10, outline: 'none', borderStyle: 'solid', borderWidth: 0.5, padding: 8 }} />
                    <div onClick={() => decide()} style={{ cursor: 'pointer', width: 130, height: 40, padding: 10, marginBottom: 10, display: 'flex', justifyContent: 'center', backgroundColor: '#28b8dc', borderRadius: 4, alignSelf: 'center', marginTop: 6, alignItems: 'center' }}>
                        <text style={{ color: 'white', fontSize: 12, fontWeight: '500' }}>{rating_otp ? 'Submit' : 'Continue with Email'}</text>
                    </div>
                </div>

            </ReactModal>

            <ReactModal
                style={{ overlay: { display: 'flex', alignSelf: 'center', justifyContent: 'center', alignContent: 'center', alignItems: 'center' }, content: { position: 'absolute', top: '10%', left: isMobile ? '5%' : '25%', display: 'flex', backgroundColor: 'white', width: isMobile ? '90%' : '50%', alignSelf: 'center', alignItems: 'center', flexDirection: 'column', borderRadius: 8, height: 400 } }}
                isOpen={comment_open}
                onRequestClose={() => {
                    setCommentOpen(false)
                }}
                closeTimeoutMS={100}
                shouldFocusAfterRender={true}
                shouldCloseOnOverlayClick={true}
                shouldCloseOnEsc={true}
                shouldReturnFocusAfterClose={true}
                preventScroll={false}
            >

                <div style={{ display: 'flex', backgroundColor: 'forestgreen', justifyContent: 'center', width: isMobile ? '95%' : '85%', alignSelf: 'center', padding: 10, borderRadius: 6 }}>
                    <text style={{ color: 'white', fontWeight: '600', fontSize: 14 }}>Comment</text>
                </div>

                <div style={{ display: 'flex', alignself: 'center', width: isMobile ? '95%' : '85%', justifyContent: 'left', flexDirection: 'column', marginTop: 10 }}>
                    <text style={{ marginBottom: 10, marginTop: 15 }}> Your Comment</text>
                    {loading && <div style={{ alignSelf: 'center', backgroundColor: 'white', padding: 10 }}><Levels size={35} /></div>}
                    <input value={name} onChange={(e) => setName(e.target.value)} type='text' placeholder='Enter your name' style={{ marginBottom: 10, backgroundColor: '#f8f8f8', marginTop: 10, height: 38, width: '96%', borderRadius: 10, outline: 'none', borderStyle: 'solid', borderColor: '#28b8dc', borderWidth: 0.5, padding: 8 }} />
                    <input value={rating_email} onChange={(e) => setRatingEmail(e.target.value)} type='text' placeholder='Enter your email' style={{ marginBottom: 10, backgroundColor: '#f8f8f8', marginTop: 10, height: 38, width: '96%', borderRadius: 10, outline: 'none', borderStyle: 'solid', borderColor: '#28b8dc', borderWidth: 0.5, padding: 8 }} />
                    {rating_otp && <input value={my_otp} onChange={(e) => setMyOtp(e.target.value)} type='text' placeholder='Enter your verification code' style={{ marginBottom: 10, backgroundColor: '#f8f8f8', marginTop: 10, height: 38, width: '96%', borderRadius: 10, outline: 'none', borderColor: '#28b8dc', borderStyle: 'solid', borderWidth: 0.5, padding: 4 }} />}
                    <textarea value={rating_feedback} onChange={(e) => setRatingFeedback(e.target.value)} type='text' placeholder='Enter your comment' style={{ borderColor: '#28b8dc', backgroundColor: '#f8f8f8', marginTop: 4, height: 68, width: '96%', borderRadius: 10, outline: 'none', borderStyle: 'solid', borderWidth: 0.5, padding: 8 }} />
                    <div onClick={() => comments()} style={{ cursor: 'pointer', width: 130, height: 40, padding: 10, marginBottom: 10, display: 'flex', justifyContent: 'center', backgroundColor: '#28b8dc', borderRadius: 4, alignSelf: 'center', marginTop: 6, alignItems: 'center' }}>
                        <text style={{ color: 'white', fontSize: 12, fontWeight: '500' }}>{rating_otp ? 'Submit' : 'Continue with Email'}</text>
                    </div>
                </div>

            </ReactModal>

        </div>
    );
}

export default SingleListingW